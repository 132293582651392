import { css, SerializedStyles, useTheme } from "@emotion/react";
import { Image as ImageIcon } from "@phosphor-icons/react";

interface ImageProps {
  title: string;
  img?: string;
  hoverImg?: string;
  backgroundColor?: string;
  extraCss?: SerializedStyles;
  placeholderTitle?: string;
}
export const Image = (props: ImageProps) => {
  const theme = useTheme();

  if (!props.img)
    return (
      <div
        css={[
          theme.typography["label-s"],
          css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 4px;
            flex: 1 0 0;
            align-self: stretch;
            height: 100%;
            background: ${props.backgroundColor
              ? props.backgroundColor
              : theme.colors.bg["weak-50"]};
            color: ${theme.colors.text["disabled-300"]};
          `,
        ]}
      >
        <ImageIcon size={32} color={theme.colors.icon["disabled-300"]} />
        <span>{props.placeholderTitle ?? "No image"}</span>
      </div>
    );

  return (
    <figure
      css={css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
      `}
    >
      <img
        draggable={false}
        src={props.img}
        alt={props.title}
        css={[
          css`
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            user-drag: none;
            -webkit-user-drag: none;
            user-select: none;
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            background: ${props.backgroundColor
              ? props.backgroundColor
              : theme.colors.bg["weak-50"]};
          `,
          props.extraCss,
        ]}
      />
      {props.hoverImg && (
        <img
          draggable={false}
          src={props.hoverImg}
          alt={props.title}
          className={"hover-image"}
          css={[
            css`
              object-fit: cover;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              user-drag: none;
              -webkit-user-drag: none;
              user-select: none;
              -moz-user-select: none;
              -webkit-user-select: none;
              -ms-user-select: none;

              transition: opacity 0.2s;
              opacity: 0;
              .image-hover:hover & {
                opacity: 1;
              }

              background: ${props.backgroundColor
                ? props.backgroundColor
                : theme.colors.bg["weak-50"]};
            `,
            props.extraCss,
          ]}
        />
      )}
    </figure>
  );
};
