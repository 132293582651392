import { useCallback, useEffect, useState } from "react";
import HeaderCellWithSorting, { SortDirection } from "./HeaderCellWithSorting";
import { TableContext } from "./types";
import type { CustomHeaderProps } from "@ag-grid-community/react";

function TableHeader<T>(props: CustomHeaderProps<T, TableContext<T>>) {
  const [sortDirection, setSortDirection] = useState<SortDirection>(false);
  const onSortChanged = useCallback(() => {
    setSortDirection(
      props.column.isSortAscending()
        ? "asc"
        : props.column.isSortDescending()
          ? "desc"
          : false
    );
  }, [props.column]);

  const onClick = () => {
    const ascendingSort = props.column.isSortAscending();
    const descendingSort = props.column.isSortDescending();
    const noSort = !ascendingSort && !descendingSort;

    if (noSort) {
      props.setSort("asc");
    } else if (ascendingSort) {
      props.setSort("desc");
    } else {
      props.setSort(null);
    }
  };

  useEffect(() => {
    props.column.addEventListener("sortChanged", onSortChanged);
    onSortChanged();
  }, [onSortChanged, props.column]);

  return (
    <HeaderCellWithSorting
      title={props.displayName}
      sortDirection={sortDirection}
      onClick={onClick}
      containerCss={
        props.context.headerContainerStyles[props.column.getColId() as keyof T]
      }
      sortDisabled={!props.column.getColDef().sortable}
    />
  );
}
export default TableHeader;
