import { useMemo } from "react";
import { BarCell } from "../../../../alignUI/DashboardModalTable/DashboardProductModalTable";
import Table from "../../../../alignUI/Table/Table";
import type { ColDef } from "@ag-grid-community/core";
import type { CustomCellRendererProps } from "@ag-grid-community/react";
import { TableContext } from "../../../../alignUI/Table/types";
import TextRowCell from "../../../../alignUI/Table/TextRowCell";
import AvatarGroup from "../../../../alignUI/AvatarGroup";
import { css } from "@emotion/react";
import { ProductClickRow } from "./useCollectionProductTableProducts";

export default function ClickthroughRateModalProductTable({
  products,
  isLoading,
}: {
  products: ProductClickRow[];
  isLoading: boolean;
}) {
  const columnDefs = useMemo<ColDef<ProductClickRow>[]>(() => {
    return [
      {
        width: 80,
        headerName: "#",
        field: "position",
        sortable: true,
        filter: true,
        cellRenderer: (
          props: CustomCellRendererProps<
            ProductClickRow,
            ProductClickRow["position"],
            TableContext<ProductClickRow>
          >
        ) => {
          const params = props;

          const isLoading = params.context.isLoading;

          return (
            <TextRowCell isLoading={isLoading} align={"left"}>
              {params.value}
            </TextRowCell>
          );
        },
      },
      {
        headerName: "Product",
        field: "title",
        sortable: true,
        filter: true,
        cellRenderer: (
          props: CustomCellRendererProps<
            ProductClickRow,
            ProductClickRow["title"],
            TableContext<ProductClickRow>
          >
        ) => {
          const params = props;

          const isLoading = params.context.isLoading;

          return (
            <TextRowCell isLoading={isLoading} align={"left"}>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 12px;
                `}
              >
                <AvatarGroup
                  urls={params.data?.image_urls.slice(0, 1) || []}
                  size={"32px"}
                  width={"32px"}
                  alt={params.value ?? undefined}
                />
                {params.value}
              </div>
            </TextRowCell>
          );
        },
        flex: 1,
      },
      {
        headerName: "Average #",
        field: "average_sort_index",
        sortable: true,
        filter: true,
        cellRenderer: (
          props: CustomCellRendererProps<
            ProductClickRow,
            ProductClickRow["average_sort_index"],
            TableContext<ProductClickRow>
          >
        ) => {
          const params = props;

          const isLoading = params.context.isLoading;

          return (
            <TextRowCell isLoading={isLoading} align={"right"}>
              {params.value ? params.value.toFixed(2) : "-"}
            </TextRowCell>
          );
        },
      },
      {
        headerName: "Clicks",
        field: "clicks",
        filter: true,
        sortable: true,
        initialSort: "desc",
        flex: 1,
        cellRenderer: (
          props: CustomCellRendererProps<
            ProductClickRow,
            ProductClickRow["clicks"],
            TableContext<ProductClickRow>
          >
        ) => {
          return (
            <BarCell
              value={props.value || 0}
              percentage={
                props.data?.percentage_of_max_clicks
                  ? props.data?.percentage_of_max_clicks
                  : 0
              }
              multiColor={false}
            />
          );
        },
      },
    ];
  }, []);

  return (
    <Table<ProductClickRow>
      data={products}
      isLoading={isLoading}
      columnDefs={columnDefs}
      headerContainerStyles={{
        position: css`
          justify-content: flex-start;
        `,
        title: css`
          justify-content: flex-start;
        `,
      }}
    />
  );
}
