import { CSSInterpolation } from "@emotion/css";
import { css, useTheme } from "@emotion/react";
import { CaretDown, CaretUp, CaretUpDown } from "@phosphor-icons/react";
import { MouseEventHandler } from "react";

export type SortDirection = "asc" | "desc" | false;

const sortDirectionToIcon = (sortDirection: SortDirection | false) => {
  const commonProps = {
    weight: "fill",
    css: css`
      cursor: pointer;
    `,
  } as const;

  switch (sortDirection) {
    case "asc":
      return (
        <div
          css={css`
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <CaretUp {...commonProps} size={14} />
        </div>
      );
    case "desc":
      return (
        <div
          css={css`
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <CaretDown {...commonProps} size={14} />
        </div>
      );
    default:
      return <CaretUpDown {...commonProps} size={20} />;
  }
};

interface HeaderCellWithSortingProps {
  title: string;
  onClick?: MouseEventHandler | undefined;
  sortDirection: SortDirection;
  sortDisabled?: boolean;
  containerCss?: CSSInterpolation;
}
const HeaderCellWithSorting = (props: HeaderCellWithSortingProps) => {
  const { title } = props;
  const theme = useTheme();

  return (
    <div
      css={css`
        display: flex;
        align-items: flex-start;
        gap: 10px;
        flex-shrink: 0;
        overflow: hidden;
        width: 100%;
      `}
    >
      <div
        css={[
          css`
            display: flex;
            align-items: center;
            gap: 2px;
            flex: 1 0 0;
            cursor: ${props.sortDisabled ? "default" : "pointer"};
            overflow: hidden;
            justify-content: flex-end;
          `,
          props.containerCss,
        ]}
        onClick={props.sortDisabled ? undefined : props.onClick}
      >
        <span
          css={[
            theme.typography["paragraph-s"],
            css`
              color: ${theme.colors.text["sub-600"]};
              /* ellipsis */
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            `,
          ]}
        >
          {title}
        </span>

        {!props.sortDisabled && (
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 2px;
              color: ${props.sortDirection !== false
                ? theme.colors.icon["sub-600"]
                : theme.colors.icon["disabled-300"]};
            `}
          >
            {sortDirectionToIcon(props.sortDirection)}
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderCellWithSorting;
