import { Interpolation, Theme, css, useTheme } from "@emotion/react";
import React from "react";
import { dragToSelectAndDeselectClass } from "../../lite/views/Collection/DragAndDrop/useDragToSelect";

const columnsToPixels = (columns: number) => (1440 / 12) * columns;
const NoLongerScrollBarContainerID = "not-scrollable-container";

const Container = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<{
    columns?: number;
    customCss?: Interpolation<Theme>;
    backgroundColor?: string;
    sidebar?: React.ReactNode;
  }>
>(({ columns = 10, children, customCss, backgroundColor, sidebar }, ref) => {
  const theme = useTheme();

  return (
    <div
      // Don't really know what this container does, but it's not scrollable anymore - we made the body scroll instead because its better
      id={NoLongerScrollBarContainerID}
      className={dragToSelectAndDeselectClass}
      css={css`
        flex: 1;
        padding: 0 32px;
        display: flex;
        justify-content: center;
        background-color: ${backgroundColor || theme.colors.bg["white-0"]};

        @media (max-width: ${theme.breakpoints.sm}px) {
          padding: 0 11px;
        }
      `}
    >
      <main
        ref={ref}
        css={[
          css`
            width: 100%;
            max-width: ${columnsToPixels(columns)}px;
          `,
          customCss,
        ]}
      >
        {children}
      </main>
      {sidebar}
    </div>
  );
});

export default Container;
