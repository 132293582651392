import { useCallback } from "react";
import { useDragAndDropContext } from "./DragAndDropProvider";
import { dndGridClass } from "./DragAndDropGrid";
import { DragAndDropHover, OnDrop } from "./types";
import { useRowMode } from "./useRowMode";
import { ExtendedProContentBlockWithId } from "../../ContentBlock/types";
import {
  LiteCollectionDto,
  LiteCollectionProductDto,
} from "../../../../api/types";
import { positionBlocks } from "../../ContentBlock/positionBlocks";
import { useSortByGridOrderPrioritiseBlocks } from "./useSortByGridOrder";

/**
 * Returns helpers for a zone to set animationStyling to styling that shows the new grid state, and in turn revert it again
 */
export function useAnimationStyling(zoneRecord: DragAndDropHover) {
  const {
    occupiedByBlocksOrProducts,
    gridStateWhileDragging,
    productsToRender,
    numberOfColumns,
    productCardWidth,
    gridGapPx,
    refState,
    isDragging,
    productCardHeight,
  } = useDragAndDropContext();
  const mapOfGridBeingShown =
    gridStateWhileDragging?.occupiedByBlocksOrProducts ||
    occupiedByBlocksOrProducts;
  const productsInGrid =
    gridStateWhileDragging?.productsToRender || productsToRender;
  const isRowMode = useRowMode();

  const { allSortedPrioritised: isDraggingInGridOrder } =
    useSortByGridOrderPrioritiseBlocks(isDragging);

  const numberOfProducts = productsToRender.length;

  const addAnimationStyling = useCallback(() => {
    if (productCardWidth == undefined || productCardHeight === undefined) {
      return;
    }
    const { mountedGridItems, greyBox, onDrop } = refState.current;
    const isDraggingArray = [...isDraggingInGridOrder];
    // Only preview first item in grid sorted is dragging
    const isDraggingToPreview = isDraggingInGridOrder[0];

    const {
      predictedGridMap = mapOfGridBeingShown,
      predictedProducts = productsInGrid,
    } =
      getPredictiveDropResult({
        // To not create jarring drag experience, only predict parts of the selection being moved
        isDragging: new Set([isDraggingToPreview]),
        zoneRecord,
        onDrop,
        showingColumns: numberOfColumns,
        numberOfProducts,
        productsToRender,
        // Ignore the blocks we ignored so there aren't holes for them at their initial positions either
        ignoreBlocks: new Set(
          isDraggingArray.filter((id) => id !== isDraggingToPreview)
        ),
      }) || {};

    let styling = "";

    if (!isRowMode) {
      const transforms: Record<
        string,
        { itemsMoveX: number; itemsMoveY: number }
      > = {};
      const itemsToTransform = new Set<number | string>();
      // Only take mounted items in the virtualisation to save the browser from parsing bunch of unused selectors
      for (const item of mapOfGridBeingShown) {
        if (typeof item === "number") {
          if (mountedGridItems.has(productsInGrid[item].main_product_id)) {
            itemsToTransform.add(item);
          }
        } else if (typeof item === "string") {
          if (mountedGridItems.has(item)) {
            itemsToTransform.add(item);
          }
        }
      }

      for (const item of itemsToTransform) {
        const indexInOldGrid = mapOfGridBeingShown.indexOf(item);
        const itemId =
          typeof item === "number"
            ? productsInGrid[item].main_product_id
            : item;
        let indexInNewGrid: number;
        if (typeof item === "number") {
          indexInNewGrid = predictedGridMap.indexOf(
            predictedProducts.findIndex(
              (product) => product.main_product_id === itemId
            )
          );
        } else {
          indexInNewGrid = predictedGridMap.indexOf(item);
        }
        const rowInOld = Math.floor(indexInOldGrid / numberOfColumns);
        const columnInOld = indexInOldGrid % numberOfColumns;
        const rowInNew = Math.floor(indexInNewGrid / numberOfColumns);
        const columnInNew = indexInNewGrid % numberOfColumns;
        const movedX = columnInNew - columnInOld;
        const movedY = rowInNew - rowInOld;
        transforms[itemId] = { itemsMoveX: movedX, itemsMoveY: movedY };
      }

      let greyBoxStyling = ``;
      const potentialHoleIndex = predictedGridMap.findIndex((item) => {
        if (item === null) return false;
        const id =
          typeof item === "number"
            ? predictedProducts[item].main_product_id
            : item;
        return isDragging.has(id);
      });
      if (potentialHoleIndex !== undefined) {
        const row = Math.floor(potentialHoleIndex / numberOfColumns);
        const column = potentialHoleIndex % numberOfColumns;
        const holeX = column * (productCardWidth + gridGapPx);
        const holeY = row * (productCardHeight + gridGapPx);
        // Box should only show when hovering a zone
        greyBoxStyling = `.grey-box {
          opacity: 1;
        }`;
        // Set this as inline style because we don't want it to get unset when this zone is no longer hovered (that would lead to the box to go to the top)
        greyBox?.style.setProperty("--box-translate-x", `${holeX}px`);
        greyBox?.style.setProperty("--box-translate-y", `${holeY}px`);
      }

      styling += `
        .${dndGridClass}.is-dragging:has(.draggable-wrapper[data-item-id='${zoneRecord.id}'] .dnd-zone:not(.disabled).hovered.left) {
          ${Object.entries(transforms)
            .map(([idOfItem, { itemsMoveX, itemsMoveY }]) => {
              return itemsMoveY || itemsMoveX
                ? `
              .draggable-wrapper[data-item-id='${idOfItem}'] {
                .content-block-shell, .card-container {
                  --animation-styling-translate-x: ${itemsMoveX * (productCardWidth + gridGapPx)}px;
                  --animation-styling-translate-y: ${itemsMoveY * (productCardHeight + gridGapPx)}px;
                }
              }`
                : ``;
            })
            .join("\n")}
            ${greyBoxStyling}
         }
        `;
    }

    refState.current.animationStyle?.replaceChildren(styling);
    requestAnimationFrame(() => {
      // Add transition to the grey box so it's animated when it moves around. Do this after a frame so the initial positioning doesn't animate from where it was before.
      greyBox?.classList.add("transition");
    });
  }, [
    gridGapPx,
    isDraggingInGridOrder,
    isRowMode,
    mapOfGridBeingShown,
    numberOfColumns,
    numberOfProducts,
    productCardHeight,
    productCardWidth,
    productsInGrid,
    productsToRender,
    refState,
    zoneRecord,
  ]);

  const removeAnimationStyling = useCallback(
    () => refState.current.animationStyle?.replaceChildren(),
    [refState]
  );

  return { addAnimationStyling, removeAnimationStyling };
}

function getPredictiveDropResult({
  onDrop,
  isDragging,
  zoneRecord,
  numberOfProducts,
  showingColumns,
  productsToRender,
  ignoreBlocks,
}: {
  onDrop: OnDrop;
  isDragging: Set<string>;
  zoneRecord: DragAndDropHover;
  showingColumns: number;
  numberOfProducts: number;
  productsToRender: LiteCollectionProductDto[];
  ignoreBlocks: Set<string>;
}) {
  let predictiveLocalContentBlockState:
    | undefined
    | ExtendedProContentBlockWithId[];
  let predictiveCollection: LiteCollectionDto | undefined;

  onDrop(isDragging, zoneRecord, {
    setCollection: (newCollection) => (predictiveCollection = newCollection),
    setLocalContentBlockState: (newState) =>
      (predictiveLocalContentBlockState = newState),
    ignoreBlocks,
  });

  if (!predictiveCollection || !predictiveLocalContentBlockState) return;

  const { occupiedByBlocksOrProducts } = positionBlocks(
    predictiveLocalContentBlockState,
    showingColumns,
    numberOfProducts
  );

  const productMap = new Map(
    productsToRender?.map((product) => [product.main_product_id, product])
  );
  const pinnedProducts =
    predictiveCollection?.pinned_main_product_ids
      .map((id) => productMap.get(id))
      .filter((product): product is LiteCollectionProductDto => !!product) ||
    [];

  const pinnedMainProductIdsSet = new Set(
    predictiveCollection?.pinned_main_product_ids
  );

  const unPinnedProducts = (productsToRender || [])
    .filter((product) => !pinnedMainProductIdsSet.has(product.main_product_id))
    .sort((a, b) => a.sort_index - b.sort_index);

  const orderedProducts = [...pinnedProducts, ...unPinnedProducts];

  return {
    predictedGridMap: occupiedByBlocksOrProducts,
    predictedProducts: orderedProducts,
  };
}
