import { css, useTheme } from "@emotion/react";
import React, { useRef, useState } from "react";
import { StepCard, StepContainer } from "./StepWidget";
import { Modal } from "../../../../alignUI/Modal/Modal";
import { AddContentBlockModalContent } from "../../Dashboard/WelcomeWidget/AddContentBlock/AddContentBlockModalContent";
import { ActivateDepictModalContent } from "../../Dashboard/WelcomeWidget/ActivateDepict/ActivateDepictModalContent";
import { EditImageSettingsModalContent } from "../../Dashboard/WelcomeWidget/EditImageSettings/EditImageSettingsModalContent";
import {
  CopySimple,
  HandHeart,
  Image,
  PhoneCall,
  RocketLaunch,
} from "@phosphor-icons/react";
import useFeatureActivationStatus from "../../Dashboard/useFeatureActivationStatus";
import CompactButton from "../../../../alignUI/CompactButton/CompactButton";
import OnboardingProgress from "./OnboardingProgress";
import {
  Placeholder,
  TextPlaceholder,
} from "../../../../alignUI/Placeholder/Placeholder";

type ModalName = "activateDepict" | "addContent" | "editImage";

export const onboardingSteps = [
  {
    title: "Publish a collection",
    icon: RocketLaunch,
    modalName: "activateDepict" as const,
    todo: "activated_depict_for_one_collection" as const,
  },
  {
    title: "Add Content to your grid",
    icon: Image,
    modalName: "addContent" as const,
    todo: "added_content_block" as const,
  },
  {
    title: "Change your product images",
    icon: CopySimple,
    modalName: "editImage" as const,
    todo: "edited_image_settings" as const,
  },
  {
    title: "Claim your free call",
    icon: PhoneCall,
    onClick: () => {
      window.open(
        "https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ1ibQXpnmBvOYH5WmM8rcgsf2dCPLZhHI9FqqnpA6HCS9LtJJA6T0deO6WrngCvb9awhJFtPURr",
        "_blank"
      );
    },
  },
  {
    title: "Give us your feedback",
    icon: HandHeart,
    onClick: () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).jimo.push([
        "do",
        "boosted:trigger",
        [{ evolutionId: "950f837a-7629-40a5-8953-72062e100894" }],
      ]);
    },
  },
];

export function FeatureSteps({
  featureActivationStatusQuery,
  cancelledSteps,
  setCancelledSteps,
}: {
  featureActivationStatusQuery: ReturnType<typeof useFeatureActivationStatus>;
  cancelledSteps: string[];
  setCancelledSteps: React.Dispatch<React.SetStateAction<string[]>>;
}) {
  const theme = useTheme();
  const [openModal, setOpenModal] = useState<ModalName | false>(false);
  const doneOnClose = useRef<string | null>(null);

  const closeModal = () => setOpenModal(false);

  const doneSteps = Object.entries(
    featureActivationStatusQuery.data || {}
  ).filter(([key, value]) => {
    const stepTitle = onboardingSteps.find((step) => step.todo === key)?.title;
    if (stepTitle && cancelledSteps.includes(stepTitle)) return false;
    return value;
  }).length;
  const stepsLeft = onboardingSteps.length - doneSteps - cancelledSteps.length;

  if (stepsLeft === 0) return null;

  return (
    <>
      <StepContainer
        desktopCards={4}
        containerCss={css`
          padding: 16px;
          background: ${theme.colors.bg["weak-50"]};
          border-radius: 24px;
        `}
      >
        <OnboardingProgress
          doneOrCancelledSteps={doneSteps + cancelledSteps.length}
          totalSteps={onboardingSteps.length}
        />

        {onboardingSteps.map(
          ({ title, icon: Icon, modalName, todo, onClick }) => {
            const showPlaceholder =
              todo && featureActivationStatusQuery.isLoading;

            return (
              (!todo || !featureActivationStatusQuery.data?.[todo]) &&
              !cancelledSteps.includes(title) && (
                <div
                  css={css`
                    position: relative;
                    display: flex;
                    align-items: stretch;
                    & > * {
                      flex-grow: 1;
                    }

                    @media (hover: hover) {
                      .hover-button {
                        opacity: 0;
                      }
                      &:hover {
                        .hover-button {
                          opacity: 1;
                        }
                      }
                    }
                  `}
                >
                  <StepCard
                    key={title}
                    onClick={() => {
                      onClick?.();
                      if (!modalName) return;
                      doneOnClose.current = title;
                      setOpenModal(modalName);
                    }}
                    extraCss={theme.typography["label-s"]}
                  >
                    <div
                      css={css`
                        padding: 6px;
                        display: flex;
                        color: ${theme.colors.primary.base};
                      `}
                    >
                      {showPlaceholder ? (
                        <Placeholder
                          extraCss={css`
                            width: 20px;
                            height: 20px;
                          `}
                        />
                      ) : (
                        <Icon size={20} weight="fill" />
                      )}
                    </div>
                    {showPlaceholder ? (
                      <TextPlaceholder>{title}</TextPlaceholder>
                    ) : (
                      title
                    )}
                  </StepCard>
                  {!showPlaceholder && (
                    <CompactButton
                      className="hover-button"
                      _style="stroke"
                      size="large"
                      icon={CloseLine}
                      css={css`
                        position: absolute;
                        right: 16px;
                        top: 50%;
                        transform: translateY(-50%);
                        border-radius: 100%;
                        cursor: pointer;
                      `}
                      onClick={() =>
                        setCancelledSteps((prev) => [...prev, title])
                      }
                    />
                  )}
                </div>
              )
            );
          }
        )}
      </StepContainer>
      <Modal
        onOpenChange={(open) => {
          if (!open) {
            closeModal();
          } else {
            setOpenModal("addContent");
          }
        }}
        open={openModal === "addContent"}
      >
        <AddContentBlockModalContent onConfirm={closeModal} />
      </Modal>
      <Modal
        onOpenChange={(open) => {
          if (!open) {
            closeModal();
          } else {
            setOpenModal("activateDepict");
          }
        }}
        open={openModal === "activateDepict"}
      >
        <ActivateDepictModalContent onConfirm={closeModal} />
      </Modal>
      <Modal
        onOpenChange={(open) => {
          if (!open) {
            closeModal();
          } else {
            setOpenModal("editImage");
          }
        }}
        open={openModal === "editImage"}
      >
        <EditImageSettingsModalContent onConfirm={closeModal} />
      </Modal>
    </>
  );
}

export function CloseLine() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3316 8.93906L14.0441 5.22656L15.1046 6.28706L11.3921 9.99956L15.1046 13.7121L14.0441 14.7726L10.3316 11.0601L6.61909 14.7726L5.55859 13.7121L9.27109 9.99956L5.55859 6.28706L6.61909 5.22656L10.3316 8.93906Z"
        fill="currentColor"
      />
    </svg>
  );
}
