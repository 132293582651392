import { css, useTheme } from "@emotion/react";
import { forwardRef } from "react";
import { Badge, BadgeColor } from "./Badge";

type Status = "completed" | "pending" | "failed" | "disabled";

const statusToColor: Record<Status, BadgeColor> = {
  completed: "green",
  pending: "orange",
  failed: "red",
  disabled: "gray",
};

export type BadgeProps = {
  _style: "lighter" | "stroke"; // _style to not collide with style prop
  status: Status;
  text: string;
  icon?: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

export const StatusBadge = forwardRef<HTMLDivElement, BadgeProps>(
  // `color` is only extracted to prevent it from being passed to the Badge component as a standard div prop
  ({ _style, status, text, icon, color, ...rest }, ref) => {
    const theme = useTheme();
    return (
      <Badge
        ref={ref}
        _style={_style}
        text={text}
        color={statusToColor[status]}
        type={icon ? "basic" : "with_dot"}
        icon={icon}
        styleOverrides={css`
          border-radius: 6px;
          padding: 4px ${text.length > 0 ? 8 : 4}px 4px 4px;
          ${_style === "stroke"
            ? `border-color: ${theme.colors.stroke["soft-200"]};`
            : ""}
          ${_style === "stroke"
            ? `color: ${theme.colors.text["sub-600"]};`
            : ""}
            ${icon ? `gap: 4px;` : ""}
        `}
        {...rest}
      />
    );
  }
);

StatusBadge.displayName = "StatusBadge";
