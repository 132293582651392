import { css, SerializedStyles, useTheme } from "@emotion/react";
import "@ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import { AgGridReact } from "@ag-grid-community/react";
import { useCallback, useMemo, useRef } from "react";

import type {
  CellKeyDownEvent,
  ColDef,
  ColumnState,
  ColumnStateParams,
  GetRowIdFunc,
  GridReadyEvent,
  RowClassParams,
  RowClickedEvent,
  RowStyle,
  SortChangedEvent,
} from "@ag-grid-community/core";
import TableHeader from "./TableHeader";
import { TableContext } from "./types";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";

ModuleRegistry.registerModules([ClientSideRowModelModule]);

interface TableProps<T> {
  columnDefs: ColDef<T>[];
  data: T[];
  isLoading: boolean;
  onRowClicked?: (rowClicked: RowClickedEvent<T> | CellKeyDownEvent<T>) => void;
  noRowsOverlayComponent?: () => JSX.Element;
  getRowId?: GetRowIdFunc<T>;
  headerContainerStyles?: Partial<Record<keyof T, SerializedStyles>>;
  onSortChange?: (event: SortChangedEvent<T>) => void;
  onBottomReached?: () => void;
  defaultSortModel?: Partial<Record<keyof T, ColumnStateParams>>;
}

export default function Table<T>({
  columnDefs,
  isLoading,
  data,
  onRowClicked,
  noRowsOverlayComponent,
  getRowId,
  headerContainerStyles,
  onSortChange,
  onBottomReached,
  defaultSortModel,
}: TableProps<T>) {
  const theme = useTheme();
  const grid = useRef<AgGridReact<T>>(null);

  const components = useMemo<{
    [p: string]: any;
  }>(() => {
    return {
      agColumnHeader: TableHeader,
    };
  }, []);

  const rowHeight = 52;

  const getRowStyle = useCallback(
    (params: RowClassParams<T, TableContext<T>>): RowStyle => {
      const lastRow = params.node.rowIndex === data.length - 1;

      if (lastRow) {
        return {};
      }

      const style: React.CSSProperties = {
        borderBottom: `1px solid ${theme.colors.stroke["soft-200"]}`,
      };

      return style as RowStyle;
    },
    [data.length, theme.colors.stroke]
  );

  const context: TableContext<T> = {
    headerContainerStyles: headerContainerStyles ?? {},
    isLoading,
  };

  const agGridCss = useMemo(() => {
    const styles = [
      css`
        .ag-header {
          background-color: ${theme.colors.bg["weak-50"]};
          border-radius: 8px;
          margin-bottom: 8px;
        }

        .ag-row .ag-cell {
          display: flex;
          align-items: center;
          border: none;
        }

        .ag-cell-value {
          padding: 8px 12px;

          &.square-cell {
            padding: 8px;
          }
        }

        .ag-header-cell {
          padding: 8px 12px;
        }

        .ag-cell[col-id="images"],
        .ag-cell.image-cell {
          justify-content: center;
        }
      `,
    ];

    if (!isLoading) {
      styles.push(css`
        .ag-row {
          &:hover {
            background-color: ${theme.colors.bg["weak-50"]};
            cursor: ${onRowClicked ? "pointer" : "default"};
          }
        }
      `);
    }

    return styles;
  }, [isLoading, theme.colors.bg, onRowClicked]);

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      if (defaultSortModel) {
        const columnState = Object.keys(defaultSortModel).map<ColumnState>(
          (key) => {
            const value = defaultSortModel[key as keyof T];

            return {
              colId: key,
              ...value,
            };
          }
        );

        params.api.applyColumnState({ state: columnState });
      }
    },
    [defaultSortModel]
  );

  const onCellKeyDown = useCallback(
    (event: CellKeyDownEvent<T>) => {
      if ((event.event as KeyboardEvent).key === "Enter" && onRowClicked) {
        onRowClicked(event);
      }
    },
    [onRowClicked]
  );

  return (
    <AgGridReact
      ref={grid}
      css={agGridCss}
      rowData={data}
      onCellKeyDown={onCellKeyDown}
      columnDefs={columnDefs}
      animateRows={false}
      rowSelection="single"
      defaultColDef={{
        resizable: false,
        filter: false,
      }}
      components={components}
      rowHeight={rowHeight}
      headerHeight={35}
      getRowStyle={getRowStyle}
      context={context}
      onRowClicked={onRowClicked}
      noRowsOverlayComponent={noRowsOverlayComponent}
      getRowId={getRowId}
      suppressMovableColumns={true}
      suppressRowTransform={true}
      onSortChanged={onSortChange}
      onBodyScrollEnd={(e) => {
        const bottom_px = e.api.getVerticalPixelRange().bottom;
        const grid_height =
          e.api.getDisplayedRowCount() *
          e.api.getSizesForCurrentTheme().rowHeight;
        if (bottom_px > grid_height - 100) {
          onBottomReached?.();
        }
      }}
      onGridReady={onGridReady}
      tooltipShowDelay={0}
    />
  );
}
