// noinspection CssUnresolvedCustomProperty

import { useDragAndDropContext } from "./DragAndDropProvider";
import { css, useTheme } from "@emotion/react";
import { useImportantDraggingSize } from "./useImportantDraggingSize";
import { useEffect } from "react";

export function DNDGreyBox() {
  const theme = useTheme();
  const {
    gridGapPx,
    productCardHeight,
    productCardWidth,
    isDragging,
    refState,
  } = useDragAndDropContext();

  const { importantDraggingWidth, importantDraggingHeight } =
    useImportantDraggingSize();

  useEffect(() => {
    if (!isDragging.size) {
      refState.current.greyBox?.classList.remove("transition");
    }
  }, [isDragging]);

  return (
    <div
      className="grey-box"
      ref={(el) => (refState.current.greyBox = el)}
      css={css`
        --box-slots-width: ${importantDraggingWidth};
        --box-slots-height: ${importantDraggingHeight};
        position: absolute;
        top: ${gridGapPx / 2}px;
        left: 0;
        width: calc(
          calc(var(--box-slots-width) * ${productCardWidth}px) +
            calc(calc(var(--box-slots-width) - 1) * ${gridGapPx}px)
        );
        height: calc(
          calc(var(--box-slots-height) * ${productCardHeight}px) +
            calc(calc(var(--box-slots-height) - 1) * ${gridGapPx}px)
        );
        background: ${theme.colors.bg["soft-200"]};
        box-shadow: ${theme.shadows.regular.xs};
        border: 1px solid ${theme.colors.bg["sub-300"]};
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        transform: translate(var(--box-translate-x), var(--box-translate-y));
        opacity: 0;
        will-change: transform, opacity;
        transition: opacity 0.1s;

        &.transition {
          transition:
            transform 0.1s,
            opacity 0.1s;
        }
      `}
    >
      {!!isDragging.size && (
        <h3
          css={[
            theme.typography.h3,
            css`
              color: ${theme.colors.text["soft-400"]};
            `,
          ]}
        >
          +{isDragging.size}
        </h3>
      )}
    </div>
  );
}
