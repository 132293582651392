import { css, useTheme } from "@emotion/react";
import React, { useEffect, useRef } from "react";
import { useDragAndDropContext } from "./DragAndDropProvider";
import { motion } from "framer-motion";
import { PushPin } from "@phosphor-icons/react";
import { useDisallowScrollHint } from "../CollectionHeaderWrapper";
import { buildThresholdList } from "@depict-ai/utilishared/latest";

export function DNDPinIndicatorZone() {
  const theme = useTheme();

  const zoneRef = useRef<HTMLDivElement | null>(null);
  const pointerThatEnteredLeft = useRef<number | undefined>();
  const { refState, productsToRender } = useDragAndDropContext();
  const firstProductProductId = productsToRender[0].main_product_id;

  // need to add event listeners like this because chrome mobile, even when releasing pointer capture, doesn't dispatch pointerenter/pointerleave events and react doesn't receive our manually dispatched ones to its synthetic event system
  // We have to use these weird effects and handle removing the listener again because if we just did `ref={el => el && el.addEventListener` the information in the scope of the listener is outdated due to reacts re-rendering
  useEffect(() => {
    const div = zoneRef.current;
    if (!div) return;
    const enterHandler = ({ pointerId, target }: PointerEvent) => {
      if (pointerId !== refState.current.dragPointerId) return;
      pointerThatEnteredLeft.current = pointerId;
      // By using refs for the global state where we don't need to know in react when the value is updated during a drag (we only need the value when the drag ends)
      refState.current.hoveringOn = {
        type: "product",
        id: firstProductProductId,
        position: "left",
      };
      // Touch device supporting substitute for :hover in css
      (target as HTMLDivElement).classList.add("hovered");
    };
    const leaveHandler = ({ pointerId, target }: PointerEvent) => {
      if (pointerId !== pointerThatEnteredLeft.current) return;
      pointerThatEnteredLeft.current = undefined;
      refState.current.hoveringOn = undefined;
      (target as HTMLDivElement).classList.remove("hovered");
    };
    div.addEventListener("pointerenter", enterHandler);
    div.addEventListener("pointerleave", leaveHandler);
    return () => {
      div.removeEventListener("pointerenter", enterHandler);
      div.removeEventListener("pointerleave", leaveHandler);
    };
  }, [firstProductProductId, refState]);

  const { setDisallowScrollHint } = useDisallowScrollHint();

  useEffect(() => {
    const div = zoneRef.current;
    if (!div) return;
    const is = new IntersectionObserver(
      (records) => {
        const relevantRecord = records.at(-1)!;
        setDisallowScrollHint(relevantRecord.intersectionRatio > 0.9);
      },
      {
        threshold: buildThresholdList(100),
      }
    );
    is.observe(div);
    return () => {
      is.unobserve(div);
      setDisallowScrollHint(false);
    };
  }, [setDisallowScrollHint]);

  if (!firstProductProductId) return;

  return (
    <motion.div
      layout={true}
      ref={zoneRef}
      className="pin-indicator-zone dnd-zone"
      css={css`
        display: flex;
        padding: 16px 32px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;
        flex-shrink: 0;
        align-self: stretch;

        border-radius: 12px;
        border: 1px dashed ${theme.colors.primary.base};
        background: ${theme.colors.primary.alpha10};
        transition-duration: 100ms;

        cursor: copy;
        position: relative;

        &.hovered {
          background: ${theme.colors.primary.alpha24};
        }
      `}
    >
      <PushPin
        size={24}
        css={css`
          color: ${theme.colors.primary.base};
        `}
        weight="fill"
      />
      <span css={theme.typography["label-s"]}>
        Drop here to position manually
      </span>
    </motion.div>
  );
}
