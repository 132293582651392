import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { DNDRefState, DragAndDropState } from "./types";
import { useContentBlocks } from "../../ContentBlock/useContentBlocks";
import {
  LiteCollectionDto,
  LiteCollectionProductDto,
} from "../../../../api/types";
import { ProductDuplicate } from "../ProductHighlight/useProductDuplicates";
import { useAlert } from "../../../../alignUI/Alert/useAlert/useAlert";
import { DisallowScrollHintProvider } from "../CollectionHeaderWrapper";
import { useCollectionOnDropFactory } from "../collectionOnDropFactory";
import { ExtendedProContentBlockWithId } from "../../ContentBlock/types";
import { isRowMode } from "./useRowMode";
import useIsMobile from "../../../../helpers/hooks/useIsMobile";

const DragAndDropContext = createContext<DragAndDropState | undefined>(
  undefined
);

/**
 * Provider that makes it possible for all different D&D components to share a state. Also handles changing some global things in said state, such as select/deselect all and disabling zones when needed.
 * Technically all other components are children of DragAndDropGrid, so it could be consolidated but there's no need right now.
 */
export function DragAndDropProvider({
  children,
  selectedItems,
  setSelectedItems,
  occupiedByBlocksOrProducts,
  productsToRender,
  dndDisabled: parentWantsDNDDisabled,
  measuredHeaderHeight,
  selectingDisabled,
  firstUnpinnedProduct,
  lastPinnedProduct,
  productDuplicates,
  numberOfColumns,
  repositionedBlocksWithRender,
  runPositioningForDragState,
  collection,
  setLocalContentBlockState,
  setCollection,
}: PropsWithChildren<{
  selectedItems: Set<string>;
  setSelectedItems: React.Dispatch<React.SetStateAction<Set<string>>>;
  occupiedByBlocksOrProducts: ReturnType<
    typeof useContentBlocks
  >["occupiedByBlocksOrProducts"];
  productsToRender: LiteCollectionProductDto[];
  dndDisabled: boolean;
  measuredHeaderHeight: number;
  selectingDisabled: boolean;
  firstUnpinnedProduct: string | undefined;
  lastPinnedProduct: string | undefined;
  productDuplicates: ProductDuplicate[];
  numberOfColumns: number;
  repositionedBlocksWithRender: ReturnType<
    typeof useContentBlocks
  >["repositionedBlocksWithRender"];
  runPositioningForDragState: ReturnType<
    typeof useContentBlocks
  >["runPositioningForDragState"];
  collection: LiteCollectionDto;
  setLocalContentBlockState: Dispatch<
    SetStateAction<ExtendedProContentBlockWithId[]>
  >;
  setCollection: Dispatch<SetStateAction<LiteCollectionDto>>;
}>) {
  const [isDragging, setIsDragging] = useState<Set<string>>(new Set());
  const [cmdOrCtrlPressed, setCmdOrCtrlPressed] = useState(false);

  const [productCardDimensions, setProductCardDimensions] = useState<{
    width: number;
    height: number;
  } | null>(null);

  const allSelectableItemIds = [...new Set(occupiedByBlocksOrProducts)].map(
    (contentIdOrProductIndex) => {
      if (typeof contentIdOrProductIndex === "number") {
        return productsToRender[contentIdOrProductIndex].main_product_id;
      } else if (typeof contentIdOrProductIndex === "string") {
        return contentIdOrProductIndex;
      }
    }
  ) as string[];

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      const { key, metaKey, ctrlKey } = e;
      // deselect when pressing ESC, but not while dragging
      if (key === "Escape" && !isDragging) {
        setSelectedItems(new Set());
        e.preventDefault();
        return;
      }

      if (!ctrlKey && !metaKey) return;
      const inputElementFocussed =
        document.activeElement?.tagName === "INPUT" ||
        document.activeElement?.tagName === "TEXTAREA";

      // Select all/deselect all
      if (key === "a" && !inputElementFocussed) {
        setSelectedItems(new Set(allSelectableItemIds));
        e.preventDefault();
      } else if (key === "d" && !inputElementFocussed) {
        setSelectedItems(new Set());
        e.preventDefault();
      } else if (key === "Meta") {
        // Disable dropping when cmd or ctrl is pressed, like Apples photos app does. The reason is that allowing it makes it "impossible" to distinguish adding to a selection (pointer down + up) and accidentally dropping all items at that point (because during down and up a movement was made)
        setCmdOrCtrlPressed(true);
      }
    };
    window.addEventListener("keydown", handler);
    return () => window.removeEventListener("keydown", handler);
  }, [allSelectableItemIds, setSelectedItems, isDragging]);

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key !== "Meta") return;
      setCmdOrCtrlPressed(false);
    };
    window.addEventListener("keyup", handler);
    return () => window.removeEventListener("keyup", handler);
  }, []);

  // In Chrome on my machine the keyup event fires unreliably when cmd+tabbing away to an application and coming back, therefore, check on pointermove if they are still pressed
  useEffect(() => {
    if (!cmdOrCtrlPressed) return; // A no-op set state does like a fuckton of work in React so bail ASAP
    const handler = (e: PointerEvent) => {
      if (e.metaKey || e.ctrlKey) return;
      setCmdOrCtrlPressed(false);
    };
    window.addEventListener("pointermove", handler, { passive: true });
    return () => window.removeEventListener("pointermove", handler);
  }, [cmdOrCtrlPressed]);

  const dndDisabled = cmdOrCtrlPressed || parentWantsDNDDisabled;
  const shouldShowPinHints = useShouldShowPinHints({
    dndDisabled,
    isDragging,
    lastPinnedProduct,
    occupiedByBlocksOrProducts,
  });

  let gridStateWhileDragging: DragAndDropState["gridStateWhileDragging"];

  const localContentBlockState = repositionedBlocksWithRender.map(
    ({ block }) => block
  );
  if (isDragging.size && isDragging.size !== allSelectableItemIds.length) {
    const overrideLocalContentBlockState = localContentBlockState.filter(
      ({ DOMElementId }) => !isDragging.has(DOMElementId)
    );
    const filteredProducts = productsToRender.filter(
      (product) => !isDragging.has(product.main_product_id)
    );
    const { repositionedBlocksWithRender, occupiedByBlocksOrProducts } =
      runPositioningForDragState({
        overrideNumberOfProducts: filteredProducts.length,
        overrideLocalContentBlockState,
      });
    gridStateWhileDragging = {
      productsToRender: filteredProducts,
      repositionedBlocksWithRender,
      occupiedByBlocksOrProducts,
    };
  }

  const rowMode = isRowMode({
    isDragging,
    numberOfColumns,
    repositionedBlocksWithRender,
    occupiedByBlocksOrProducts,
    productsToRender,
  });

  const onDrop = useCollectionOnDropFactory({
    collection,
    productsToRender,
    occupiedByBlocksOrProducts,
    localContentBlockState,
    setLocalContentBlockState,
    numberOfColumns,
    setCollection,
    gridStateWhileDragging,
    rowMode,
    isDragging,
  });

  const refState = useRef<DNDRefState>({ onDrop, mountedGridItems: new Set() });
  useEffect(() => {
    refState.current.onDrop = onDrop;
  }, [onDrop]);

  const isMobile = useIsMobile();

  return (
    <DisallowScrollHintProvider>
      <DragAndDropContext.Provider
        value={{
          shouldShowPinHints,
          refState,
          selectedItems,
          setSelectedItems,
          isDragging,
          setIsDragging,
          occupiedByBlocksOrProducts,
          allSelectableItemIds,
          productsToRender,
          droppableGapSize: productCardDimensions?.width || 200,
          gridGapPx: isMobile ? 12 : 16,
          dndDisabled,
          productCardHeight: productCardDimensions?.height,
          productCardWidth: productCardDimensions?.width,
          setProductCardDimensions,
          selectingDisabled,
          firstUnpinnedProduct,
          lastPinnedProduct,
          productDuplicates,
          numberOfColumns,
          measuredHeaderHeight,
          repositionedBlocksWithRender,
          gridStateWhileDragging: gridStateWhileDragging,
        }}
      >
        {/* This style tag is being used to, without triggering slow react re-renders, set css variables which do the animations in the grid during a drag.
          This enables insane smoothness.*/}
        <style
          type="text/css"
          ref={(el) => (refState.current.animationStyle = el)}
        />
        {children}
      </DragAndDropContext.Provider>
    </DisallowScrollHintProvider>
  );
}

export function useDragAndDropContext() {
  const context = useContext(DragAndDropContext);
  if (!context) {
    throw new Error(
      "Only use useDragAndDropContext within a <DragAndDropProvider>"
    );
  }
  return context;
}

function useShouldShowPinHints({
  dndDisabled,
  isDragging,
  lastPinnedProduct,
  occupiedByBlocksOrProducts,
}: {
  dndDisabled: boolean;
  isDragging: Set<string>;
  lastPinnedProduct: string | undefined;
  occupiedByBlocksOrProducts: ReturnType<
    typeof useContentBlocks
  >["occupiedByBlocksOrProducts"];
}) {
  let isDraggingNotOnlyContentBlocks = false;
  for (const item of isDragging) {
    if (!occupiedByBlocksOrProducts.includes(item)) {
      // This is not a content block, because for products we have indexes in occupiedByBlocksOrProducts to make it agnostic to the actual products existing.
      // We do not want to wiggle/indicate to drop at the top if the user is only dragging content blocks, as they can be dropped everywhere anyway
      isDraggingNotOnlyContentBlocks = true;
      break;
    }
  }

  const shouldShowPinHints = !!(
    !dndDisabled &&
    isDragging.size &&
    !lastPinnedProduct &&
    isDraggingNotOnlyContentBlocks
  );

  const { addAlert } = useAlert();

  useEffect(() => {
    if (!shouldShowPinHints) return;
    addAlert({
      text: "Drag to top to position manually.",
      styling: "filled",
      size: "large",
      autoClose: 5000,
      status: "information",
    });
  }, [addAlert, shouldShowPinHints]);

  return shouldShowPinHints;
}
