import { Suspense, lazy, useCallback, useMemo, useState } from "react";
import { CollectionOrderBy, LiteDashboardCollectionDto } from "src/api/types";
import { Interpolation, Theme, css, useTheme } from "@emotion/react";
import { Link } from "react-router-dom";
import { Collection } from "./CollectionTable.types";
import {
  ChartLine,
  DotsThreeVertical,
  Pause,
  Timer,
} from "@phosphor-icons/react";
import useDashboardCollectionData from "../../Dashboard/useDashboardCollectionData";
import useGetCollectionsOutOfStockStatus from "../useGetCollectionsOutOfStockStatus";
import { useMultiStore } from "src/lite/helpers/useMultiStore";
import { Button } from "src/alignUI/Button/Button";
import { Search } from "src/alignUI/Search/Search";
import { CollectionImage } from "../CollectionImage";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "../../../../alignUI/Dropdown/Dropdown";
import useIsMobile from "src/helpers/hooks/useIsMobile";
import { components } from "../../../../api/generated/openapi";
import {
  TabMenuHorizontal,
  TabMenuHorizontalItem,
} from "src/alignUI/TabMenuHorizontal/TabMenuHorizontal";
import useInstalledMoreThanADay from "../../../../helpers/hooks/useInstalledMoreThanADay";
import {
  BadgeGroup,
  CollectionBadge,
  CollectionStateBadge,
} from "src/lite/components/CollectionBadges";

const ClickthroughRateModal = lazy(
  () => import("../../Dashboard/Modal/ClickthroughRateModal")
);

const TOP_N = 10;

const collectionOrderByValues: { name: string; key: CollectionOrderBy }[] = [
  { name: "Recent", key: "updated_at" },
  { name: "Top Views", key: "top_views" },
  { name: "Top Clicks", key: "top_clicks" },
  { name: "Top CTR", key: "top_ctr" },
  { name: "A-Z", key: "name_a_z" },
];

const fakeDataWhileLoading: Collection[] = Array.from(
  { length: 12 },
  (_, index) => {
    const collection: Collection = {
      coverImages: undefined,
      productImages: undefined,
      title: "",
      numProducts: 0,
      sorting: "BEST_SELLING",
      hasDepictConfiguration: true,
      syncBackToShopify: true,
      id: index.toString(),
      numPins: 0,
      badges: [],
      missingInSubStores: [],
      views: null,
      clicks: null,
      clickthrough_rate: null,
    };

    return collection;
  }
);

interface CollectionTableProps {
  collections: LiteDashboardCollectionDto[];
  isLoading: boolean;
  noRowsOverlayComponent?: JSX.Element;
  onSearch: (search: string) => void;
  setOrderBy: (orderBy: CollectionOrderBy) => void;
  orderBy: CollectionOrderBy;
  loadMoreRef: (element: Element | null) => void;
  onUnPublishDepict: (collectionId: string) => void;
  collectionsData:
    | components["schemas"]["LiteDashboardCollectionDto"][]
    | undefined;
  collectionsDataLoading: boolean;
  collectionTopClickDataQuery: ReturnType<typeof useDashboardCollectionData>;
}

export const CollectionTable = ({
  collections,
  isLoading,
  onSearch,
  setOrderBy,
  orderBy,
  loadMoreRef,
  noRowsOverlayComponent: noRowsFallback,
  onUnPublishDepict,
  collectionsData,
  collectionsDataLoading,
  collectionTopClickDataQuery,
}: CollectionTableProps) => {
  const { byShopId: multiStores } = useMultiStore();
  const toDate = useMemo(() => new Date(), []);
  const fromDate = useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() - 30);
    return date;
  }, []);

  const topPopularCollections = useMemo(
    () =>
      collectionTopClickDataQuery.data?.pages
        .flatMap((page) => page.collections)
        .slice(0, TOP_N)
        .reduce((mem, collection, index) => {
          mem.set(collection.collection_id, {
            type: "popular",
            rank: index + 1,
            clicks: collection.selected_period_data.clicks || 0,
          });
          return mem;
        }, new Map<string, CollectionBadge>()),
    [collectionTopClickDataQuery.data]
  );

  const collectionTopConversionDataQuery = useDashboardCollectionData({
    from_date: fromDate,
    to_date: toDate,
    order_by: "clickthrough_rate_desc",
    page_size: TOP_N,
  });
  const topConvertingCollections = useMemo(
    () =>
      collectionTopConversionDataQuery.data?.pages
        .flatMap((page) => page.collections)
        .slice(0, TOP_N)
        .reduce((mem, collection, index) => {
          if (!collection.selected_period_data.clickthrough_rate) return mem;
          mem.set(collection.collection_id, {
            type: "conversion_rate",
            rank: index + 1,
            conversionRate: collection.selected_period_data.clickthrough_rate,
          });
          return mem;
        }, new Map<string, CollectionBadge>()),
    [collectionTopConversionDataQuery.data]
  );

  const { data: outOfStockStatus } = useGetCollectionsOutOfStockStatus();

  const data = useMemo(() => {
    if (isLoading) {
      return fakeDataWhileLoading;
    }

    return collections.map((collection) => {
      const syncBackToShopify = collection.sync_back_to_shopify;
      const hasDepictConfiguration = collection.has_depict_configuration;
      const sorting = syncBackToShopify
        ? collection.sort_order
        : collection.shopify_sort_order;

      const badges = [
        topConvertingCollections?.get(collection.collection_id),
        topPopularCollections?.get(collection.collection_id),
      ].filter((badge): badge is CollectionBadge => !!badge);

      const productsOutOfStock = outOfStockStatus?.[collection.collection_id];

      if (productsOutOfStock && productsOutOfStock.length > 0) {
        badges.push({
          type: "out_of_stock",
          mainProductIds: productsOutOfStock,
        });
      }

      const missingShopIds = Object.keys(multiStores ?? {}).filter(
        (shopId) => !collection.connected_shop_ids?.includes(shopId)
      );

      if (syncBackToShopify) {
        badges.unshift({ type: "live" });
      } else if (hasDepictConfiguration) {
        badges.unshift({ type: "draft" });
      }

      const _collection: Collection = {
        id: collection.collection_id,
        coverImages: collection.image_urls,
        productImages: collection.product_images,
        title: collection.title,
        sorting,
        syncBackToShopify,
        hasDepictConfiguration,

        numProducts: collection.n_products,
        numPins: syncBackToShopify
          ? collection.n_pinned_main_product_ids
          : null,

        badges,
        missingInSubStores:
          missingShopIds && multiStores
            ? (missingShopIds
                .map((shopId) => multiStores[shopId]?.shopify_base_url)
                .filter((d) => d) as string[])
            : [],
        views: collection.selected_period_data.views,
        clicks: collection.selected_period_data.clicks,
        clickthrough_rate: collection.selected_period_data.clickthrough_rate,
      };

      return _collection;
    });
  }, [
    collections,
    isLoading,
    multiStores,
    outOfStockStatus,
    topConvertingCollections,
    topPopularCollections,
  ]);

  const [selectedDashboardCollectionId, setSelectedDashboardCollectionId] =
    useState<string | undefined>(undefined);

  const onClose = useCallback(
    () => void setSelectedDashboardCollectionId(undefined),
    [setSelectedDashboardCollectionId]
  );

  const installedMoreThanADay = useInstalledMoreThanADay();

  return (
    <>
      <Rows
        onSearch={onSearch}
        collections={data}
        setOrderBy={setOrderBy}
        orderBy={orderBy}
        isLoading={isLoading}
        loadMoreRef={loadMoreRef}
        setSelectedCollectionId={setSelectedDashboardCollectionId}
        onUnPublishDepict={onUnPublishDepict}
        noRowsFallback={noRowsFallback}
        showDataPlaceHolder={!installedMoreThanADay}
      />
      <Suspense>
        {selectedDashboardCollectionId && (
          <ClickthroughRateModal
            onSetSelectedCollectionId={setSelectedDashboardCollectionId}
            selectedCollectionId={selectedDashboardCollectionId}
            open={selectedDashboardCollectionId !== undefined}
            onClose={onClose}
            collectionsData={collectionsData}
            collectionsDataLoading={collectionsDataLoading}
          />
        )}
      </Suspense>
    </>
  );
};

function Rows({
  collections,
  onSearch,
  setOrderBy,
  orderBy,
  isLoading,
  loadMoreRef,
  onUnPublishDepict,
  noRowsFallback: noRowsFallback,
  showDataPlaceHolder,
  setSelectedCollectionId,
}: {
  collections: Collection[];
  onSearch: (search: string) => void;
  setOrderBy: (orderBy: CollectionOrderBy) => void;
  orderBy: CollectionOrderBy;
  isLoading: boolean;
  loadMoreRef: (element: Element | null) => void;
  setSelectedCollectionId: (collectionId: string) => void;
  onUnPublishDepict: (collectionId: string) => void;
  noRowsFallback?: JSX.Element;
  showDataPlaceHolder: boolean;
}) {
  const isMobile = useIsMobile();
  return (
    <>
      <div
        css={[
          (theme) => css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 16px;

            @media (max-width: ${theme.breakpoints.sm}px) {
              flex-direction: column-reverse;
              gap: 4px;
              align-items: start;
            }
          `,
        ]}
      >
        <OrderByDropDown orderBy={orderBy} setOrderBy={setOrderBy} />
        <Search openWidth={120} onSearch={onSearch} />
      </div>

      {collections.length === 0 ? (
        noRowsFallback
      ) : (
        <div
          css={(theme) => css`
            border: 1px solid ${theme.colors.stroke["soft-200"]};
            border-radius: 16px;
            margin: 0 0 8px 0;
            padding: 8px;

            display: grid;
            grid-template-columns: 120px minmax(0, 1fr) 150px 80px 80px 80px 80px auto;
            @media (max-width: ${theme.breakpoints.sm}px) {
              grid-template-columns: 86px minmax(0, 1fr) 80px auto;
            }
            align-items: center;
          `}
        >
          {!isMobile && (
            <div
              css={(theme) => css`
                display: grid;
                grid-template-columns: subgrid;
                border-radius: 8px;
                grid-column: span 8;
                background: ${theme.colors.bg["weak-50"]};
                margin-bottom: 8px;

                // Sticky header
                @media (min-width: ${theme.breakpoints.sm + 1}px) {
                  position: sticky;
                  top: 0;
                  z-index: 10;
                }
              `}
            >
              <HeaderElement
                customCss={css`
                  grid-column: 3/4;
                `}
                title={"Status"}
              />
              <HeaderElement title={"Products"} />
              <HeaderElement title={"Views"} />
              <HeaderElement title={"Clicks"} />
              <HeaderElement title={"CTR"} />
            </div>
          )}

          {collections.map((collection) => (
            <Row
              key={collection.id}
              collection={collection}
              isLoading={isLoading}
              setSelectedCollectionId={setSelectedCollectionId}
              onUnPublishDepict={onUnPublishDepict}
              showDataPlaceHolder={showDataPlaceHolder}
            />
          ))}
          <span ref={loadMoreRef}></span>
        </div>
      )}
    </>
  );
}

function Row({
  collection,
  isLoading,
  onUnPublishDepict,
  showDataPlaceHolder,
  setSelectedCollectionId,
}: {
  collection: Collection;
  isLoading: boolean;
  onUnPublishDepict: (collectionId: string) => void;
  showDataPlaceHolder: boolean;
  setSelectedCollectionId: (collectionId: string) => void;
}) {
  const theme = useTheme();
  const isMobile = useIsMobile();

  return (
    <>
      <div
        css={css`
          display: grid;
          align-items: center;
          grid-column: span 8;
          grid-template-columns: subgrid;
          padding: 4px;
          border-radius: 8px;

          .collection-link {
            color: ${theme.colors.text["strong-950"]};
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
          &:has(.collection-link:hover) {
            background: ${theme.colors.bg["weak-50"]};
          }

          /* For link overlay */
          position: relative;
          button,
          .lift {
            z-index: 1;
            position: relative;
          }

          :not(:last-of-type):after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            width: calc(100% - 98px);
            border-bottom: 1px solid ${theme.colors.stroke["soft-200"]};
          }
        `}
      >
        <CollectionImage
          showPlaceholder={isLoading}
          coverImages={collection.coverImages}
          productImages={collection.productImages}
          size="small"
        />
        <div
          css={css`
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          `}
        >
          <Link
            to={collection.id}
            className="collection-link"
            css={[
              css`
                &:before {
                  position: absolute;
                  display: block;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  content: "";
                }
              `,
              (theme) => theme.typography["label-m"],
            ]}
          >
            {collection.title}
          </Link>
        </div>
        {isMobile && (
          <div>
            <CollectionStateBadge
              syncBackToShopify={collection.syncBackToShopify}
              hasDepictConfiguration={collection.hasDepictConfiguration}
            />
          </div>
        )}

        {!isMobile && (
          <>
            {/* Status */}
            <div
              css={[
                (theme) => theme.typography["label-s"],
                css`
                  padding: 12px 8px;
                  display: flex;
                  gap: 4px;
                `,
              ]}
            >
              {!isLoading && <BadgeGroup badges={collection.badges} />}
            </div>

            {/* Products */}
            <div
              css={[
                (theme) => theme.typography["label-s"],
                css`
                  padding: 12px 8px;
                `,
              ]}
            >
              {!isLoading ? collection.numProducts : null}
            </div>

            {/* Views */}
            {!showDataPlaceHolder ? (
              <>
                <div
                  css={[
                    (theme) => theme.typography["label-s"],
                    css`
                      padding: 12px 8px;
                    `,
                  ]}
                >
                  {collection.views}
                </div>
                {/* Clicks */}
                <div
                  css={[
                    (theme) => theme.typography["label-s"],
                    css`
                      padding: 12px 8px;
                    `,
                  ]}
                >
                  {collection.clicks}
                </div>
                {/* CTR */}
                <div
                  css={[
                    (theme) => theme.typography["label-s"],
                    css`
                      padding: 12px 8px;
                    `,
                  ]}
                >
                  {isLoading
                    ? null
                    : collection.clickthrough_rate
                      ? collection.clickthrough_rate.toFixed(0) + "%"
                      : 0 + "%"}
                </div>
              </>
            ) : (
              <>
                {!isLoading && (
                  <div
                    css={[
                      (theme) => theme.typography["label-s"],
                      css`
                        padding: 12px 8px;
                        grid-column: span 3;
                        color: ${theme.colors.text["soft-400"]};
                        display: flex;
                        align-items: center;
                        gap: 6px;
                      `,
                    ]}
                  >
                    <Timer size={20} />
                    Collecting views & clicks.
                  </div>
                )}
              </>
            )}
          </>
        )}

        {/* Actions */}
        {!isLoading && (
          <div
            css={css`
              display: flex;
              justify-content: end;
            `}
          >
            <Button
              _style="ghost"
              variant="neutral"
              size="small"
              onClick={() => {
                setSelectedCollectionId(collection.id);
              }}
            >
              <ChartLine size={20} />
            </Button>
            {!isMobile && (
              <OverFlowMenuDropDown
                onUnPublishDepict={
                  collection.syncBackToShopify
                    ? () => onUnPublishDepict(collection.id)
                    : undefined
                }
              />
            )}
          </div>
        )}
      </div>
    </>
  );
}

const HeaderElement = ({
  title,
  customCss,
}: {
  title: string;
  customCss?: Interpolation<Theme>;
}) => {
  return (
    <>
      <div
        css={(theme) => [
          theme.typography["paragraph-s"],
          css`
            padding: 10px 8px;
            color: ${theme.colors.text["sub-600"]};
          `,
          customCss,
        ]}
      >
        {title}
      </div>
    </>
  );
};

const OrderByDropDown = ({
  orderBy,
  setOrderBy,
}: {
  orderBy: CollectionOrderBy;
  setOrderBy: (orderBy: CollectionOrderBy) => void;
}) => {
  return (
    <TabMenuHorizontal
      value={orderBy}
      onValueChange={(value) => {
        setOrderBy(value as CollectionOrderBy);
      }}
    >
      {collectionOrderByValues.map((collectionOrderByValue) => (
        <TabMenuHorizontalItem
          key={collectionOrderByValue.name}
          value={collectionOrderByValue.key}
        >
          {collectionOrderByValue.name}
        </TabMenuHorizontalItem>
      ))}
    </TabMenuHorizontal>
  );
};

const OverFlowMenuDropDown = ({
  onUnPublishDepict,
}: {
  onUnPublishDepict: (() => void) | undefined;
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const theme = useTheme();

  return (
    <DropdownMenu open={openDropdown} onOpenChange={setOpenDropdown}>
      <DropdownMenuTrigger asChild>
        <Button
          _style="ghost"
          variant="neutral"
          onClick={() => {
            setOpenDropdown(!openDropdown);
          }}
        >
          <DotsThreeVertical size={20} weight="bold" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>Options</DropdownMenuLabel>
        <DropdownMenuItem asChild>
          <Button
            css={[
              theme.typography["paragraph-s"],
              css`
                display: flex;
                align-items: center;
                justify-content: left;
              `,
            ]}
            disabled={!onUnPublishDepict}
            onClick={onUnPublishDepict}
            variant={"danger"}
            _style={"ghost"}
          >
            <Pause size={20} />
            Un-publish
          </Button>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
