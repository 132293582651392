import { DefaultPeriod, getFromPeriod, Period } from "../../Dashboard/util";
import React, { useMemo, useState } from "react";
import useIsMobile from "../../../../helpers/hooks/useIsMobile";
import { css, useTheme } from "@emotion/react";
import PageHeader from "../../../../alignUI/PageHeader/PageHeader";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "../../../../alignUI/Dropdown/Dropdown";
import { Button } from "../../../../alignUI/Button/Button";
import { CaretDown } from "@phosphor-icons/react";
import TimeFramePicker from "../../Dashboard/TimeFramePicker";
import { JimoBell } from "../JimoBell";
import useMerchant from "../../../../helpers/hooks/app/useMerchant";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../alignUI/Tooltip/Tooltip";
import { ButtonGroupItem } from "../../../../alignUI/ButtonGroup/ButtonGroup";
import DatePickerModalContent from "../../../../alignUI/DatePicker/DatePickerModalContent";

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
} from "../../../../alignUI/Modal/Modal";

export function DatePickerAndTitle({
  setPeriod,
  period,
}: {
  period: Period;
  setPeriod: React.Dispatch<React.SetStateAction<Period>>;
}) {
  const isMobile = useIsMobile();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const now = useMemo(() => new Date(), []);

  const defaultPeriods: DefaultPeriod[] = ["24h", "7d", "4w", "6m", "1y"];
  const theme = useTheme();

  const { merchant } = useMerchant();
  const minTimestamp = merchant?.start_timestamp
    ? new Date(merchant.start_timestamp * 1000)
    : new Date();

  const [customOpen, setCustomOpen] = useState(false);

  return (
    <PageHeader
      title="Collections"
      outerCss={css`
        padding: 0 16px;
        margin: 0;
      `}
      wrapperCss={css`
        display: flex;
        gap: 12px;
      `}
    >
      <DropdownMenu
        open={dropdownOpen}
        onOpenChange={(open) => setDropdownOpen(open)}
      >
        <Tooltip>
          <TooltipTrigger
            css={css`
              background: none;
              border: none;
              padding: 0;
              margin: 0;
            `}
          >
            <DropdownMenuTrigger asChild>
              <Button variant="neutral" _style="stroke" size={"x-small"}>
                {period.type.toUpperCase()}
                <CaretDown size={20} />
              </Button>
            </DropdownMenuTrigger>
          </TooltipTrigger>
          <TooltipContent>
            <span>
              Range:{" "}
              {period.fromDate.toLocaleDateString([], {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}{" "}
              -{" "}
              {period.toDate.toLocaleDateString([], {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </span>
          </TooltipContent>
        </Tooltip>
        <DropdownMenuContent>
          <DropdownMenuLabel>Select timeframe</DropdownMenuLabel>
          {defaultPeriods.map((timeValue) => (
            <DropdownMenuItem
              data-item={timeValue}
              disabled={minTimestamp > getFromPeriod(timeValue, now)}
              onSelect={({ target }) =>
                setPeriod({
                  type: (target as HTMLDivElement).dataset
                    .item as DefaultPeriod,
                  fromDate: getFromPeriod(
                    (target as HTMLDivElement).dataset.item as DefaultPeriod,
                    now
                  )!,
                  toDate: now,
                })
              }
            >
              {timeValue.toUpperCase()}
            </DropdownMenuItem>
          ))}
          <DropdownMenuItem
            onSelect={() => setCustomOpen(true)}
            disabled={false}
          >
            Custom
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <JimoBell />
      <Modal open={customOpen} onOpenChange={setCustomOpen}>
        <DatePickerModalContent
          from={period.fromDate}
          to={period.toDate}
          onSelect={(from, to) =>
            setPeriod({ type: "custom", fromDate: from, toDate: to })
          }
          minDate={minTimestamp}
        />
      </Modal>
    </PageHeader>
  );
}
