import { useEffect, useRef } from "react";
import { listenToPointerOrTouchEvent, PointerEventLike } from "./events";

const fullSpeed = 15; // vh (viewport height) per second

/**
 * Scrolls the page with a velocity relative to an exponential curve within either the top or bottom zone this creates at the top/bottom of the DragAndDropGrid.
 */
export function useAutoscroll({
  enable,
  measuredHeaderHeight,
  getDraggingPointerId,
}: {
  enable: boolean;
  measuredHeaderHeight: number;
  getDraggingPointerId: () => number | undefined;
}) {
  const state = useRef<{
    pointerLocations: Map<number, { x: number; y: number }>;
    callOnChange: Set<VoidFunction>;
  }>({ callOnChange: new Set(), pointerLocations: new Map() });

  // Create map of pointer id -> coordinates that's always updated
  useEffect(() => {
    const updatePointerHandler = (e: PointerEventLike) => {
      state.current.pointerLocations.set(e.pointerId, {
        x: e.clientX,
        y: e.clientY,
      });
      state.current.callOnChange.forEach((fn) => fn());
    };
    const deletePointerHandler = (e: PointerEventLike) => {
      state.current.pointerLocations.delete(e.pointerId);
      state.current.callOnChange.forEach((fn) => fn());
    };
    const removeMove = listenToPointerOrTouchEvent(
      window,
      "pointermove",
      updatePointerHandler
    );
    const removeDown = listenToPointerOrTouchEvent(
      window,
      "pointerdown",
      updatePointerHandler
    );
    const removeCancel = listenToPointerOrTouchEvent(
      window,
      "pointercancel",
      deletePointerHandler
    );
    const removeUp = listenToPointerOrTouchEvent(
      window,
      "pointerup",
      deletePointerHandler
    );
    return () => {
      removeMove();
      removeDown();
      removeCancel();
      removeUp();
    };
  }, []);

  useEffect(() => {
    if (!enable) return;
    let gotCleanedUp = false;
    let speedPercent = 0; // negative = up, positive = down, not actually percent (1 = 100%, goes over 1 sometimes, and we want that)
    let lastTimestamp = 0;
    let fps = 60; // Default FPS value

    const doInitiallyAndOnUpdate = () => {
      const draggingPointerId = getDraggingPointerId();
      // In firefox main pointer id is 0 so we have to check for undefined/null explicitly
      if (draggingPointerId == undefined) return;

      const pointerLocation =
        state.current.pointerLocations.get(draggingPointerId);
      if (!pointerLocation) return;
      const { innerHeight } = window;
      const { y } = pointerLocation;

      // 15% zone at top of the draggable area
      const zoneSize = innerHeight * 0.15;
      const upperZoneEnd = measuredHeaderHeight + zoneSize;
      const lowerZoneStart = innerHeight - zoneSize;
      const isInUpperZone = y < upperZoneEnd;
      const isInLowerZone = y > lowerZoneStart;

      // Exponential increase in speed percent as you get closer to 100%
      if (isInUpperZone) {
        speedPercent = (upperZoneEnd - y) / zoneSize;
        speedPercent = -Math.pow(speedPercent, 3); // Exponential increase
      } else if (isInLowerZone) {
        speedPercent = (y - lowerZoneStart) / zoneSize;
        speedPercent = Math.pow(speedPercent, 3); // Exponential increase
      } else {
        speedPercent = 0;
      }
    };

    const doPerFrame = (timestamp: number) => {
      if (gotCleanedUp) return;

      // Calculate FPS
      if (lastTimestamp > 0) {
        const delta = timestamp - lastTimestamp;
        fps = 1000 / delta;
      }
      lastTimestamp = timestamp;

      const speedPerSecond = fullSpeed * window.innerHeight * speedPercent;
      const speedPerFrame = speedPerSecond / fps;
      window.scrollBy(0, speedPerFrame);

      requestAnimationFrame(doPerFrame);
    };
    requestAnimationFrame(doPerFrame);

    state.current.callOnChange.add(doInitiallyAndOnUpdate);
    doInitiallyAndOnUpdate();

    return () => {
      state.current.callOnChange.delete(doInitiallyAndOnUpdate);
      gotCleanedUp = true;
    };
  }, [enable, measuredHeaderHeight, getDraggingPointerId]);
}
