import {
  ButtonGroup,
  ButtonGroupItem,
} from "../../../alignUI/ButtonGroup/ButtonGroup";
import { getFromPeriod, Period } from "./util";
import useMerchant from "../../../helpers/hooks/app/useMerchant";

export default function TimeFramePicker({
  now,
  period,
  setPeriod,
}: {
  now: Date;
  period: Period;
  setPeriod: (period: Period) => void;
}) {
  const { merchant } = useMerchant();
  const minTimestamp = merchant?.start_timestamp
    ? new Date(merchant.start_timestamp * 1000)
    : new Date();

  return (
    <ButtonGroup
      type={"single"}
      onValueChange={(value) => {
        switch (value) {
          case "24h":
          case "7d":
          case "4w":
          case "6m":
          case "1y":
            setPeriod({
              type: value,
              fromDate: getFromPeriod(value, now),
              toDate: now,
            });
            break;
          case "custom":
            break;
        }
      }}
      value={period.type}
    >
      <ButtonGroupItem value={"24h"}>24H</ButtonGroupItem>
      <ButtonGroupItem
        disabled={minTimestamp > getFromPeriod("7d", now)}
        value={"7d"}
      >
        7D
      </ButtonGroupItem>
      <ButtonGroupItem
        disabled={minTimestamp > getFromPeriod("4w", now)}
        value={"4w"}
      >
        4W
      </ButtonGroupItem>
      <ButtonGroupItem
        disabled={minTimestamp > getFromPeriod("6m", now)}
        value={"6m"}
      >
        6M
      </ButtonGroupItem>
      <ButtonGroupItem
        disabled={minTimestamp > getFromPeriod("1y", now)}
        value={"1y"}
      >
        1Y
      </ButtonGroupItem>
    </ButtonGroup>
  );
}
