import { css, useTheme } from "@emotion/react";
import { X } from "@phosphor-icons/react";
import useGetProps from "./Alert.helper";
import { AlertProps } from "./Alert.types";

export const Alert = (props: AlertProps) => {
  const config = useGetProps(props.status, props.size, props.styling);

  const Icon = config.icons.leftIcon;

  const theme = useTheme();

  return (
    <div
      css={[
        css`
          ${props.expand ? "" : "width: 390px;"}
          display: flex;
          padding: ${config.container.padding};
          align-items: ${config.container.alignItems};
          gap: ${config.container.gap};
          border-radius: ${config.container.borderRadius};
          background-color: ${config.container.backgroundColor};
          box-shadow: ${config.container.boxShadow};
          border: ${config.container.border};
        `,
        props.customCss,
      ]}
    >
      {!props.iconOverride && (
        <Icon
          size={config.icons.size}
          color={config.icons.color}
          weight={"fill"}
        />
      )}
      {props.iconOverride}
      {props.size === "x-small" || props.size === "small" ? (
        <span
          css={[
            config.text.layout,
            config.text.typography,
            css`
              color: ${config.container.textColor};
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              text-align: start;
            `,
          ]}
        >
          {props.text}
        </span>
      ) : (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: 10px;
            align-items: flex-start;
          `}
        >
          <span
            css={[
              config.text.layout,
              config.text.typography,
              css`
                color: ${config.container.textColor};
              `,
            ]}
          >
            {props.text}
          </span>
          {props.description && (
            <span
              css={[
                config.text.layout,
                theme.typography["paragraph-s"],
                css`
                  color: ${config.container.textColor};
                `,
              ]}
            >
              {props.description}
            </span>
          )}
          {props.component}
        </div>
      )}
      {(props.size === "x-small" || props.size === "small") && props.component}
      {props.dismissIcon && (
        <X
          size={config.icons.size}
          color={config.icons.color}
          css={css`
            cursor: pointer;
          `}
          opacity={0.72}
        />
      )}
    </div>
  );
};
