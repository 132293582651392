import { useDragAndDropContext } from "./DragAndDropProvider";
import { useSortByGridOrderPrioritiseBlocks } from "./useSortByGridOrder";
import { useMemo } from "react";

/**
 * Returns the size of the most important item being dragged. That being the first item in the grid order, of the dragging items, with all blocks taking priority over products (being earlier).
 */
export function useImportantDraggingSize() {
  const { isDragging, repositionedBlocksWithRender, numberOfColumns } =
    useDragAndDropContext();
  const { blocks } = useSortByGridOrderPrioritiseBlocks(isDragging);

  return useMemo(() => {
    const dimensionsByBlockId = Object.fromEntries(
      repositionedBlocksWithRender.map(
        ({ block: { DOMElementId, span_columns, span_rows } }) => [
          DOMElementId,
          { span_columns, span_rows },
        ]
      )
    );
    const firstBlock = blocks[0];
    const spansColumns =
      (firstBlock && dimensionsByBlockId[firstBlock].span_columns) || 1;
    const spanRows =
      (firstBlock && dimensionsByBlockId[firstBlock].span_rows) || 1;

    return {
      importantDraggingWidth: Math.min(numberOfColumns, spansColumns),
      importantDraggingHeight: spanRows,
      spanColumnsByBlockId: dimensionsByBlockId,
    };
  }, [repositionedBlocksWithRender, blocks, numberOfColumns]);
}
