// noinspection CssUnresolvedCustomProperty

import { css, Global, useTheme } from "@emotion/react";
import React, { useEffect } from "react";
import { LiteCollectionProductDto } from "../../../../api/types";
import ProductCard, {
  PlaceholderProductCard,
} from "../../../../alignUI/ProductCard/ProductCard";
import { useWindowVirtualizer } from "@tanstack/react-virtual";
import { useDragAndDropContext } from "./DragAndDropProvider";
import { motion } from "framer-motion";
import { VirtualRow, virtualRowClass } from "./DNDVirtualRow";
import { DNDCardStack } from "./DNDCardStack";
import {
  dragToSelectAndDeselectClass,
  useDragToSelect,
} from "./useDragToSelect";
import { useAutoscroll } from "./useAutoscroll";
import { createPortal } from "react-dom";
import { useRowMode } from "./useRowMode";
import { ProductCardDimensionMeasurer } from "./ProductCardDimensionMeasurer";
import useIsMobile from "../../../../helpers/hooks/useIsMobile";
import { useZone } from "./useZone";
import { DNDGreyBox } from "./DNDGreyBox";

export type CollectionProductsGridView = "desktop" | "mobile";

export const dndGridClass = "dnd-grid";

/**
 * A quite tight-knit and purpose built drag and drop grid. This is the component that renders the virtualized grid that contains products and content blocks that then can be dragged around.
 * Also contains all the styling for drag and drop.
 */
export function DragAndDropGrid({
  zoomLevel,
  className,
  getProductCardProps,
  showPlaceholders: parentWantsPlaceholders,
  blockIdToStartRow,
}: {
  zoomLevel: number;
  className: string;
  showPlaceholders: boolean;
  getProductCardProps: (
    product: LiteCollectionProductDto
  ) => Omit<Parameters<typeof ProductCard>[0], "product">;
  blockIdToStartRow: Record<string, number>;
}) {
  // Other todos:
  // TODO: Check if rows containing only blocks and only one block work as on storefront
  // TODO: check that content blocks that are no longer inbetween products are displayed correctly
  // TODO: refactor out product cards into a renderProduct
  // TODO: animate card stack being created or cancelled
  const enableZoneDebug = false && process.env.NODE_ENV === "development";
  const enableZoneHoverIndicator = enableZoneDebug;

  const {
    isDragging,
    droppableGapSize,
    refState,
    gridGapPx,
    productCardHeight,
    numberOfColumns,
    measuredHeaderHeight,
    gridStateWhileDragging,
    occupiedByBlocksOrProducts,
    productsToRender,
  } = useDragAndDropContext();
  const showPlaceholders =
    parentWantsPlaceholders || productCardHeight == undefined;
  const occupiedByBlocksOrProductsToUse =
    gridStateWhileDragging?.occupiedByBlocksOrProducts ||
    occupiedByBlocksOrProducts;
  const numberOfRows = Math.ceil(
    occupiedByBlocksOrProductsToUse.length / numberOfColumns
  );

  const rowMode = useRowMode();
  const isMobile = useIsMobile();

  useEffect(() => {
    document.body.style.setProperty(
      "--dnd-base-z-index",
      numberOfRows + 1 + ""
    );
    return () => {
      document.body.style.removeProperty("--dnd-base-z-index");
    };
  }, [numberOfRows]);

  const rowVirtualizer = useWindowVirtualizer({
    count: showPlaceholders ? 0 : numberOfRows,
    estimateSize: () => (productCardHeight ?? 0) + gridGapPx,
    overscan: numberOfColumns,
  });

  useEffect(() => {
    rowVirtualizer.measure();
  }, [productCardHeight, rowVirtualizer]);

  const theme = useTheme();
  const dndGridRef = React.useRef<HTMLDivElement>(null);

  const dropShadow = `filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.03))
                  drop-shadow(0px 16px 32px rgba(0, 0, 0, 0.1))
                  drop-shadow(0px 32px 40px rgba(0, 0, 0, 0.24));`;

  const dragSelectingPointerId = useDragToSelect(dndGridRef);

  useAutoscroll({
    enable: isDragging.size > 0 || dragSelectingPointerId !== undefined,
    measuredHeaderHeight: measuredHeaderHeight,
    getDraggingPointerId: () =>
      refState.current.dragPointerId ?? dragSelectingPointerId,
  });

  return (
    <>
      <Global
        styles={
          // This is all the styling for DND, except for DNDPinIndicatorZone and DNDGreyBox which are separate from core D&D styling
          css`
            @media not all and (hover: hover) and (pointer: fine) {
              #not-scrollable-container:has(.${dndGridClass}.is-dragging) {
                // Mostly because chrome mobile makes the document wider otherwise, even though <body> has overflow-x: hidden
                overflow-x: hidden;
              }
            }
            html:has(.${dndGridClass}.is-dragging) {
              cursor: grabbing;
              &,
              & * {
                user-select: none;
              }
            }

            .${dndGridClass} {
              width: ${isMobile ? 100 : zoomLevel}%;
              position: relative;
              display: grid;
              justify-content: center;
              max-width: 100%;
              gap: ${gridGapPx}px;
              margin: -${gridGapPx}px 0;
              --active-zone-size: 50%;
              flex-grow: 1;
              overflow-anchor: none;

              &:not(.is-dragging) {
                .draggable-wrapper {
                  cursor: grab;
                  .dnd-zone {
                    pointer-events: none;
                  }
                }
              }

              // This is also being set on the whole dndGrid for the placeholders
              .${virtualRowClass}, & {
                grid-template-columns: repeat(
                  ${numberOfColumns},
                  minmax(0, 1fr)
                );
              }

              .${virtualRowClass}:has(.pin-indicator-zone) {
                grid-template-columns: repeat(
                  ${numberOfColumns + 1},
                  minmax(0, 1fr)
                );
              }

              .${virtualRowClass} {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                display: grid;
                gap: ${gridGapPx}px;
                /* the negative margin on dnd-grid is to counteract this */
                padding-bottom: calc(${gridGapPx}px / 2);
                padding-top: calc(${gridGapPx}px / 2);
                justify-content: center;
                z-index: var(--z-index);

                &:has(.pin-indicator-zone) {
                  width: 125.335%;
                }

                .dnd-empty {
                  // Not sure if needed
                  pointer-events: none;
                }
              }
              .draggable-wrapper {
                // Mainly for the dimming when being pinned
                transition: all 0.1s;
              }
            }

            .${dndGridClass}, .dnd-card-stack {
              .draggable-wrapper {
                display: flex;
                position: relative;
                align-items: stretch;
                flex-grow: 1;
                --left-zone-normal-left-position: calc(
                  0px - calc(${gridGapPx}px / 2)
                );
                --right-zone-normal-right-position: calc(
                  0px - calc(${gridGapPx}px / 2)
                );

                &.unpinned-product {
                  opacity: 36%;
                }

                &,
                & * {
                  -webkit-user-select: none;
                  user-select: none;
                  // Enable long press on ios for us to DND with
                  -webkit-touch-callout: none;
                }

                &:not(.content) {
                  height: ${productCardHeight}px;
                }

                & > * {
                  flex-grow: 1;
                }

                &.selected .content-block-shell {
                  --selected-border: 1px solid ${theme.colors.primary.base};
                  --selected-shadow: 0 0 0 1px ${theme.colors.primary.base};
                }

                .dnd-zone {
                  &:not(.disabled) {
                    cursor: copy;
                  }
                  position: absolute;
                  top: calc(${gridGapPx}px / -2);
                  bottom: calc(${gridGapPx}px / -2);

                  --width-of-one-slot: calc(
                    calc(
                        100% - calc(
                            max(0, calc(var(--slots-width) - 1)) *
                              ${gridGapPx}px
                          )
                      ) / var(--slots-width)
                  );
                  --extension-calculated: calc(
                    calc(var(--extend-by-slots, 0) * var(--width-of-one-slot)) +
                      calc(var(--extend-by-slots, 0) * ${gridGapPx}px)
                  );

                  ${enableZoneDebug &&
                  `
                    &.left > div,
                    &.right > div {
                      border: 1px solid purple;
                      background: rgb(from purple r g b / 0.3);
                    }
                  `}

                  &.left {
                    left: var(--left-zone-normal-left-position);
                    ${enableZoneDebug
                      ? `
                        background: rgb(from orange r g b / 0.3);
                        border: 1px dashed orange;
                      `
                      : ""}
                    right: calc(var(--right-zone-normal-right-position) - var(--extension-calculated));
                  }
                  &.right {
                    // Right zones only exist anymore for extension into where no items are to the right in the row
                    right: calc(
                      var(--right-zone-normal-right-position) - var(
                          --extension-calculated
                        )
                    );
                    ${enableZoneDebug
                      ? `
                        background: rgb(from green r g b / 0.3);
                        border: 1px dashed green;
                      `
                      : ""}
                    left: calc(100% - var(--right-zone-normal-right-position));
                  }
                }
              }
            }

            .${dndGridClass}.is-dragging {
              .draggable-wrapper {
                .content-block-shell,
                .card-container {
                  // Disable pointer events on zone siblings since they might stick out and occlude the neighbour zone
                  pointer-events: none;
                  // Card movement animations
                  transform: translate(
                    var(--animation-styling-translate-x, 0),
                    var(--animation-styling-translate-y, 0)
                  );
                  transition: transform 0.1s linear;
                }
              }

              // Disable pointer events where zones don't exist
              .draggable-wrapper:not(:has(.dnd-zone:not(.disabled))) {
                pointer-events: none;
              }

              // Can't use :hover because it doesn't work on touch devices, so we have a :hovered class on the zones instead
              .draggable-wrapper:has(.dnd-zone.hovered) {
                // So potential drag-over shadows/effects go over other prodcuts, maybe not needed anymore
                z-index: calc(var(--z-index) + 1);

                &:has(.dnd-zone.right:not(.disabled).hovered) {
                  ${enableZoneHoverIndicator
                    ? `box-shadow: ${gridGapPx}px 0px 0 0px black;`
                    : ""}
                }
                &:has(.dnd-zone.left:not(.disabled).hovered) {
                  ${enableZoneHoverIndicator
                    ? `box-shadow: calc(${gridGapPx}px * -1) 0px 0 0px black;`
                    : ""}
                }
              }
            }

            body:not(:has(.dnd-zone:not(.disabled).hovered)) .dnd-card-stack,
            .dnd-card-stack.dnd-disabled {
              & > * {
                transform: scale(0.5)
                  translate(
                    calc(var(--scaled-down-shift-x) - 50%),
                    calc(var(--scaled-down-shift-y) - 50%)
                  );
              }
            }

            .dnd-card-stack {
              // Tell the browser this element is completely separate from the page for some extra performance/glue to the cursor
              contain: content;
              pointer-events: none;
              position: fixed;
              top: 0;
              left: 0;
              z-index: calc(var(--dnd-base-z-index, 0) + 1000);
              opacity: 0.9;
              // Also used in react where we generate the transform style to offset the padding
              --padding: 200px;
              // Add padding so stacked cards can "overflow", as we're setting the size of the stack to the size of the item the user started dragging on
              padding: var(--padding);
              // For performance reasons, only put drop-shadow on the whole stack in chrome (chrome uses 100% GPU on my M1 max and gets laggy with multiple while firefox and safari handle it fine)
              ${(window as any).chrome ? dropShadow : ``}

              & > * {
                transition-duration: 200ms;
              }

              .dnd-zone {
                display: none;
              }

              .relative-wrapper {
                position: relative;

                .draggable-wrapper.clone {
                  height: ${productCardHeight}px;
                  ${refState.current.widthOfOneSlotAtDragstart
                    ? `width: ${refState.current.widthOfOneSlotAtDragstart}px;`
                    : ""}
                  ${(window as any).chrome ? `` : dropShadow}

                  &.content {
                    &.wide {
                      --white-space: calc(
                        1 - calc(var(--slots-height) / var(--slots-width))
                      );
                      --padding-above-below: calc(
                        calc(${productCardHeight}px * var(--white-space)) / 2
                      );
                      padding-top: var(--padding-above-below);
                      padding-bottom: var(--padding-above-below);
                    }

                    &.tall {
                      --white-space: calc(
                        1 - calc(var(--slots-width) / var(--slots-height))
                      );
                      --padding-each-side: calc(
                        calc(
                            ${
                                refState.current.widthOfOneSlotAtDragstart ||
                                productCardHeight /* second part of statement should never be seen*/
                              }px * var(--white-space)
                          ) / 2
                      );
                      padding-right: var(--padding-each-side);
                      padding-left: var(--padding-each-side);
                    }
                  }

                  &:not(:last-child) {
                    opacity: 0.8 !important;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) rotate(8deg);
                    & ~ .draggable-wrapper.clone:not(:last-child) {
                      transform: translate(-50%, -50%) rotate(-8deg);
                    }
                  }
                  &:last-child {
                    // Let's assume no-one drags more than 100k items
                    z-index: calc(var(--dnd-base-z-index, 0) + 100000);
                    position: relative;

                    & > * {
                      // So indicator doesn't get more transparent
                      opacity: 0.9 !important;
                    }

                    &::after {
                      // Indicator of how many items are selected
                      display: inline-flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: center;
                      gap: 2px;
                      content: var(--selection-count);
                      position: absolute;
                      transform: translate(calc(-50% + 4px), calc(-50% + 4px));
                      border-radius: 999px;
                      background: ${theme.colors.primary.base};
                      z-index: calc(var(--dnd-base-z-index, 0) + 100001);
                      color: white;
                      line-height: 1;
                      --font-size: 0.75em;
                      font-size: var(--font-size);
                      font-weight: 500;
                      padding-right: 6px;
                      padding-left: 6px;
                      height: calc(12px + var(--font-size));
                      min-width: calc(12px + var(--font-size));
                    }
                  }
                }
              }
            }

            .dnd-drag-to-select-overlay {
              position: absolute;
              margin: initial;
              border: none;
              padding: 0;
              max-height: unset;
              contain: strict;
              z-index: calc(var(--dnd-base-z-index, 0) + 1);
              pointer-events: none;
              // Inset to not make rectangle any bigger than it is
              box-shadow: inset 0 0 0 2px ${theme.colors.primary.base};
              background: ${theme.colors.primary.alpha24};

              ::backdrop {
                display: none;
                // Work around https://bugzilla.mozilla.org/show_bug.cgi?id=1921777
                visibility: hidden;
              }
            }

            &.row-mode {
              .card-container,
              .content-block-shell {
                transition: transform 200ms ease-in-out;
              }

              .virtual-row:has(.dnd-zone.hovered:not(.disabled)) {
                .card-container,
                .content-block-shell {
                  transform: translateY(${droppableGapSize}px);
                  box-shadow: ${theme.shadows.large};
                }
              }
            }

            .dnd-zone.last-row {
              ${enableZoneDebug
                ? `
                  background: rgb(from blue r g b / 0.3);
                  border: 1px dashed blue;
                `
                : ""}
              width: 100%;
              height: 100px;
              position: absolute;
              bottom: -100px;
            }

            .dnd-zone.disabled {
              ${enableZoneDebug
                ? `
                  background: rgb(from red r g b / 0.3);
                  border: 1px dashed red;
                  color: red;
                `
                : ""}
            }
          `
        }
      />
      <ProductCardDimensionMeasurer
        products={productsToRender}
        dndGridRef={dndGridRef}
        getProductCardProps={getProductCardProps}
      />
      <div
        ref={dndGridRef}
        className={
          `${dndGridClass} ${dragToSelectAndDeselectClass}` +
          (className ? ` ${className}` : "") +
          (isDragging.size ? " is-dragging" : "") +
          (rowMode ? " row-mode" : "")
        }
        style={
          showPlaceholders
            ? undefined
            : {
                height: `${rowVirtualizer.getTotalSize()}px`,
              }
        }
      >
        <DNDGreyBox />
        {showPlaceholders &&
          Array.from({ length: 4 * 8 }).map(
            (
              _,
              i // Usual aspect ratio of a product card without clicks data
            ) => <PlaceholderProductCard key={i} aspectRatio={0.651205968} />
          )}
        {!showPlaceholders &&
          rowVirtualizer
            .getVirtualItems()
            .map((virtualRow) => (
              <VirtualRow
                rowStart={virtualRow.start}
                rowIndex={virtualRow.index}
                numberOfColumns={numberOfColumns}
                numberOfRows={numberOfRows}
                getProductCardProps={getProductCardProps}
                productCardHeight={productCardHeight}
                blockIdToStartRow={blockIdToStartRow}
                gapPx={gridGapPx}
                key={virtualRow.index}
              />
            ))}
        <LastRowZone />
      </div>

      {createPortal(
        <DNDCardStack getProductCardProps={getProductCardProps} />,
        document.getElementById("root-portal")!
      )}
    </>
  );
}

function LastRowZone() {
  const { refState } = useDragAndDropContext();
  const rowMode = useRowMode();

  const { disabled, ref } = useZone({
    disabled: !rowMode,
    position: "left",
    refState,
    zoneInfo: {
      id: "last-row",
      type: "last-row",
    },
  });

  return (
    <div
      className={`dnd-zone last-row${disabled ? " disabled" : ""}`}
      ref={ref}
    />
  );
}
