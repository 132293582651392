import { Slot } from "@radix-ui/react-slot";
import { Interpolation, Theme, css, useTheme } from "@emotion/react";
import React from "react";

export interface ButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "variant"> {
  asChild?: boolean;
  variant: "primary" | "neutral" | "danger";
  _style: "filled" | "stroke" | "lighter" | "ghost"; // _style to not collide with style prop
  size?: "medium" | "small" | "x-small" | "xx-small";
  iconOnly?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  customCss?: Interpolation<Theme>;
}

// We always have 1px border so we subtract 1px from top and bottom padding
const paddingFromSizeMap: Record<Required<ButtonProps>["size"], string> = {
  medium: "9px 10px",
  small: "7px 8px",
  "x-small": "5px 6px",
  "xx-small": "3px 6px",
};

const roundingFromSizeMap = {
  medium: "10px",
  small: "8px",
  "x-small": "8px",
  "xx-small": "8px",
};

const baseStyling = (size: Required<ButtonProps>["size"]) => css`
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${paddingFromSizeMap[size]};
  gap: 4px;
  border-radius: ${roundingFromSizeMap[size]};
  white-space: nowrap;
`;

const iconSizeToWH = {
  medium: "40px",
  small: "36px",
  "x-small": "32px",
  "xx-small": "28px",
};

const iconOnlyStyle = (size: Required<ButtonProps>["size"]) => css`
  width: ${iconSizeToWH[size]};
  height: ${iconSizeToWH[size]};
  min-height: ${iconSizeToWH[size]};
  min-width: ${iconSizeToWH[size]};
  padding: 0;
`;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      asChild = false,
      iconOnly = false,
      disabled = false,
      size = "medium",
      customCss,
      variant,
      ...props
    },
    ref
  ) => {
    const theme = useTheme();
    const Comp = asChild ? Slot : "button";

    const specificStyle = disabled
      ? css`
          cursor: not-allowed;
          ${props.fullWidth ? "width: 100%;" : ""}
          border: 1px solid ${theme.colors.bg["weak-50"]};
          background-color: ${theme.colors.bg["weak-50"]};
          color: ${theme.colors.text["disabled-300"]};
          text-decoration: none;

          &[data-style="ghost"] {
            background-color: transparent;
            border-color: transparent;
            color: ${theme.colors.text["disabled-300"]};
          }
        `
      : css`
          ${props.fullWidth ? "width: 100%;" : ""}
          cursor: pointer;
          text-decoration: none;

          &[data-variant="primary"] {
            &[data-style="filled"] {
              background-color: ${theme.colors.primary.base};
              color: ${theme.colors.neutral.static.white};

              &:hover {
                background-color: ${theme.colors.primary.dark};
              }
            }

            &[data-style="stroke"] {
              background-color: transparent;
              border: 1px solid ${theme.colors.primary.base};
              color: ${theme.colors.primary.base};

              &:hover {
                background-color: ${theme.colors.primary.alpha10};
                border: 1px solid transparent;
              }
            }

            &[data-style="lighter"] {
              background-color: ${theme.colors.primary.alpha10};
              color: ${theme.colors.primary.base};

              &:hover {
                background-color: ${theme.colors.bg["white-0"]};
                border: 1px solid ${theme.colors.primary.base};
                color: ${theme.colors.primary.base};
              }
            }

            &[data-style="ghost"] {
              background-color: transparent;
              color: ${theme.colors.primary.base};

              &:hover {
                background-color: ${theme.colors.primary.alpha10};
              }
            }
          }

          &[data-variant="danger"] {
            &[data-style="filled"] {
              background-color: ${theme.colors.state.error.base};
              color: ${theme.colors.neutral.static.white};

              &:hover {
                background-color: ${theme.colors.state.error.dark};
              }
            }

            &[data-style="stroke"] {
              background-color: transparent;
              border: 1px solid ${theme.colors.state.error.base};
              color: ${theme.colors.primary.base};

              &:hover {
                background-color: ${theme.colors.state.error.lighter};
                border: 1px solid transparent;
              }
            }

            &[data-style="lighter"] {
              background-color: ${theme.colors.state.error.lighter};
              color: ${theme.colors.primary.base};

              &:hover {
                background-color: ${theme.colors.bg["white-0"]};
                border: 1px solid ${theme.colors.state.error.base};
                color: ${theme.colors.primary.base};
              }
            }

            &[data-style="ghost"] {
              background-color: transparent;
              color: ${theme.colors.state.error.base};

              &:hover {
                background-color: ${theme.colors.state.error.lighter};
              }
            }
          }

          &[data-variant="neutral"] {
            &[data-style="filled"] {
              background-color: ${theme.colors.bg["strong-950"]};
              color: ${theme.colors.text["white-0"]};

              &:hover {
                background-color: ${theme.colors.bg["strong-800"]};
              }
            }

            &[data-style="stroke"] {
              background-color: ${theme.colors.bg["white-0"]};
              border: 1px solid ${theme.colors.stroke["soft-200"]};
              color: ${theme.colors.text["sub-600"]};

              &:hover {
                background-color: ${theme.colors.bg["weak-50"]};
                border: 1px solid transparent;
                color: ${theme.colors.bg["strong-950"]};
              }
            }

            &[data-style="lighter"] {
              background-color: ${theme.colors.bg["weak-50"]};
              color: ${theme.colors.text["sub-600"]};

              &:hover {
                background-color: ${theme.colors.bg["white-0"]};
                border: 1px solid ${theme.colors.stroke["soft-200"]};
                color: ${theme.colors.bg["strong-950"]};
              }
            }

            &[data-style="ghost"] {
              background-color: transparent;
              color: ${theme.colors.text["sub-600"]};

              &:hover {
                background-color: ${theme.colors.bg["weak-50"]};
                color: ${theme.colors.bg["strong-950"]};
              }
            }
          }
        `;

    return (
      <Comp
        disabled={disabled}
        ref={ref}
        data-variant={variant}
        data-style={props._style}
        className={className}
        css={[
          baseStyling(size),
          theme.typography["label-s"],
          specificStyle,
          iconOnly ? iconOnlyStyle(size) : undefined,
          customCss,
        ]}
        {...props}
      />
    );
  }
);
