import React from "react";
import * as RadixSelect from "@radix-ui/react-select";
import {
  CaretDown,
  CaretUp,
  CaretUpDown,
  Check,
  IconProps,
} from "@phosphor-icons/react";
import { css, SerializedStyles, useTheme } from "@emotion/react";

export const SelectItem = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<RadixSelect.SelectItemProps & { sublabel?: string }>
>(({ children, sublabel, ...props }, forwardedRef) => {
  const theme = useTheme();
  return (
    <RadixSelect.Item
      {...props}
      ref={forwardedRef}
      css={[
        theme.typography["paragraph-s"],
        css`
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px;
          position: relative;
          user-select: none;
          width: 100%;
          gap: 8px;
          white-space: nowrap;
          box-shadow: none;
          cursor: pointer;

          &:hover {
            background-color: ${theme.colors.bg["weak-50"]};
          }

          &[data-disabled] {
            cursor: not-allowed;
            pointer-events: none;
            color: ${theme.colors.text["disabled-300"]};

            .sublabel {
              color: ${theme.colors.text["disabled-300"]};
            }
          }
        `,
      ]}
    >
      <RadixSelect.ItemText
        css={[
          theme.typography["label-s"],
          css`
            white-space: nowrap;
            display: flex;
            flex-direction: column;
            row-gap: 10px;
          `,
        ]}
      >
        {children}
        {sublabel && (
          <div
            className={"sublabel"}
            css={[
              theme.typography["label-xs"],
              css`
                color: ${theme.colors.text["sub-600"]};
              `,
            ]}
          >
            {sublabel}
          </div>
        )}
      </RadixSelect.ItemText>
      <RadixSelect.ItemIndicator>
        <Check size={20} color={theme.colors.icon["sub-600"]} />
      </RadixSelect.ItemIndicator>
    </RadixSelect.Item>
  );
});

type SelectGroupProps = {
  label?: string;
};

export const SelectGroup = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<SelectGroupProps>
>(({ children, label, ...props }, forwardedRef) => {
  const theme = useTheme();
  return (
    <RadixSelect.Group
      css={css`
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 4px;
      `}
      {...props}
      ref={forwardedRef}
    >
      {label && (
        <RadixSelect.Label
          css={[
            theme.typography["subheading-xs"],
            css`
              color: ${theme.colors.text["soft-400"]};
              padding: 4px 8px;
            `,
          ]}
        >
          {label}
        </RadixSelect.Label>
      )}
      {children}
    </RadixSelect.Group>
  );
});

type SelectContentProps = {
  label?: string;
  placeholder?: string;
  value?: string;
  onValueChange?: (value: string) => void;
  side?: "top" | "bottom";
  align?: "start" | "center" | "end";
  buttonOnly?: boolean;
  css?: SerializedStyles;
  zIndex?: number;
  contentWidthMatchesTrigger?: boolean;
};

export const SelectContent = (
  props: React.PropsWithChildren<SelectContentProps>
) => {
  const theme = useTheme();

  return (
    <RadixSelect.Portal>
      <RadixSelect.Content
        style={{
          zIndex: `calc(var(--dnd-base-z-index, 0) + ${props.zIndex || 0})`,
        }}
        css={[
          css`
            width: ${props.contentWidthMatchesTrigger
              ? "var(--radix-select-trigger-width)"
              : "calc(max(var(--radix-popper-anchor-width), fit-content))"};
            min-width: fit-content;
            overflow: hidden;
            max-height: 250px;
            background-color: ${theme.colors.bg["white-0"]};
            border-radius: 16px;
            border: 1px solid ${theme.colors.stroke["soft-200"]};
            box-shadow: 0px 16px 32px -12px ${theme.colors.neutral.static.black}1A;
            z-index: calc(var(--dnd-base-z-index, 0) + 1000);
            position: relative;
          `,
        ]}
        position={"popper"}
        align={props.align}
        sideOffset={4}
        side={props.side}
      >
        <RadixSelect.ScrollUpButton
          css={css`
            display: flex;
            align-content: center;
            justify-content: center;
            padding-top: 4px;
            box-shadow:
              0px 1px 2px 0px ${theme.colors.neutral.static.black}08,
              0px 4px 8px 0px rgba(0, 0, 0, 0.1);
            position: absolute;
            top: 0;
            width: 100%;
            z-index: calc(var(--dnd-base-z-index, 0) + 100);
            background-color: ${theme.colors.bg["white-0"]};
          `}
        >
          <CaretUp />
        </RadixSelect.ScrollUpButton>

        <RadixSelect.Viewport
          css={css`
            padding: 8px;
            display: flex;
            flex-direction: column;
            gap: 4px;
          `}
        >
          {props.children}
        </RadixSelect.Viewport>
        <RadixSelect.ScrollDownButton
          css={css`
            display: flex;
            align-content: center;
            justify-content: center;
            padding-bottom: 4px;
            box-shadow:
              0px -1px 2px 0px ${theme.colors.neutral.static.black}08,
              0px -4px 8px 0px rgba(0, 0, 0, 0.1);
            position: absolute;
            bottom: 0;
            width: 100%;
            z-index: calc(var(--dnd-base-z-index, 0) + 100);
            background-color: ${theme.colors.bg["white-0"]};
          `}
        >
          <CaretDown />
        </RadixSelect.ScrollDownButton>
      </RadixSelect.Content>
    </RadixSelect.Portal>
  );
};

type SelectTriggerProps = {
  label?: string;
  placeholder?: string;
  customCss?: SerializedStyles;
  noIcon?: boolean;
};

export const SelectTrigger = (
  props: React.PropsWithChildren<SelectTriggerProps>
) => {
  const theme = useTheme();

  return (
    <RadixSelect.Trigger
      css={[
        theme.typography["paragraph-s"],
        css`
          display: inline-flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 10px;
          padding: 0 15px;
          font-size: 13px;
          line-height: 1;
          height: 35px;
          gap: 4px;
          width: 100%;
          background-color: ${theme.colors.bg["white-0"]};
          border: 1px solid ${theme.colors.stroke["soft-200"]};
          cursor: pointer;

          &:not(:focus-visible) {
            box-shadow: 0px 1px 2px 0px ${theme.colors.neutral.static.black}08;
          }

          &[data-state="open"] {
            outline: none;
            border-color: ${theme.colors.stroke["strong-950"]};
          }

          // this seems to be required to not trigger os level focus style

          &:focus {
            outline: none;
          }

          &:hover {
            border-color: ${theme.colors.bg["weak-50"]};
            background-color: ${theme.colors.bg["weak-50"]};
          }

          &[data-disabled] {
            cursor: not-allowed;
            pointer-events: none;
            color: ${theme.colors.text["disabled-300"]};
            background-color: ${theme.colors.bg["weak-50"]};
            box-shadow: none;
            border-color: ${theme.colors.bg["weak-50"]};
          }
        `,
        props.customCss,
      ]}
      aria-label={props.label}
    >
      <RadixSelect.Value placeholder={props.placeholder}>
        {props.children}
      </RadixSelect.Value>
      {!props.noIcon && (
        <RadixSelect.Icon>
          <CaretDown />
        </RadixSelect.Icon>
      )}
    </RadixSelect.Trigger>
  );
};

type SelectTriggerTextWithIconProps = {
  icon: React.FC<IconProps>;
  label?: string;
};
export const SelectValueLabelWithIcon = (
  props: SelectTriggerTextWithIconProps
) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 8px;
      `}
    >
      <props.icon size={20} />
      {props.label}
    </div>
  );
};

type CompactSelectTriggerProps = {
  css?: SerializedStyles;
  icon?: React.FC<IconProps>;
  label?: string;
};

export const CompactSelectTrigger = (
  props: React.PropsWithChildren<CompactSelectTriggerProps>
) => {
  const theme = useTheme();

  return (
    <RadixSelect.Trigger
      css={[
        theme.typography["paragraph-s"],
        css`
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 1px 2px 0px ${theme.colors.neutral.static.black}08;
          border-radius: 10px;
          padding: 10px;
          font-size: 13px;
          box-sizing: border-box;
          height: 40px;
          gap: 4px;
          background-color: ${theme.colors.bg["white-0"]};

          border: 1px solid ${theme.colors.stroke["soft-200"]};

          &[data-state="open"] {
            outline: none;
            border-color: ${theme.colors.stroke["strong-950"]};
          }

          // this seems to be required to not trigger os level focus style

          &:focus {
            outline: none;
          }

          &:hover {
            border-color: ${theme.colors.bg["weak-50"]};
            background-color: ${theme.colors.bg["weak-50"]};
          }

          &[data-disabled] {
            cursor: not-allowed;
            pointer-events: none;
            color: ${theme.colors.text["disabled-300"]};
            background-color: ${theme.colors.bg["weak-50"]};
            box-shadow: none;
            border-color: ${theme.colors.bg["weak-50"]};
          }
        `,
        props.css,
      ]}
      aria-label={props.label}
    >
      {props.icon && (
        <props.icon color={theme.colors.icon["strong-950"]} size={20} />
      )}
      <RadixSelect.Icon
        css={css`
          height: 20px;
          width: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <CaretUpDown size={20} />
      </RadixSelect.Icon>
    </RadixSelect.Trigger>
  );
};

export const Select = RadixSelect.Root;
