import { css, useTheme } from "@emotion/react";
import { useMemo } from "react";
import { CursorClick, Eye } from "@phosphor-icons/react";
import { Placeholder } from "../../../../alignUI/Placeholder/Placeholder";

export default function ClickthroughRateGauge({
  clickthroughRate,
  views,
  clicks,
  isTotal,
  isLoading,
}: {
  isLoading?: boolean;
  clickthroughRate: number;
  views: number;
  clicks: number;
  isTotal?: boolean;
}) {
  const degrees = useMemo(() => clickthroughRate * 1.8, [clickthroughRate]);
  const theme = useTheme();
  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      <div
        css={css`
          position: relative;
          display: flex;
          justify-content: center;
          padding: 16px 0;
          border-bottom: 1px solid ${theme.colors.stroke["soft-200"]};
        `}
      >
        <div
          css={css`
            padding: 1px;
            max-height: 124px;
            overflow: hidden;
          `}
        >
          <div
            css={css`
              height: 248px;
              width: 248px;
              rotate: ${degrees}deg;
              transition: rotate 0.5s;
            `}
          >
            <svg
              width="248"
              height="248"
              viewBox="0 0 248 248"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M248 124C248 91.1132 234.936 59.5733 211.681 36.3188C188.427 13.0643 156.887 2.48289e-06 124 0C91.1132 -2.48289e-06 59.5733 13.0642 36.3188 36.3187C13.0643 59.5733 4.96578e-06 91.1131 0 124L24.8 124C24.8 97.6905 35.2514 72.4586 53.855 53.855C72.4586 35.2514 97.6905 24.8 124 24.8C150.309 24.8 175.541 35.2514 194.145 53.855C212.749 72.4586 223.2 97.6905 223.2 124H248Z"
                fill="#E1E4EA"
              />
              <path
                d="M248 124C248 156.684 235.096 188.047 212.093 211.267C189.091 234.487 157.852 247.686 125.169 247.994C92.4857 248.303 61.0028 235.694 37.567 212.912C14.1313 190.13 0.638077 159.016 0.0220282 126.337L24.8176 125.87C25.3105 152.013 36.105 176.904 54.8536 195.13C73.6023 213.355 98.7886 223.442 124.935 223.196C151.081 222.949 176.073 212.39 194.475 193.814C212.877 175.238 223.2 150.148 223.2 124L248 124Z"
                fill="#335CFF"
              />
            </svg>
          </div>
        </div>
        <div
          css={css`
            position: absolute;
            text-align: center;
            width: 100%;
            top: 50%;
            visibility: ${isLoading ? "hidden" : "visible"};
          `}
        >
          <p
            css={[
              theme.typography["subheading-xs"],
              css`
                color: ${theme.colors.text["sub-600"]};
                margin-bottom: 4px;
              `,
            ]}
          >
            {isTotal ? "Total CTR" : "CTR"}
          </p>
          <p
            css={[
              theme.typography.h5,
              css`
                margin: 0;
              `,
            ]}
          >
            {clickthroughRate.toFixed(2)}%
          </p>
        </div>
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          padding-top: 16px;
          padding-bottom: 32px;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            flex: 1;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              width: 32px;
              height: 32px;
              border-radius: 50%;
              background: ${theme.colors.bg["weak-50"]};
              margin-bottom: 12px;
            `}
          >
            <Eye size={20} color={theme.colors.icon["sub-600"]} />
          </div>
          <p
            css={[
              theme.typography["paragraph-xs"],
              css`
                margin-bottom: 4px;
                color: ${theme.colors.text["sub-600"]};
              `,
            ]}
          >
            Collection views
          </p>
          <p
            css={[
              theme.typography["paragraph-xs"],
              css`
                margin-bottom: 0;
              `,
            ]}
          >
            {isLoading ? (
              <Placeholder width={"40px"} />
            ) : (
              views.toLocaleString()
            )}
          </p>
        </div>
        <div
          css={css`
            border-left: 1px solid ${theme.colors.stroke["soft-200"]};
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            flex: 1;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              width: 32px;
              height: 32px;
              border-radius: 50%;
              background: ${theme.colors.bg["weak-50"]};
              margin-bottom: 12px;
            `}
          >
            <CursorClick size={20} color={theme.colors.icon["sub-600"]} />
          </div>
          <p
            css={[
              theme.typography["paragraph-xs"],
              css`
                margin-bottom: 4px;
                color: ${theme.colors.text["sub-600"]};
              `,
            ]}
          >
            Product clicks
          </p>
          <p
            css={[
              theme.typography["paragraph-xs"],
              css`
                margin-bottom: 0;
              `,
            ]}
          >
            {isLoading ? (
              <Placeholder width={"40px"} />
            ) : (
              clicks.toLocaleString()
            )}
          </p>
        </div>
      </div>
    </div>
  );
}
