import { css, useTheme } from "@emotion/react";
import { StepCard, StepContainer } from "./StepWidget";
import {
  ArrowRight,
  CursorClick,
  Star,
  TrendDown,
} from "@phosphor-icons/react";
import { Button } from "../../../../alignUI/Button/Button";
import React, { Suspense, useMemo, useState } from "react";
import { components } from "../../../../api/generated/openapi";
import ClickthroughRateModal from "../../Dashboard/Modal/ClickthroughRateModal";

const ClickWidgetModal = React.lazy(
  () => import("../../Dashboard/Modal/ClickWidgetModal")
);
const ProductOrdersWidgetModal = React.lazy(
  () => import("../../Dashboard/Modal/ProductOrdersWidgetModal")
);
const SellThroughRateWidgetModal = React.lazy(
  () => import("../../Dashboard/Modal/SellThroughRateWidgetModal")
);

type StatisticsModal =
  | "product_clicks"
  | "sellthrough_rate"
  | "product_orders"
  | "conversion_rate"
  | null;

export function StatisticButtons({
  fromDate,
  toDate,
  collectionsData,
  collectionsDataLoading,
}: {
  fromDate: Date;
  toDate: Date;
  collectionsData:
    | components["schemas"]["LiteDashboardCollectionDto"][]
    | undefined;
  collectionsDataLoading: boolean;
}) {
  const theme = useTheme();
  const [openModalType, setOpenModalType] = useState<StatisticsModal>(null);

  const [selectedCollectionId, setSelectedCollectionId] = useState<string>();

  const statisticButtons = [
    {
      iconColor: theme.colors.state.feature.base,
      icon: Star,
      text: "Best Selling Products",
      onClick: () => setOpenModalType("product_orders"),
    },
    {
      iconColor: theme.colors.state.information.base,
      icon: CursorClick,
      text: "Popular Products",
      onClick: () => setOpenModalType("product_clicks"),
    },
    {
      iconColor: theme.colors.state.error.base,
      icon: TrendDown,
      text: "Slow Moving Products",
      onClick: () => setOpenModalType("sellthrough_rate"),
    },
  ];

  return (
    <>
      <StepContainer
        desktopCards={4}
        extraCss={css`
          --gap: 24px;
        `}
        containerCss={css`
          padding: 0 16px;
        `}
      >
        <TotalCTRButton
          collectionsData={collectionsData}
          collectionsDataLoading={collectionsDataLoading}
          setOpenModalType={setOpenModalType}
        />

        {statisticButtons.map(({ icon: Icon, text, iconColor, onClick }) => (
          <StepCard
            key={text}
            onClick={onClick}
            extraCss={[
              theme.typography["label-s"],
              css`
                border-radius: 16px;
                box-shadow: ${theme.shadows.regular.xs};
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 16px;
                align-self: stretch;

                @media (hover: hover) {
                  &:hover div.button {
                    background: ${theme.colors.bg["weak-50"]};
                  }
                }
              `,
            ]}
          >
            <span
              css={css`
                display: flex;
                align-items: center;
                flex: 1 0 0;
                span {
                  padding: 6px;
                  display: inline-flex;
                  color: ${iconColor};
                }
              `}
            >
              <span>
                <Icon size={20} weight="fill" />
              </span>
              {text}
            </span>
            <Button
              asChild
              variant="neutral"
              _style="stroke"
              customCss={css`
                pointer-events: none;
                padding: 6px;
              `}
            >
              <div className="button">
                <ArrowRight size={20} />
              </div>
            </Button>
          </StepCard>
        ))}
      </StepContainer>
      <Suspense>
        <ClickWidgetModal
          open={openModalType === "product_clicks"}
          onClose={() => setOpenModalType(null)}
          fromDate={fromDate}
          toDate={toDate}
        />
        <ProductOrdersWidgetModal
          open={openModalType === "product_orders"}
          onClose={() => setOpenModalType(null)}
          fromDate={fromDate}
          toDate={toDate}
        />
        <SellThroughRateWidgetModal
          open={openModalType === "sellthrough_rate"}
          onClose={() => setOpenModalType(null)}
          fromDate={fromDate}
          toDate={toDate}
        />
      </Suspense>
      <ClickthroughRateModal
        collectionsData={collectionsData}
        collectionsDataLoading={collectionsDataLoading}
        onSetSelectedCollectionId={setSelectedCollectionId}
        selectedCollectionId={selectedCollectionId}
        open={openModalType === "conversion_rate"}
        onClose={() => setOpenModalType(null)}
      />
    </>
  );
}

export function TotalCTRButton({
  collectionsData,
  collectionsDataLoading,
  setOpenModalType,
}: {
  collectionsData:
    | components["schemas"]["LiteDashboardCollectionDto"][]
    | undefined;
  collectionsDataLoading: boolean;
  setOpenModalType: React.Dispatch<React.SetStateAction<StatisticsModal>>;
}) {
  const clickthroughRate = useMemo(() => {
    const views = collectionsData?.reduce(
      (acc, c) => acc + c.selected_period_data.views,
      0
    );
    const clicks = collectionsData?.reduce(
      (acc, c) => acc + c.selected_period_data.clicks,
      0
    );
    if (clicks == undefined || views == undefined) return undefined;
    return Math.round(views ? (clicks / views) * 100 : 0);
  }, [collectionsData]);

  return (
    <button
      css={(theme) => css`
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        padding: 8px 16px;
        border: 1px solid ${theme.colors.stroke["soft-200"]};
        border-radius: 16px;
        background: ${theme.colors.bg["white-0"]};
        cursor: pointer;

        @media (hover: hover) {
          &:hover {
            background: ${theme.colors.bg["weak-50"]};
          }
        }
      `}
      onClick={() => setOpenModalType("conversion_rate")}
    >
      <CircularProgress
        progress={(clickthroughRate || 0) / 100}
        isLoading={collectionsDataLoading}
      />
      <div
        css={[
          (theme) => theme.typography["label-xs"],
          (theme) => css`
            color: ${theme.colors.text["soft-400"]};
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            flex: 1 0 0;
            white-space: nowrap;
          `,
        ]}
      >
        Total Collection CTR
        <div
          css={[
            (theme) => theme.typography["label-l"],
            css`
              display: flex;
            `,
          ]}
        >
          {collectionsDataLoading ? (
            <span
              css={[
                (theme) => css`
                  background: ${theme.colors.bg["sub-200"]};
                  color: transparent;
                  border-radius: 4px;
                `,
                shimmeringAnimation,
              ]}
            >
              0%
            </span>
          ) : (
            <>{clickthroughRate}%</>
          )}
        </div>
      </div>
    </button>
  );
}

// Pulsating the CTR circle catches too much attention IMO for a loading state, doing a subtle shimmering effect looks better
const shimmeringAnimation = css`
  animation: 1.5s infinite ease-in moving-shimmer;
  will-change:
    mask,
    -webkit-mask;
  mask-position-x: 125%;
  mask-position-y: center;
  mask-size: 300% 100%;
  mask-image: linear-gradient(
    -60deg,
    rgb(0, 0, 0) 30%,
    rgba(0, 0, 0, 0.333),
    rgb(0, 0, 0) 70%
  );
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) 125% / 300%
    100%;
  // https://stackoverflow.com/a/69970936

  @keyframes moving-shimmer {
    00% {
      mask-position-x: 125%;
      -webkit-mask-position-x: 125%;
    }
    10% {
      mask-position-x: 125%;
      -webkit-mask-position-x: 125%;
    }
    90% {
      mask-position-x: -25%;
      -webkit-mask-position-x: -25%;
    }
    100% {
      mask-position-x: -25%;
      -webkit-mask-position-x: -25%;
    }
  }
`;

function CircularProgress({
  progress,
  isLoading,
}: {
  progress: number;
  isLoading: boolean;
}) {
  const theme = useTheme();

  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      css={isLoading && shimmeringAnimation}
    >
      <defs>
        <path
          id="circlePath"
          d="
            M24,3.4
            A20.6,20.6 0 1,1 23.99,3.4
          "
        />
      </defs>
      <use
        xlinkHref="#circlePath"
        stroke={theme.colors.bg["sub-200"]}
        strokeWidth="6.8"
        fill="none"
      />

      <use
        id="progressArc"
        xlinkHref="#circlePath"
        stroke={theme.colors.primary.base}
        strokeWidth="6.8"
        fill="none"
        strokeDasharray="129.2"
        strokeDashoffset={129.2 * (1 - (isLoading ? 0 : progress))}
        css={css`
          transition-duration: 0.5s;
          transition-timing-function: ease-in-out;
        `}
      />
    </svg>
  );
}
