import { useState, useEffect } from "react";
import { Breakpoint, breakpoints } from "src/theme";

function getBreakpoint(windowWidth: number): Breakpoint {
  const keys = Object.keys(breakpoints) as Array<keyof typeof breakpoints>;
  let breakpoint: Breakpoint = "initial";
  for (let i = keys.length - 1; i > 0; i--) {
    const breakpointName = keys[i];

    const breakpointWidth = breakpoints[breakpointName];
    if (windowWidth >= breakpointWidth) {
      breakpoint = keys[i];
      break;
    }
  }
  return breakpoint;
}

export function useBreakpoint() {
  const [breakpoint, setBreakpoint] = useState<Breakpoint>(() =>
    getBreakpoint(window.innerWidth)
  );

  useEffect(() => {
    const handleResize = () => {
      const newBreakpoint = getBreakpoint(window.innerWidth);
      if (breakpoint !== newBreakpoint) {
        setBreakpoint(newBreakpoint);
      }
    };

    const mediaQueries = (
      Object.keys(breakpoints) as Array<keyof typeof breakpoints>
    ).map((key) => {
      const mq = window.matchMedia(`(min-width: ${breakpoints[key]}px)`);
      mq.addEventListener("change", handleResize);
      return mq;
    });

    // Call once on mount to set the initial value
    handleResize();

    return () => {
      mediaQueries.forEach((mq) =>
        mq.removeEventListener("change", handleResize)
      );
    };
  }, [breakpoint]);

  return breakpoint;
}
