import * as React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { css, useTheme } from "@emotion/react";
import { Check } from "@phosphor-icons/react";

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => {
  const theme = useTheme();

  return (
    <CheckboxPrimitive.Root
      ref={ref}
      {...props}
      css={[
        css`
          all: unset;
        `,
        css`
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          width: 16px;
          min-width: 16px;
          height: 16px;
          border-radius: 4px;
          outline: none;
          color: ${theme.colors.neutral.static.white};

          background: ${theme.colors.bg["soft-200"]};
          border: 1px solid ${theme.colors.bg["soft-200"]};
          cursor: not-allowed;

          &:not([data-disabled]) {
            background: ${theme.colors.bg["white-0"]};
            border-color: ${theme.colors.bg["soft-200"]};

            cursor: pointer;
            &:hover {
              background: ${theme.colors.bg["white-0"]};
              border-color: ${theme.colors.bg["sub-300"]};
            }

            &:focus {
              outline: none;
              background: ${theme.colors.bg["white-0"]};
              border-color: ${theme.colors.primary.base};
            }

            &[data-state="checked"] {
              background: ${theme.colors.primary.base};
              border-color: ${theme.colors.primary.base};

              &:hover {
                background: ${theme.colors.primary.darker};
                border-color: ${theme.colors.primary.darker};
              }

              &:focus {
                outline: none;
                background: ${theme.colors.primary.dark};
                border-color: ${theme.colors.primary.dark};
              }
            }
          }
        `,
      ]}
    >
      <CheckboxPrimitive.Indicator
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <Check size={16} />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  );
});
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
