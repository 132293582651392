import { css, useTheme } from "@emotion/react";
import { Image } from "@phosphor-icons/react";
import { Placeholder } from "./Placeholder/Placeholder";
import { colorPalette } from "../theme";
import React from "react";
import { getShopifyImage } from "../lite/helpers/getShopifyImage";

export interface AvatarGroupProps {
  urls: string[];
  isLoading?: boolean;
  fallBackImageIcon?: React.ReactNode;
  size?: string;
  width?: string;
  background?: string;
  alt?: string;
}

const AvatarGroup = ({
  size = "24px",
  width = "42px",
  ...props
}: AvatarGroupProps) => {
  const theme = useTheme();

  if (props.isLoading) {
    return <Placeholder height="100%" width={width} />;
  }

  if (props.urls.length <= 1) {
    const noImage = !props.urls?.[0];

    const image = noImage ? (
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          width: ${width};
          height: ${size};
          border-radius: 4px;
          background: ${props.background ?? theme.colors.bg["weak-50"]};
        `}
      >
        {props.fallBackImageIcon ? (
          props.fallBackImageIcon
        ) : (
          <Image
            size={16}
            color={theme.colors.icon["soft-400"]}
            alt="missing image"
          />
        )}
      </div>
    ) : (
      <img
        src={getShopifyImage(props.urls[0], "x-small")}
        alt={props.alt ?? "avatar"}
        css={css`
          width: ${width};
          height: ${size};
          flex-shrink: 0;
          border-radius: 4px;
          object-fit: cover;
        `}
      />
    );

    return (
      <div
        css={[
          css`
            display: flex;
            justify-content: center;
            align-items: center;
          `,
        ]}
      >
        {image}
      </div>
    );
  } else {
    const firstImages = props.urls.slice(0, 3);

    return (
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
        `}
      >
        <div
          css={css`
            width: ${width};
            height: ${size};
            position: relative;
          `}
        >
          {firstImages.map((url, index) => (
            <img
              key={index}
              src={getShopifyImage(url, "x-small")}
              alt={props.alt ?? "avatar"}
              css={[
                css`
                  position: absolute;
                  z-index: ${3 - index};
                  display: flex;
                  width: ${size};
                  height: ${size};
                  object-fit: cover;
                  justify-content: center;
                  align-items: center;
                  border-radius: 999px;
                  border: 2px solid ${theme.colors.stroke["white-0"]};
                  background: ${colorPalette.neutral[200]};
                  left: 0;
                `,
                firstImages.length > 1 &&
                  css`
                    left: calc(
                      (${width} - ${size}) / ${firstImages.length - 1} *
                        ${index}
                    );
                  `,
              ]}
            />
          ))}
        </div>
      </div>
    );
  }
};

export default AvatarGroup;
