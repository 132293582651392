import { css, useTheme } from "@emotion/react";
import {
  ClockCountdown,
  Cursor,
  Icon,
  IconProps,
  Package,
  ShoppingCart,
} from "@phosphor-icons/react";
import React from "react";
import { colorPalette } from "../../theme";
import { Tooltip, TooltipContent, TooltipTrigger } from "../Tooltip/Tooltip";

const DataBox = ({
  data,
  RightIcon,
  hoverText,
  collectingData,
}: {
  data: number | undefined;
  RightIcon: Icon;
  hoverText: string;
  collectingData: boolean;
}) => {
  const theme = useTheme();
  return (
    <Tooltip delayDuration={0}>
      <TooltipTrigger asChild>
        <div
          css={[
            theme.typography["subheading-xxs"],
            css`
              display: flex;
              padding: 2px;
              justify-content: center;
              align-items: center;
              gap: 4px;
              flex: 1 0 0;
              align-self: stretch;

              border-radius: 4px;
              background: ${theme.colors.bg["weak-50"]};

              color: ${theme.colors.text["sub-600"]};
            `,
          ]}
        >
          <RightIcon size={12} weight={"fill"} />
          {!collectingData ? data : <ClockCountdown size={12} />}
        </div>
      </TooltipTrigger>
      <TooltipContent>{hoverText}</TooltipContent>
    </Tooltip>
  );
};

interface DataSubSectionProps {
  clicksInfo:
    | {
        clicks: number;
        percentOfMaxClicks: number | null;
      }
    | undefined;
  orders?: number | null;
  quantity?: number;
  timeFrame: number;
}

export const DataSubSection = ({
  clicksInfo,
  orders,
  quantity,
  timeFrame,
}: DataSubSectionProps) => {
  const theme = useTheme();

  function getCursorColor() {
    if (clicksInfo?.percentOfMaxClicks != null) {
      if (clicksInfo.percentOfMaxClicks >= 80) {
        return colorPalette.green["600"];
      } else if (clicksInfo.percentOfMaxClicks >= 60) {
        return colorPalette.green["400"];
      } else if (clicksInfo.percentOfMaxClicks >= 40) {
        return theme.colors.state.away.base;
      } else if (clicksInfo.percentOfMaxClicks >= 20) {
        return theme.colors.state.warning.base;
      } else {
        return theme.colors.state.error.base;
      }
    }
    return theme.colors.text["sub-600"];
  }

  const CursorWithColor = React.forwardRef<SVGSVGElement, IconProps>(
    (props, ref) => {
      return <Cursor ref={ref} color={getCursorColor()} {...props} />;
    }
  );

  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 2px;
        width: 100%;
      `}
    >
      <DataBox
        data={clicksInfo !== undefined ? clicksInfo.clicks : undefined}
        RightIcon={CursorWithColor}
        hoverText={
          clicksInfo !== undefined
            ? `${clicksInfo.clicks} clicks in the last ${timeFrame} days`
            : "Collecting clicks data, come back later!"
        }
        collectingData={clicksInfo === undefined}
      />
      {orders !== undefined && (
        <DataBox
          data={orders ?? undefined}
          RightIcon={ShoppingCart}
          hoverText={`${orders} orders in the last ${timeFrame} days`}
          collectingData={false}
        />
      )}
      {quantity !== undefined && (
        <DataBox
          data={quantity}
          RightIcon={Package}
          hoverText={`${quantity} items left in stock`}
          collectingData={false}
        />
      )}
    </div>
  );
};
