import { useCallback, useMemo } from "react";
import { LiteDashboardProductDto } from "../../api/types";
import AvatarGroup from "../AvatarGroup";
import TextRowCell from "../Table/TextRowCell";

import { css, SerializedStyles, useTheme } from "@emotion/react";
import "@ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import Table from "../Table/Table";
import { Badge } from "../Badges/Badge";
import { useBreakpoint } from "../../helpers/hooks/useBreakpoint";
import { ProductBadge } from "../ProductCard/ProductCard";
import { Tooltip, TooltipContent, TooltipTrigger } from "../Tooltip/Tooltip";
import useMerchant from "../../helpers/hooks/app/useMerchant";
import type {
  ColDef,
  GetRowIdFunc,
  SortChangedEvent,
} from "@ag-grid-community/core";
import type { CustomCellRendererProps } from "@ag-grid-community/react";
import { productBadgeTypeToData } from "../ProductCard/ProductBadgeTypeToData";

interface DashboardModalTableProps {
  products: LiteDashboardProductDto[];
  columnVisibilityAndOrder: (keyof DashboardModalTableProduct)[];
  defaultSort?: [keyof DashboardModalTableProduct, "asc" | "desc" | null];
  barColumns?: ("n_sold" | "forecasted_days_to_sellout" | "clicks")[];
  noRowsOverlayComponent?: () => JSX.Element;
  onSortChange?: (event: SortChangedEvent<DashboardModalTableProduct>) => void;
  onBottomReached?: () => void;
}

export type DashboardModalTableProduct = {
  main_product_id: string;
  image_urls: string[];
  title: string;
  badges: LiteDashboardProductDto["badges"];
} & LiteDashboardProductDto["selected_period_data"];

export const BarCell = ({
  value,
  percentage,
  multiColor,
}: {
  value: number;
  percentage: number;
  multiColor: boolean;
}) => {
  const theme = useTheme();
  let colorToUse = theme.colors.state.information.base;
  if (multiColor && percentage < 40) {
    colorToUse = theme.colors.state.success.base;
  } else if (multiColor && percentage < 80) {
    colorToUse = theme.colors.state.warning.base;
  } else if (multiColor) {
    colorToUse = theme.colors.state.error.base;
  }
  return (
    <div
      css={css`
        flex: 1 0 0;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 8px;
      `}
    >
      <div
        css={css`
          flex: 1 0 0;
          background-color: ${theme.colors.bg["soft-200"]};
          border-radius: 999px;
          height: 6px;
        `}
      >
        <div
          css={css`
            background-color: ${colorToUse};
            border-radius: 999px;
            height: 6px;
            width: ${percentage}%;
          `}
        />
      </div>
      <span
        css={[
          theme.typography["paragraph-xs"],
          css`
            color: ${theme.colors.text["sub-600"]};
          `,
        ]}
      >
        {value.toFixed(0)}
      </span>
    </div>
  );
};

export const DashboardProductModalTable = ({
  products,
  columnVisibilityAndOrder,
  barColumns = [],
  noRowsOverlayComponent,
  onSortChange,
  onBottomReached,
  defaultSort,
}: DashboardModalTableProps) => {
  const theme = useTheme();
  const breakpoint = useBreakpoint();
  const { merchant } = useMerchant();

  const renderPercentageCell = useCallback((value: number) => {
    return <TextRowCell align={"right"}>{value.toFixed(2)}%</TextRowCell>;
  }, []);

  const columnDefs = useMemo<ColDef<DashboardModalTableProduct>[]>(() => {
    const defs: ColDef<DashboardModalTableProduct>[] = [
      {
        headerName: "",
        field: "image_urls",
        hide: !columnVisibilityAndOrder.includes("image_urls"),
        cellRenderer: (
          props: CustomCellRendererProps<
            DashboardModalTableProduct,
            DashboardModalTableProduct["image_urls"]
          >
        ) => {
          const params = props;

          if (!params.value || params.value.length < 1) return null;

          return (
            <AvatarGroup
              urls={[params.value[0]]}
              size="32px"
              width={"32px"}
              alt={params.data?.title}
            />
          );
        },
        filter: false,
        sortable: false,
        width: 48, //32px + 8 * 2 padding
        cellClass: ["square-cell", "image-cell"],
      },
      {
        headerName: "Product",
        field: "title",
        sortable: false,
        hide: !columnVisibilityAndOrder.includes("title"),
        cellRenderer: (
          props: CustomCellRendererProps<
            DashboardModalTableProduct,
            DashboardModalTableProduct["title"]
          >
        ) => {
          return <TextRowCell align={"left"}>{props.value}</TextRowCell>;
        },
        flex: ["xs", "sm"].includes(breakpoint) ? 1 : 3,
      },
      {
        headerName: "Orders",
        field: "n_sold",
        sortable: true,
        sort: defaultSort?.[0] === "n_sold" ? defaultSort[1] : undefined,
        hide: !columnVisibilityAndOrder.includes("n_sold"),
        cellRenderer: (
          props: CustomCellRendererProps<
            DashboardModalTableProduct,
            DashboardModalTableProduct["n_sold"]
          >
        ) => {
          if (barColumns.includes("n_sold")) {
            return (
              <BarCell
                value={props.value || 0}
                percentage={props.data?.percentage_of_max_orders || 0}
                multiColor={false}
              />
            );
          }
          return <TextRowCell>{props.value || 0}</TextRowCell>;
        },
        flex: barColumns.includes("n_sold") ? 3 : 1,
      },
      {
        headerName: "Status",
        field: "badges",
        sortable: false,
        hide: !columnVisibilityAndOrder.includes("badges"),
        cellRenderer: (
          props: CustomCellRendererProps<
            DashboardModalTableProduct,
            DashboardModalTableProduct["badges"]
          >
        ) => {
          const badges = props.value;
          if (badges && badges.length > 0) {
            const badgesToShow = badges.slice(0, 2);
            const additionalBadges = badges.slice(2);
            return (
              <div
                css={css`
                  display: flex;
                  justify-content: flex-end;
                  gap: 4px;
                  width: 100%;
                `}
              >
                {badgesToShow.map((badge, index) => (
                  <ProductBadge
                    key={`badge-${index}`}
                    size={"small"}
                    badge={badge}
                  />
                ))}
                {additionalBadges.length > 0 && (
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div>
                        <Badge
                          _style={"light"}
                          color={"gray"}
                          size={"small"}
                          text={`+${additionalBadges.length}`}
                        />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent side={"right"}>
                      <div
                        css={css`
                          display: flex;
                          flex-direction: column;
                          gap: 4px;
                        `}
                      >
                        {additionalBadges.map((badge, index) => (
                          <p
                            css={[
                              theme.typography["paragraph-xs"],
                              css`
                                margin-bottom: 0;
                              `,
                            ]}
                            key={`badge-${index}`}
                          >
                            {productBadgeTypeToData[badge.type].title}
                          </p>
                        ))}
                      </div>
                    </TooltipContent>
                  </Tooltip>
                )}
              </div>
            );
          }
          return null;
        },
        flex: 3,
      },
      {
        headerName: "Revenue",
        field: "revenue",
        sortable: false,
        hide: !columnVisibilityAndOrder.includes("revenue"),
        cellRenderer: (
          props: CustomCellRendererProps<
            DashboardModalTableProduct,
            DashboardModalTableProduct["revenue"]
          >
        ) => {
          return (
            <TextRowCell>
              {(props.value || 0).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}{" "}
              {merchant?.base_currency}
            </TextRowCell>
          );
        },
        flex: 1.5,
      },
      {
        headerName: "% of total",
        field: "percentage_of_total_orders",
        sortable: true,
        sort:
          defaultSort?.[0] === "percentage_of_total_orders"
            ? defaultSort[1]
            : undefined,
        hide: !columnVisibilityAndOrder.includes("percentage_of_total_orders"),
        cellRenderer: (
          props: CustomCellRendererProps<
            DashboardModalTableProduct,
            DashboardModalTableProduct["percentage_of_total_orders"]
          >
        ) => {
          return renderPercentageCell(props.value || 0);
        },
        flex: 1.25,
      },
      {
        headerName: "Projected sellout in weeks",
        field: "forecasted_days_to_sellout",
        sortable: true,
        sort:
          defaultSort?.[0] === "forecasted_days_to_sellout"
            ? defaultSort[1]
            : undefined,
        hide: !columnVisibilityAndOrder.includes("forecasted_days_to_sellout"),
        cellRenderer: (
          props: CustomCellRendererProps<
            DashboardModalTableProduct,
            DashboardModalTableProduct["forecasted_days_to_sellout"]
          >
        ) => {
          const invertedSellThroughRate =
            100 - (props.data?.sellthrough_rate || 0);
          return (
            <BarCell
              value={Math.floor(props.value || 0)}
              percentage={invertedSellThroughRate}
              multiColor={true}
            />
          );
        },
        flex: barColumns.includes("forecasted_days_to_sellout") ? 3 : 1,
      },
      {
        headerName: "In Stock",
        field: "quantity",
        sortable: false,
        hide: !columnVisibilityAndOrder.includes("quantity"),
        cellRenderer: (
          props: CustomCellRendererProps<
            DashboardModalTableProduct,
            DashboardModalTableProduct["quantity"]
          >
        ) => {
          return <TextRowCell>{props.value || 0}</TextRowCell>;
        },
        flex: 1,
      },
      {
        headerName: "Sell-through-rate",
        field: "sellthrough_rate",
        sortable: true,
        sort:
          defaultSort?.[0] === "sellthrough_rate" ? defaultSort[1] : undefined,
        hide: !columnVisibilityAndOrder.includes("sellthrough_rate"),
        cellRenderer: (
          props: CustomCellRendererProps<
            DashboardModalTableProduct,
            DashboardModalTableProduct["sellthrough_rate"]
          >
        ) => {
          return renderPercentageCell(props.value || 0);
        },
        flex: 2,
      },
      {
        headerName: "Clicks",
        field: "clicks",
        sortable: true,
        sort: defaultSort?.[0] === "clicks" ? defaultSort[1] : undefined,
        hide: !columnVisibilityAndOrder.includes("clicks"),
        filter: true,
        cellRenderer: (
          props: CustomCellRendererProps<
            DashboardModalTableProduct,
            DashboardModalTableProduct["clicks"]
          >
        ) => {
          if (barColumns.includes("clicks")) {
            return (
              <BarCell
                value={props.value || 0}
                percentage={props.data?.percentage_of_max_clicks || 0}
                multiColor={false}
              />
            );
          }
          return <TextRowCell>{props.value || 0}</TextRowCell>;
        },
        flex: barColumns.includes("clicks") ? 3 : 1,
      },
      {
        headerName: "% of total clicks",
        field: "percentage_of_total_clicks",
        sortable: true,
        sort:
          defaultSort?.[0] === "percentage_of_total_clicks"
            ? defaultSort[1]
            : undefined,
        hide: !columnVisibilityAndOrder.includes("percentage_of_total_clicks"),
        filter: true,
        cellRenderer: (
          props: CustomCellRendererProps<
            DashboardModalTableProduct,
            DashboardModalTableProduct["percentage_of_total_clicks"]
          >
        ) => {
          return renderPercentageCell(props.value || 0);
        },
        flex: 1,
      },
    ];
    const orderedDefs: ColDef<DashboardModalTableProduct>[] = [];
    columnVisibilityAndOrder.forEach((key) => {
      const def = defs.find((def) => def.field === key);
      if (def) {
        orderedDefs.push(def);
      }
    });
    return orderedDefs;
  }, [
    barColumns,
    breakpoint,
    columnVisibilityAndOrder,
    defaultSort,
    renderPercentageCell,
    theme.typography,
  ]);

  const data = useMemo(() => {
    return products.map((product) => {
      const _product: DashboardModalTableProduct = {
        main_product_id: product.main_product_id,
        image_urls: product.image_urls,
        title: product.title,
        badges: product.badges,
        ...product.selected_period_data,
      };

      return _product;
    });
  }, [products]);

  const getRowId: GetRowIdFunc<DashboardModalTableProduct> = (params) => {
    return params.data.main_product_id;
  };

  // First column header (except images since they have no header) should be left aligned
  const firstColumnKeyNotImages = columnVisibilityAndOrder.find(
    (key) => key !== "image_urls"
  );
  const headerStyleOverrides: Partial<
    Record<keyof DashboardModalTableProduct, SerializedStyles>
  > = {
    image_urls: css`
      display: none;
    `,
  };
  if (firstColumnKeyNotImages) {
    headerStyleOverrides[firstColumnKeyNotImages] = css`
      justify-content: flex-start;
    `;
  }
  const lastColumn =
    columnVisibilityAndOrder[columnVisibilityAndOrder.length - 1];
  // All bar column headers should be left aligned
  // If the bar happens to be the last column, we don't want to align it to the left
  barColumns
    ?.filter((c) => c !== lastColumn)
    .forEach((column) => {
      headerStyleOverrides[column] = css`
        justify-content: flex-start;
      `;
    });

  return (
    <Table<DashboardModalTableProduct>
      data={data}
      isLoading={false}
      columnDefs={columnDefs}
      getRowId={getRowId}
      noRowsOverlayComponent={noRowsOverlayComponent}
      headerContainerStyles={headerStyleOverrides}
      onSortChange={onSortChange}
      onBottomReached={onBottomReached}
    />
  );
};
