import { useDragAndDropContext } from "./DragAndDropProvider";
import { useEffect, useRef } from "react";
import { useDNDSetOpacity } from "./DNDOpacityProvider";
import { useZone } from "./useZone";
import { useRowMode } from "./useRowMode";
import { useIsDraggingAnyProduct } from "./useIsDraggingAnyProduct";
import { useImportantDraggingSize } from "./useImportantDraggingSize";

/**
 * Renders the zones that one needs to put into the product card and content block components. The way we know where one is about to drop is by listening on "hover events" on these zones.
 * This way we defer hit testing to the browsers native implementation and both reduce complexity and improve performance for knowing where the user is about to drop something.
 */
export function DragAndDropZones({
  isUnpinnedProduct,
  forbidLeftZone,
  forbidRightZone,
  ...zoneInfo
}: {
  type: "product" | "content";
  id: string;
  isUnpinnedProduct?: boolean;
  /**
   * Whether to never render a left zone. Used to not enable dropping between product duplicates.
   */
  forbidLeftZone?: boolean;
  forbidRightZone?: boolean;
}) {
  const {
    refState,
    isDragging,
    occupiedByBlocksOrProducts,
    lastPinnedProduct,
    productsToRender,
    gridStateWhileDragging,
    firstUnpinnedProduct,
  } = useDragAndDropContext();

  const rowMode = useRowMode();

  const setHalfOpacity = useDNDSetOpacity();
  const hoveredWhileDisabled = useRef<typeof usRight | typeof usLeft>();

  let isContentBlockInUnpinnedSection = false;
  let contentToOurRight = false;

  const isDraggingAnyProduct = useIsDraggingAnyProduct();

  // Disable dropping products on content blocks in the pinned section (whose zones are still active)
  if (zoneInfo.type === "content" && isDraggingAnyProduct) {
    const indexInProductsOfLastPinnedProduct = productsToRender.findIndex(
      (item) => item.main_product_id === lastPinnedProduct
    );
    const physicalIndexOfFirstUnpinnedProduct =
      occupiedByBlocksOrProducts.findIndex(
        (item) => item === indexInProductsOfLastPinnedProduct
      );
    const indexOfUsIfContent = occupiedByBlocksOrProducts.findIndex(
      (item) => item === zoneInfo.id
    );

    if (
      indexOfUsIfContent > physicalIndexOfFirstUnpinnedProduct + 1 ||
      physicalIndexOfFirstUnpinnedProduct === -1
    ) {
      isContentBlockInUnpinnedSection = true;
    }

    for (
      let i = indexOfUsIfContent + 1;
      i < occupiedByBlocksOrProducts.length;
      i++
    ) {
      const here = occupiedByBlocksOrProducts[i];
      // If our block still stretches over this slot, continue
      if (here === zoneInfo.id) continue;
      if (typeof here === "string") {
        if (
          occupiedByBlocksOrProducts.findIndex((slot) => slot === here) !== i
        ) {
          // If the block that is next doesn't start here, move on until we find the next block/product
          continue;
        }
        contentToOurRight = true;
      }
      break;
    }
  } else {
    // Only products move around when we remove what's being dragged because they're not stones.
    // Therefore we need to calculate "contentToOurRight" from the during-drag state if it exists
    const occupiedByBlocksOrProductsDuringDrag =
      gridStateWhileDragging?.occupiedByBlocksOrProducts;
    const productsToRenderDuringDrag = gridStateWhileDragging?.productsToRender;
    const productsToRenderToUse =
      productsToRenderDuringDrag || productsToRender;
    const occupiedByBlocksOrProductsToUse =
      occupiedByBlocksOrProductsDuringDrag || occupiedByBlocksOrProducts;
    const productIndexOfUs = productsToRenderToUse.findIndex(
      (item) => item.main_product_id === zoneInfo.id
    );
    const indexInSlotMap = occupiedByBlocksOrProductsToUse.findIndex(
      (slot) => slot === productIndexOfUs
    );
    for (
      let i = indexInSlotMap + 1;
      i < occupiedByBlocksOrProductsToUse.length;
      i++
    ) {
      const inSlotToRight = occupiedByBlocksOrProductsToUse[i];
      if (
        occupiedByBlocksOrProductsToUse.findIndex(
          (item) => item === inSlotToRight
        ) !== i
      ) {
        // This is a block that doesn't start here, so we're not interested in it
        continue;
      }
      contentToOurRight = typeof inSlotToRight === "string";
      break;
    }
  }
  let disabledBecauseUnpinned = false;
  if (isUnpinnedProduct || isContentBlockInUnpinnedSection) {
    for (const item of isDragging) {
      if (!occupiedByBlocksOrProducts.includes(item)) {
        // This is not a content block, because for products we have indexes in occupiedByBlocksOrProducts to make it agnostic to the actual products existing
        // Only allow dropping pure content block selections in-between unpinned products
        disabledBecauseUnpinned = true;
        break;
      }
    }
  }

  const { leftDisabledToFitDraggingBlock, rightDisabledToFitDraggingBlock } =
    useShouldDisableDropZoneForBlocks(zoneInfo);

  const { extendZoneBy, extendZone } = useEmptySpotsToTheRightInRow(zoneInfo);

  const indexOfUsIfContent = occupiedByBlocksOrProducts.findIndex(
    (item) => item === zoneInfo.id
  );

  const weAreFirstUnpinnedProduct = zoneInfo.id === firstUnpinnedProduct;
  const disableRow = useShouldDisableForRowMode(zoneInfo);

  const {
    ref: leftZoneDiv,
    record: usLeft,
    disabled: leftZoneDisabled,
  } = useZone({
    zoneInfo,
    position: "left",
    refState,
    disabled:
      disableRow ||
      disabledBecauseUnpinned ||
      leftDisabledToFitDraggingBlock ||
      (weAreFirstUnpinnedProduct && indexOfUsIfContent === 0),
    hoveredWhileDisabled,
  });

  const {
    ref: rightZoneDiv,
    record: usRight,
    disabled: rightZoneDisabled,
  } = useZone({
    zoneInfo,
    position: "right",
    refState,
    disabled:
      disabledBecauseUnpinned ||
      // In rowMode we're going up, so don't care if there's content to the right
      (contentToOurRight && !rowMode) ||
      rightDisabledToFitDraggingBlock ||
      disableRow,
    hoveredWhileDisabled,
  });

  useEffect(
    () => setHalfOpacity(disabledBecauseUnpinned),
    [disabledBecauseUnpinned, setHalfOpacity]
  );

  return (
    // Only show zones at all if the first item being dragged would fit here, as otherwise we just enlarge the leftmost zone of the item to the left of us
    !(leftDisabledToFitDraggingBlock && rightDisabledToFitDraggingBlock) && (
      <>
        {!forbidLeftZone && (
          <div
            className={`dnd-zone left${leftZoneDisabled ? " disabled" : ""}${
              // Add hovered class from react side too in case it overrides our instantly set (without re-rendering) hovered class from below when `leftZoneDisabled` changes during a hover
              hoveredWhileDisabled.current === usLeft ||
              refState.current.hoveringOn === usLeft
                ? " hovered"
                : ""
            }`}
            ref={leftZoneDiv}
            style={
              extendZone === "left" && extendZoneBy
                ? {
                    ["--extend-by-slots" as string]: extendZoneBy,
                  }
                : {}
            }
          />
        )}
        {extendZone === "right" && !!extendZoneBy && !forbidRightZone && (
          <div
            style={{
              ["--extend-by-slots" as string]: extendZoneBy,
            }}
            ref={rightZoneDiv}
            className={`dnd-zone right${rightZoneDisabled ? " disabled" : ""}${
              // Add hovered class from react side too in case it overrides our instantly set (without re-rendering) hovered class from below when `leftZoneDisabled` changes during a hover
              hoveredWhileDisabled.current === usRight ||
              refState.current.hoveringOn === usRight
                ? " hovered"
                : ""
            }`}
          />
        )}
      </>
    )
  );
}

function useShouldDisableDropZoneForBlocks(zoneInfo: {
  id: string;
  type: "content" | "product";
}) {
  const { numberOfColumns, gridStateWhileDragging } = useDragAndDropContext();

  const { importantDraggingWidth, spanColumnsByBlockId } =
    useImportantDraggingSize();
  const rowMode = useRowMode();

  let leftDisabledToFitDraggingBlock = false;
  let rightDisabledToFitDraggingBlock = false;

  if (!rowMode && gridStateWhileDragging) {
    const { occupiedByBlocksOrProducts, productsToRender } =
      gridStateWhileDragging;
    const lookingForInMap =
      zoneInfo.type === "content"
        ? zoneInfo.id
        : productsToRender.findIndex(
            (item) => item.main_product_id === zoneInfo.id
          );
    const ourIndex = occupiedByBlocksOrProducts.findIndex(
      (item) => lookingForInMap === item
    );
    const ourColumn = ourIndex % numberOfColumns;
    const { span_columns } = spanColumnsByBlockId[zoneInfo.id] || 1;
    const rightZoneColumn = ourColumn + span_columns;
    if (
      rightZoneColumn + importantDraggingWidth > numberOfColumns &&
      importantDraggingWidth > 1
    ) {
      rightDisabledToFitDraggingBlock = true;
    }
    if (ourColumn + importantDraggingWidth > numberOfColumns) {
      leftDisabledToFitDraggingBlock = true;
    }
  }

  return { leftDisabledToFitDraggingBlock, rightDisabledToFitDraggingBlock };
}

function useShouldDisableForRowMode(zoneInfo: {
  id: string;
  type: "content" | "product";
}) {
  const { numberOfColumns, gridStateWhileDragging } = useDragAndDropContext();
  const rowMode = useRowMode();

  if (rowMode && gridStateWhileDragging) {
    const { occupiedByBlocksOrProducts, productsToRender } =
      gridStateWhileDragging;
    const lookingForInMap =
      zoneInfo.type === "content"
        ? zoneInfo.id
        : productsToRender.findIndex(
            (item) => item.main_product_id === zoneInfo.id
          );
    const ourIndex = occupiedByBlocksOrProducts.findIndex(
      (item) => lookingForInMap === item
    );
    const ourRow = Math.floor(ourIndex / numberOfColumns);
    if (ourRow > 0) {
      const prevRowStartIndex = (ourRow - 1) * numberOfColumns;
      const prevRowEndIndex = ourRow * numberOfColumns;

      const blocksIdsInTheWay = occupiedByBlocksOrProducts
        .slice(prevRowStartIndex, prevRowEndIndex)
        .filter((item) => typeof item === "string");

      return blocksIdsInTheWay.some((blockId) =>
        gridStateWhileDragging.repositionedBlocksWithRender.some(
          (b) =>
            b.block.DOMElementId === blockId &&
            // The block hangs over our row
            Math.floor(b.finalBlockIndex / numberOfColumns) +
              b.block.span_rows >
              ourRow
        )
      );
    }
  }
  return false;
}

function useEmptySpotsToTheRightInRow(zoneInfo: {
  type: "product" | "content";
  id: string;
}) {
  const {
    occupiedByBlocksOrProducts,
    gridStateWhileDragging,
    productsToRender,
    numberOfColumns,
  } = useDragAndDropContext();
  const { importantDraggingWidth } = useImportantDraggingSize();
  const makeSpaceForBlock = importantDraggingWidth > 1;
  const mapOfGridBeingShown =
    gridStateWhileDragging?.occupiedByBlocksOrProducts ||
    occupiedByBlocksOrProducts;
  const productsInGrid =
    gridStateWhileDragging?.productsToRender || productsToRender;

  const lookingForInMap =
    zoneInfo.type === "content"
      ? zoneInfo.id
      : productsInGrid.findIndex(
          (item) => item.main_product_id === zoneInfo.id
        );
  const ourIndex = mapOfGridBeingShown.findIndex(
    (item) => lookingForInMap === item
  );
  const ourRow = Math.floor(ourIndex / numberOfColumns);
  const ourRowEnd = (ourRow + 1) * numberOfColumns;

  const inThisRowAfterUs = mapOfGridBeingShown.slice(ourIndex + 1, ourRowEnd);
  const somethingElseToOurRight = inThisRowAfterUs.some(
    (item) => item !== null && item !== zoneInfo.id
  );
  const usInstancesToOurRight = inThisRowAfterUs.filter(
    (item) => item === zoneInfo.id
  ).length;

  // Prioritise enlarging the left zone to fit a 2xSomething block being dragged
  if (makeSpaceForBlock && !usInstancesToOurRight) {
    const indexInOurRow = ourIndex % numberOfColumns;
    if (indexInOurRow === numberOfColumns - importantDraggingWidth) {
      const extendZoneBy = Math.max(0, importantDraggingWidth - 1);
      return { extendZoneBy, extendZone: "left" };
    }
  }

  if (!somethingElseToOurRight) {
    // There's nothing to our right, enlarge the right zone for convenience of putting things there
    const extendZoneBy = Math.max(
      0,
      ourRowEnd - 1 - ourIndex - usInstancesToOurRight
    ); // just in case it gets -1 at some point (should never happen)

    return { extendZoneBy, extendZone: "right" };
  }

  return { extendZone: undefined, extendZoneBy: undefined };
}
