import { useCollectionProducts } from "../../Collection/useCollectionProducts";
import useCollectionProductClicks from "../../Collection/useCollectionProductClicks";
import {
  LiteCollectionProductDto,
  LiteDashboardCollectionDto,
} from "../../../../api/types";
import { useMemo } from "react";

export type ProductClickRow = {
  main_product_id: string;
  image_urls: string[];
  title: string;
  clicks: number;
  percentage_of_max_clicks: number;
  position: number;
  average_sort_index: number | null;
  skus: string[];
};

const fakeDataWhileLoading: ProductClickRow[] = Array.from({ length: 10 }).map(
  (_, i) => ({
    main_product_id: `fake-${i}`,
    image_urls: [],
    title: "Loading...",
    clicks: 0,
    percentage_of_max_clicks: 0,
    position: i + 1,
    average_sort_index: null,
    skus: [],
  })
);

export default function useCollectionProductTableProducts({
  collection,
  fromDate,
  toDate,
}: {
  collection?: LiteDashboardCollectionDto;
  fromDate: Date;
  toDate: Date;
}) {
  const { products: serverProducts, isLoading: productsAreLoading } =
    useCollectionProducts(
      collection?.collection_id,
      collection?.sync_back_to_shopify ? collection.sort_order : "SHOPIFY_SORT"
    );
  const { data: productClicks, isLoading: clicksAreLoading } =
    useCollectionProductClicks({
      collectionId: collection?.collection_id,
      fromDate,
      toDate,
      enabled: true,
    });

  const isLoading = productsAreLoading || clicksAreLoading;

  const products: ProductClickRow[] = useMemo(() => {
    if (isLoading) {
      return fakeDataWhileLoading;
    }

    const maxClicks = Math.max(
      ...Object.values(productClicks || {}).map((p) => p.clicks || 0)
    );
    const products =
      serverProducts
        ?.filter((p): p is LiteCollectionProductDto => !!p)
        .reduce((acc: LiteCollectionProductDto[], p) => {
          if (
            acc.findIndex((a) => a.main_product_id === p.main_product_id) < 0
          ) {
            acc.push(p);
          }
          return acc;
        }, [] as LiteCollectionProductDto[]) || [];

    return products.map((p, index) => ({
      main_product_id: p.main_product_id,
      image_urls: p.image_urls,
      title: p.title,
      clicks: productClicks?.[p.main_product_id]?.clicks || 0,
      percentage_of_max_clicks: productClicks?.[p.main_product_id]?.clicks
        ? (productClicks?.[p.main_product_id]?.clicks / maxClicks) * 100
        : 0,
      position: index + 1,
      average_sort_index:
        productClicks?.[p.main_product_id]?.averagePosition || null,
      skus: p.skus,
    }));
  }, [serverProducts, productClicks, isLoading]);

  return { products, isLoading };
}
