import { css } from "@emotion/react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../alignUI/Tooltip/Tooltip";
import { Badge } from "../../../../alignUI/Badges/Badge";

interface UnPublishedBadgeProps {
  onToolTipClick?: () => void;
  status: "warning" | "error";
}

export const UnPublishedBadge = (props: UnPublishedBadgeProps) => {
  return (
    <Tooltip delayDuration={0}>
      <TooltipTrigger asChild>
        <Badge
          _style={"filled"}
          text={props.status === "error" ? "UNPUBLISHED" : "SAVE TO APPLY"}
          color={props.status === "error" ? "red" : "gray"}
        ></Badge>
      </TooltipTrigger>
      <TooltipContent size={"large"} side={"bottom"}>
        {props.status === "error" ? (
          <>
            This is not visible in your online store yet. To go live, change to
            a Depict template{" "}
            <a
              onClick={props.onToolTipClick}
              css={css`
                text-decoration: underline;
                cursor: pointer;
              `}
            >
              here
            </a>
          </>
        ) : (
          <>Save to apply your collection template.</>
        )}
      </TooltipContent>
    </Tooltip>
  );
};
