import {
  MutableRefObject,
  PointerEvent,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { DNDRefState, DragAndDropHover, ZoneInfo } from "./types";
import { useDragAndDropContext } from "./DragAndDropProvider";
import { Record } from "@bloomberg/record-tuple-polyfill";
import { useRowMode } from "./useRowMode";
import { useIsDraggingAnyProduct } from "./useIsDraggingAnyProduct";
import { useAnimationStyling } from "./useAnimationStyling";

export function useZone(args: {
  position: "left" | "right";
  refState: MutableRefObject<DNDRefState>;
  disabled: boolean;
  zoneInfo: ZoneInfo;
  hoveredWhileDisabled?: MutableRefObject<DragAndDropHover | undefined>;
}): {
  ref: MutableRefObject<HTMLDivElement | null>;
  pointerThatEntered: MutableRefObject<number | undefined>;
  record: DragAndDropHover;
  disabled: boolean;
} {
  const ref = useRef<HTMLDivElement>(null);
  const pointerThatEntered = useRef<number>();
  const dndContext = useDragAndDropContext();
  const rowMode = useRowMode();

  // is any of the items in isDragging a product
  const isDraggingAnyProduct = useIsDraggingAnyProduct();

  const record: DragAndDropHover = Record({
    id: args.zoneInfo.id,
    type: args.zoneInfo.type,
    position: args.position,
  });

  const { addAnimationStyling, removeAnimationStyling } =
    useAnimationStyling(record);

  const disabled =
    dndContext.dndDisabled ||
    args.disabled ||
    // Don't allow dropping on the left side of content blocks at all
    (args.zoneInfo.type === "content" &&
      isDraggingAnyProduct &&
      args.position === "left" &&
      !rowMode);

  const unset = useCallback(
    function unset() {
      if (args.refState.current.hoveringOn === record) {
        args.refState.current.hoveringOn = undefined;
      }
    },
    [args.refState, record]
  );

  // need to add event listeners like this because chrome mobile, even when releasing pointer capture, doesn't dispatch pointerenter/pointerleave events and react doesn't receive our manually dispatched ones to its synthetic event system
  // We have to use these weird effects and handle removing the listener again because if we just did `ref={el => el && el.addEventListener` the information in the scope of the listener is outdated due to reacts re-rendering
  useEffect(() => {
    const div = ref.current;
    if (!div) return;
    const enterHandler = ({ pointerId, target }: PointerEvent) => {
      if (pointerId !== args.refState.current.dragPointerId) return;
      pointerThatEntered.current = pointerId;

      if (disabled && args.hoveredWhileDisabled) {
        args.hoveredWhileDisabled.current = record;
      } else {
        // By using refs for the global state where we don't need to know in react when the value is updated during a drag (we only need the value when the drag ends)
        args.refState.current.hoveringOn = record;
      }

      // Touch device supporting substitute for :hover in css
      (target as HTMLDivElement).classList.add("hovered");
      addAnimationStyling();
    };

    const leaveHandler = ({ pointerId, target }: PointerEvent) => {
      if (pointerId !== pointerThatEntered.current) return;
      pointerThatEntered.current = undefined;

      if (disabled && args.hoveredWhileDisabled) {
        args.hoveredWhileDisabled.current = undefined;
      }

      unset();
      (target as HTMLDivElement).classList.remove("hovered");
      removeAnimationStyling();
    };

    // @ts-expect-error TS types are incorrect for HTMLElements - the event is typed as Event instead of PointerEvent which it actually is
    div.addEventListener("pointerover", enterHandler);
    // @ts-expect-error TS types are incorrect for HTMLElements - the event is typed as Event instead of PointerEvent which it actually is
    div.addEventListener("pointerout", leaveHandler);
    return () => {
      // @ts-expect-error TS types are incorrect for HTMLElements - the event is typed as Event instead of PointerEvent which it actually is
      div.removeEventListener("pointerover", enterHandler);
      // @ts-expect-error TS types are incorrect for HTMLElements - the event is typed as Event instead of PointerEvent which it actually is
      div.removeEventListener("pointerout", leaveHandler);
    };
  }, [
    addAnimationStyling,
    args.hoveredWhileDisabled,
    args.position,
    args.refState,
    args.zoneInfo,
    disabled,
    dndContext.gridStateWhileDragging,
    dndContext.numberOfColumns,
    record,
    removeAnimationStyling,
    rowMode,
    unset,
  ]);

  useEffect(() => {
    return () => unset();
  }, [unset]);

  return {
    ref,
    pointerThatEntered,
    record,
    disabled,
  };
}
