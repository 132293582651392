import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalTitle,
} from "src/alignUI/Modal/Modal";
import { css, useTheme } from "@emotion/react";
import { LiteDashboardCollectionDto } from "../../../../api/types";
import { useEffect, useMemo, useState } from "react";
import AvatarGroup from "../../../../alignUI/AvatarGroup";
import { getFromPeriod, Period } from "../util";
import ClickthroughRateModalProductTable from "./ClickthroughRateModalProductTable";
import ClickthroughRateGauge from "../Widget/ClickthroughRateGauge";
import useIsMobile from "../../../../helpers/hooks/useIsMobile";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from "../../../../alignUI/Select/Select";
import { Placeholder } from "../../../../alignUI/Placeholder/Placeholder";
import { Link } from "react-router-dom";
import useMerchantId from "../../../../helpers/hooks/app/useMerchantId";
import TimeFramePicker from "../TimeFramePicker";
import useCollectionSearch from "../../Collections/useCollectionSearch";
import { Search } from "../../../../alignUI/Search/Search";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from "../../../../alignUI/Dropdown/Dropdown";
import { Button } from "../../../../alignUI/Button/Button";
import { Export, SortAscending, SortDescending } from "@phosphor-icons/react";
import useCollectionProductTableProducts from "./useCollectionProductTableProducts";
import { unparse } from "papaparse";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../alignUI/Tooltip/Tooltip";
import useTrackEvent from "../../../helpers/useTrackEvent";
import { components } from "../../../../api/generated/openapi";

export default function ClickthroughRateModal({
  open,
  onClose,
  selectedCollectionId,
  onSetSelectedCollectionId,
  collectionsData,
  collectionsDataLoading,
}: {
  selectedCollectionId?: string;
  onSetSelectedCollectionId: (collectionId: string) => void;
  open: boolean;
  onClose: () => void;
  collectionsData:
    | components["schemas"]["LiteDashboardCollectionDto"][]
    | undefined;
  collectionsDataLoading: boolean;
}) {
  const theme = useTheme();

  const now = useMemo(() => new Date(), []);
  const [period, setPeriod] = useState<Period>({
    type: "4w",
    fromDate: getFromPeriod("4w", now),
    toDate: now,
  });

  const { fromDate, toDate } = period;

  useEffect(() => {
    if (
      collectionsData &&
      !selectedCollectionId &&
      collectionsData.length > 0
    ) {
      onSetSelectedCollectionId(collectionsData[0].collection_id);
    }
  }, [collectionsData, onSetSelectedCollectionId, selectedCollectionId]);

  const selectedCollection = collectionsData?.find(
    (c) => c.collection_id === selectedCollectionId
  );
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [sort, setSort] = useState<
    | "clickthrough_rate_desc"
    | "clickthrough_rate_asc"
    | "clicks_desc"
    | "clicks_asc"
    | "views_desc"
    | "views_asc"
  >("clicks_desc");
  const results = useCollectionSearch(searchTerm, collectionsData || []);

  const isMobile = useIsMobile();
  const { merchantId } = useMerchantId();

  const { products, isLoading } = useCollectionProductTableProducts({
    collection: selectedCollection,
    fromDate,
    toDate,
  });

  const trackEventMutation = useTrackEvent();

  const handleDownloadCsv = async () => {
    const jsonData = products.map((p) => ({
      product_id: p.main_product_id,
      title: p.title,
      position: p.position,
      clicks: p.clicks,
      skus: p.skus,
    }));
    const csvString = unparse(jsonData);
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `clickthrough_rate_${selectedCollectionId}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    await trackEventMutation.mutateAsync({
      merchantId,
      eventType: "clickthrough_rate_csv_export",
      properties: {
        collection_id: selectedCollectionId || null,
        collection_title: selectedCollection?.title || null,
      },
    });
  };

  return (
    <Modal open={open} onOpenChange={(isOpen) => !isOpen && onClose()}>
      <ModalContent size="large" fullHeight>
        <ModalHeader>
          <ModalTitle>Top collections</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div
            css={css`
              display: flex;
              height: 100%;
              gap: ${isMobile ? "16px" : "24px"};
              flex-direction: ${isMobile ? "column" : "row"};
              overflow-y: ${isMobile ? "auto" : "hidden"};
            `}
          >
            {!isMobile ? (
              <div
                css={css`
                  padding: 10px;
                  border-radius: 16px;
                  border: 1px solid ${theme.colors.stroke["soft-200"]};
                  box-shadow: ${theme.shadows.regular};
                  width: 258px;
                  overflow: hidden;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  gap: 8px;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  `}
                >
                  <h5
                    css={[
                      theme.typography["subheading-xs"],
                      css`
                        color: ${theme.colors.text["soft-400"]};
                        padding: 4px 8px;
                        margin: 0;
                      `,
                    ]}
                  >
                    Collections
                  </h5>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        variant={"neutral"}
                        _style={"stroke"}
                        size="small"
                      >
                        {sort.includes("desc") ? (
                          <SortAscending />
                        ) : (
                          <SortDescending />
                        )}
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <DropdownMenuLabel>Sort by</DropdownMenuLabel>
                      <DropdownMenuRadioGroup
                        value={sort}
                        onValueChange={(value) => {
                          setSort(value as typeof sort);
                        }}
                      >
                        <DropdownMenuRadioItem value="clicks_desc">
                          Clicks (High to low)
                        </DropdownMenuRadioItem>
                        <DropdownMenuRadioItem value="clicks_asc">
                          Clicks (Low to high)
                        </DropdownMenuRadioItem>
                        <DropdownMenuRadioItem value="clickthrough_rate_desc">
                          Clickthrough rate (High to low)
                        </DropdownMenuRadioItem>
                        <DropdownMenuRadioItem value="clickthrough_rate_asc">
                          Clickthrough rate (Low to high)
                        </DropdownMenuRadioItem>
                        <DropdownMenuRadioItem value="views_desc">
                          Views (High to low)
                        </DropdownMenuRadioItem>
                        <DropdownMenuRadioItem value="views_asc">
                          Views (Low to high)
                        </DropdownMenuRadioItem>
                      </DropdownMenuRadioGroup>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
                <Search onSearch={setSearchTerm} alwaysMaxWidth />
                <div
                  css={css`
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    overflow-y: auto;
                  `}
                >
                  {collectionsDataLoading &&
                    Array.from({ length: 15 }).map((_, index) => (
                      <Placeholder key={index} height={"40px"} width={"100%"} />
                    ))}
                  {!collectionsDataLoading &&
                    results
                      ?.slice()
                      ?.sort((a, b) => {
                        switch (sort) {
                          case "clickthrough_rate_desc":
                            return (
                              (b.selected_period_data.clickthrough_rate || 0) -
                              (a.selected_period_data.clickthrough_rate || 0)
                            );
                          case "clickthrough_rate_asc":
                            return (
                              (a.selected_period_data.clickthrough_rate || 0) -
                              (b.selected_period_data.clickthrough_rate || 0)
                            );
                          case "clicks_desc":
                            return (
                              (b.selected_period_data.clicks || 0) -
                              (a.selected_period_data.clicks || 0)
                            );
                          case "clicks_asc":
                            return (
                              (a.selected_period_data.clicks || 0) -
                              (b.selected_period_data.clicks || 0)
                            );
                          case "views_desc":
                            return (
                              (b.selected_period_data.views || 0) -
                              (a.selected_period_data.views || 0)
                            );
                          case "views_asc":
                            return (
                              (a.selected_period_data.views || 0) -
                              (b.selected_period_data.views || 0)
                            );
                        }
                      })
                      .map((collection: LiteDashboardCollectionDto) => (
                        <div
                          data-active={
                            collection.collection_id === selectedCollectionId
                          }
                          onClick={() => {
                            onSetSelectedCollectionId(collection.collection_id);
                          }}
                          key={collection.collection_id}
                          css={css`
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            gap: 8px;
                            padding: 8px;
                            border-radius: 8px;
                            cursor: pointer;

                            &[data-active="true"] {
                              background-color: ${theme.colors.bg["weak-50"]};
                            }

                            &:hover {
                              background-color: ${theme.colors.bg["weak-50"]};
                            }
                          `}
                        >
                          <div
                            css={css`
                              display: flex;
                              align-items: center;
                              gap: 6px;
                              overflow: hidden;
                            `}
                          >
                            <AvatarGroup
                              urls={
                                collection.image_urls.length > 0
                                  ? collection.image_urls
                                  : collection.product_images || []
                              }
                              size={"24px"}
                              width={"42px"}
                              alt={collection.title}
                            />
                            <h5
                              css={[
                                theme.typography["label-s"],
                                css`
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                  white-space: nowrap;
                                  margin: 0;
                                `,
                              ]}
                            >
                              {collection.title}
                            </h5>
                          </div>
                          <span css={[theme.typography["label-s"]]}>
                            {collection.selected_period_data.clickthrough_rate?.toFixed(
                              0
                            )}
                            %
                          </span>
                        </div>
                      ))}
                </div>
              </div>
            ) : (
              <Select
                onValueChange={(value) => {
                  onSetSelectedCollectionId(value as string);
                }}
                value={selectedCollectionId}
              >
                <SelectTrigger>
                  {selectedCollection?.title || "Select collection"}
                </SelectTrigger>
                <SelectContent>
                  {collectionsData?.map(
                    (collection: LiteDashboardCollectionDto) => (
                      <SelectItem
                        value={collection.collection_id}
                        key={collection.collection_id}
                      >
                        {collection.title}
                      </SelectItem>
                    )
                  )}
                </SelectContent>
              </Select>
            )}
            <div
              css={css`
                flex: 1;
                padding: 16px;
                border-radius: 16px;
                border: 1px solid ${theme.colors.stroke["soft-200"]};
                box-shadow: ${theme.shadows.regular};
                overflow-y: auto;
                height: 100%;
                display: flex;
                flex-direction: column;
              `}
            >
              <div
                css={css`
                  display: flex;
                  justify-content: space-between;
                `}
              >
                <Link
                  to={`/${merchantId}/collections/${selectedCollectionId}`}
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    overflow: hidden;
                  `}
                >
                  <AvatarGroup
                    urls={
                      selectedCollection &&
                      selectedCollection.image_urls.length > 0
                        ? selectedCollection?.image_urls
                        : selectedCollection?.product_images || []
                    }
                    size={"40px"}
                    width={"70px"}
                    alt={selectedCollection?.title}
                  />
                  <h6
                    css={[
                      theme.typography.h6,
                      css`
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        margin: 0;
                      `,
                    ]}
                  >
                    {selectedCollection?.title}
                  </h6>
                </Link>
                <div
                  css={css`
                    display: flex;
                    gap: 8px;
                    align-items: center;
                  `}
                >
                  <TimeFramePicker
                    now={now}
                    period={period}
                    setPeriod={setPeriod}
                  />
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        onClick={handleDownloadCsv}
                        size={"x-small"}
                        variant={"neutral"}
                        _style={"stroke"}
                      >
                        <Export size={20} />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>Export as CSV</TooltipContent>
                  </Tooltip>
                </div>
              </div>
              <ClickthroughRateGauge
                clickthroughRate={
                  selectedCollection?.selected_period_data.clickthrough_rate ||
                  0
                }
                views={selectedCollection?.selected_period_data.views || 0}
                clicks={selectedCollection?.selected_period_data.clicks || 0}
              />
              <div
                css={css`
                  flex: 1;
                  min-height: 300px;
                `}
              >
                {selectedCollectionId && (
                  <ClickthroughRateModalProductTable
                    products={products}
                    isLoading={isLoading}
                  />
                )}
              </div>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
