import { useMutation } from "@tanstack/react-query";
import useAuthorizedApi from "../../helpers/hooks/app/useAuthorizedApi";

export default function useTrackEvent() {
  const { api } = useAuthorizedApi();
  return useMutation({
    mutationFn: ({
      merchantId,
      eventType,
      properties,
    }: {
      merchantId: string;
      eventType: string;
      properties: { [p: string]: string | string[] | null };
    }) =>
      api.POST("/arm", {
        params: {
          query: {
            merchant_id: merchantId,
          },
        },
        body: {
          type: eventType,
          payload: properties,
        },
      }),
  });
}
