import { css, useTheme } from "@emotion/react";
import { Placeholder } from "../Placeholder/Placeholder";

interface TextRowCellProps {
  children: React.ReactNode;
  maxWidth?: string;
  isLoading?: boolean;
  align?: "left" | "center" | "right";
  customCss?: ReturnType<typeof css>;
}

const TextRowCell = (props: TextRowCellProps) => {
  const theme = useTheme();

  const alignToFlex = (align: TextRowCellProps["align"]) => {
    switch (align) {
      case "left":
        return "flex-start";
      case "center":
        return "center";
      case "right":
        return "flex-end";
      default:
        return "flex-end";
    }
  };

  return (
    <div
      css={[
        theme.typography["paragraph-s"],
        css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: ${alignToFlex(props.align)};
          gap: 2px;
          flex: 1 0 0;
          width: 100%;
        `,
        props.customCss,
      ]}
    >
      {props.isLoading ? (
        <Placeholder height="16px" width="100%" />
      ) : (
        <span
          css={[
            css`
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: ${props.maxWidth || "100%"};
            `,
          ]}
        >
          {props.children}
        </span>
      )}
    </div>
  );
};

export default TextRowCell;
