import { css } from "@emotion/react";
import React from "react";
import { Period } from "../../Dashboard/util";
import { FeatureSteps } from "./FeatureSteps";
import { DatePickerAndTitle } from "./DatePickerAndTitle";
import useFeatureActivationStatus from "../../Dashboard/useFeatureActivationStatus";
import { StatisticButtons } from "./StatisticButtons";
import useMerchant from "../../../../helpers/hooks/app/useMerchant";
import { components } from "../../../../api/generated/openapi";
import { useCachedState } from "../../../../helpers/hooks/useCachedState";

export function CollectionsHeader({
  period,
  setPeriod,
  collectionsData,
  collectionsDataLoading,
}: {
  period: Period;
  setPeriod: React.Dispatch<React.SetStateAction<Period>>;
  collectionsData:
    | components["schemas"]["LiteDashboardCollectionDto"][]
    | undefined;
  collectionsDataLoading: boolean;
}) {
  const featureActivationStatusQuery = useFeatureActivationStatus();
  const { merchant } = useMerchant();
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const installTimestamp = merchant?.start_timestamp ?? 0;
  const installedMoreThanADay = installTimestamp < currentTimestamp - 86400;
  const [cancelledSteps, setCancelledSteps] = useCachedState<string[]>(
    "collections_header_cancelled_steps",
    []
  );

  return (
    <>
      <div
        className="collections-header"
        css={css`
          display: flex;
          padding-bottom: 16px;
          flex-direction: column;
          justify-content: center;
          align-items: stretch;
          gap: 16px;
          align-self: stretch;
          z-index: 11; // So shadow goes above grid below
        `}
      >
        <DatePickerAndTitle setPeriod={setPeriod} period={period} />
        <FeatureSteps
          featureActivationStatusQuery={featureActivationStatusQuery}
          cancelledSteps={cancelledSteps}
          setCancelledSteps={setCancelledSteps}
        />
        {installedMoreThanADay && (
          <StatisticButtons
            fromDate={period.fromDate}
            toDate={period.toDate}
            collectionsData={collectionsData}
            collectionsDataLoading={collectionsDataLoading}
          />
        )}
      </div>
    </>
  );
}
