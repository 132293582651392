import React, {
  createContext,
  CSSProperties,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDragAndDropContext } from "./DragAndDropProvider";
import {
  getOriginalProductIdForDuplicateProduct,
  getProductIdForDuplicateProduct,
} from "../ProductHighlight/productDuplicateHelpers";
import { ProductDuplicate } from "../ProductHighlight/useProductDuplicates";
import {
  getIsNonIosMobile,
  listenToPointerOrTouchEvent,
  PointerEventLike,
} from "./events";
import { openOptionsClassName } from "../../../../alignUI/ProductCard/ProductCard";
import { DNDOpacityProvider } from "./DNDOpacityProvider";
import { useIsDarkMode } from "../../../../helpers/hooks/app/useIsDarkMode";
import { dlog } from "@depict-ai/utilishared/latest";
import { useMobileSafariBugWorkaround } from "./useMobileSafariBugWorkaround";

export type DraggableInfo = {
  isContent: boolean;
  id: string;
  slotsHeight: number;
  slotsWidth: number;
} & (
  | {
      isCloneInCardStack: false;
      allSelectableItemIds: string[];
    }
  | {
      isCloneInCardStack: true;
      allSelectableItemIds?: never;
    }
);

const draggableWrapperClass = "draggable-wrapper";
const DraggableWrapperContext = createContext<DraggableInfo | undefined>(
  undefined
);

export const useIsCloneInCardStack = () =>
  useContext(DraggableWrapperContext)?.isCloneInCardStack;

export function DraggableWrapperProvider({
  children,
  ...info
}: PropsWithChildren<DraggableInfo>) {
  return (
    <DraggableWrapperContext.Provider value={info}>
      {children}
    </DraggableWrapperContext.Provider>
  );
}

/**
 * Creates the outermost element around a draggable item (product or content block). This component is responsible for handling the lifecycle from drag start event to dropping, via handling events and updating the state.
 * Also puts framer motion stuff on the elements for the re-shuffle animations.
 */
export function DraggableWrapper({
  children,
  style,
  className,
}: PropsWithChildren<{
  style?: CSSProperties;
  className?: string;
}>) {
  const info = useContext(DraggableWrapperContext);
  if (!info) {
    throw new Error(
      "Only use DraggableWrapperContext within a <DraggableWrapperProvider> - check DragAndDropGrid.tsx that it wraps correctly when rendering"
    );
  }
  let { selectedItems, isDragging } = useDragAndDropContext();
  const {
    id,
    isContent,
    allSelectableItemIds,
    isCloneInCardStack,
    slotsHeight,
    slotsWidth: unclampedSlotsWidth,
  } = info;
  const {
    setSelectedItems: actualSetSelectedItems,
    setIsDragging: actualSetIsDragging,
    refState,
    gridGapPx,
    productCardHeight,
    selectingDisabled,
    productDuplicates,
    dndDisabled,
    numberOfColumns,
    shouldShowPinHints,
  } = useDragAndDropContext();
  const slotsWidth = Math.min(unclampedSlotsWidth, numberOfColumns);
  const setSelectedItems = (
    newSetOrFunction: Set<string> | ((prev: Set<string>) => Set<string>)
  ) => {
    if (selectingDisabled) return;
    actualSetSelectedItems((prev) => {
      const newSet =
        typeof newSetOrFunction === "function"
          ? newSetOrFunction(prev)
          : newSetOrFunction;
      // Update selectedItems in our scope, so we have the latest source of truth. This is needed for when one starts dragging a product that wasn't selected before.
      selectedItems = newSet;
      return newSet;
    });
  };
  const setIsDragging = (newSet: Set<string>) =>
    actualSetIsDragging(() => {
      // Update isDragging in our scope, so we always have the latest source of truth.
      isDragging = newSet;
      return newSet;
    });
  const currentRefState = refState.current;
  const isWide = isContent && slotsWidth > slotsHeight;
  const isTall = isContent && slotsWidth < slotsHeight;
  const draggableWrapperRef = useRef<HTMLDivElement | null>(null);
  const [zonesWantHalfOpacity, setZonesWantHalfOpacity] =
    useState<boolean>(false);
  // Only fade cards if we're dragging
  const halfOpacity =
    isDragging.size && !dndDisabled ? zonesWantHalfOpacity : false;

  useMobileSafariBugWorkaround(draggableWrapperRef);

  useEffect(() => {
    refState.current.mountedGridItems.add(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => void refState.current.mountedGridItems.delete(id);
  }, [id, refState]);

  useEffect(() => {
    const div = draggableWrapperRef.current;
    if (!div) return;
    const pointerDownListener = async ({
      pointerId,
      currentTarget,
      shiftKey,
      ctrlKey,
      metaKey,
      clientY,
      clientX,
      target,
      pageY,
      pageX,
      pointerType,
      button,
      wasTouchEvent,
    }: PointerEventLike) => {
      if (
        button !== 0 ||
        productCardHeight == undefined ||
        // Don't select card when opening options on product card
        (target instanceof Element &&
          (target.matches("." + openOptionsClassName) ||
            target.closest("." + openOptionsClassName)))
      ) {
        return;
      }
      const isDesktop = pointerType === "mouse";
      dlog("Pointer down listener, isDesktop", isDesktop);
      let setSelectionOnPointerUpOrDragStart: VoidFunction | undefined;
      let callWhenNoDragHappened: VoidFunction | undefined;

      // If a second finger goes down, ignore it (it's the scroll finger)
      if (isCloneInCardStack || currentRefState.dragPointerId) return;
      // All JS code to handle a drag from start to end originates from this event handler
      const wasSelectedBefore = selectedItems.has(id);
      const cmdPressed = ctrlKey || metaKey;
      if (shiftKey && selectedItems.size) {
        // Range select
        let lastSelectedItem = 0;
        for (const selectedId of selectedItems) {
          const currentId = allSelectableItemIds.indexOf(selectedId);
          if (currentId > lastSelectedItem) {
            lastSelectedItem = currentId;
          }
        }

        const currentId = allSelectableItemIds.indexOf(id);
        if (currentId === -1) return;
        const newSet = new Set(selectedItems);
        const start = Math.min(currentId, lastSelectedItem);
        const end = Math.max(currentId, lastSelectedItem);
        for (let i = start; i <= end; i++) {
          newSet.add(allSelectableItemIds[i]);
        }
        const newSetWithDuplicates = new Set(newSet);
        newSet.forEach((selectedItem) => {
          handleSelectProductDuplicate(
            productDuplicates,
            selectedItem,
            newSetWithDuplicates
          );
        });
        setSelectedItems(newSetWithDuplicates);
      } else {
        // Multi-select
        const changeSelection = () =>
          setSelectedItems((prev) => {
            const newSet = new Set(prev);
            if (newSet.has(id)) {
              newSet.delete(id);
            } else {
              newSet.add(id);
            }
            handleSelectProductDuplicate(productDuplicates, id, newSet);
            return newSet;
          });
        if (cmdPressed && wasSelectedBefore) {
          // Copy edge-case handling from apple photos: When an already selected item is cmd-clicked, we drag the whole selection. If no drag occurs, we deselect it on pointer up.
          callWhenNoDragHappened = changeSelection;
        } else if (!cmdPressed) {
          // On mobile, we want the same behavior as when a user cmd+clicks on desktop all the time, since there's no way to multi-select otherwise.
          // However, since we don't know if pointerdown will turn into a scroll or drag, or if the user actually intended to select, we need to defer updating the selection until pointerup.
          setSelectionOnPointerUpOrDragStart = changeSelection;
        } else {
          changeSelection();
        }
      }

      // End code that changes selection, begin code that starts dragging

      const ourElementRect = (
        currentTarget as HTMLDivElement
      ).getBoundingClientRect();
      const oneSlotWidth =
        (ourElementRect.width - gridGapPx * (slotsWidth - 1)) / slotsWidth;
      currentRefState.dragPointerId = pointerId;
      currentRefState.widthOfOneSlotAtDragstart = oneSlotWidth;
      let relativeX = clientX - ourElementRect.left;
      let relativeY = clientY - ourElementRect.top;

      // Adjust click positions in content blocks to the new position in their smaller clones
      if (isTall) {
        const heightCorrection = productCardHeight / ourElementRect.height;
        relativeY *= heightCorrection;
        const widthFactor = slotsWidth / slotsHeight;
        const reducedWidth = ourElementRect.width * widthFactor;
        const clickPercentageInUs = relativeX / ourElementRect.width;
        const gapToTheLeft = (ourElementRect.width - reducedWidth) / 2;
        relativeX = clickPercentageInUs * reducedWidth + gapToTheLeft;
      } else if (isWide) {
        const widthCorrection = oneSlotWidth / ourElementRect.width;
        relativeX *= widthCorrection;
        const heightFactor = slotsHeight / slotsWidth;
        const reducedHeight = ourElementRect.height * heightFactor;
        const clickPercentageInUs = relativeY / ourElementRect.height;
        const gapToTheTop = (ourElementRect.height - reducedHeight) / 2;
        relativeY = clickPercentageInUs * reducedHeight + gapToTheTop;
      } else if (slotsWidth > 1 && slotsHeight > 1) {
        // Larger card, but same aspect ratio as product
        relativeY /= slotsHeight;
        relativeX /= slotsWidth;
      }

      currentRefState.startedDraggingRelativeToElement = {
        x: relativeX,
        y: relativeY,
      };
      const { stopListeningForLongTouch, longPressHappened } =
        blockScrollGestureOnMobileIfNeeded(pointerId);
      const { stopListeningForMovingABit, pointerMovedABit } =
        waitForPointerMovedABit(pointerId, pageX, pageY);
      // Listen for pointer up or cancel anywhere, to stop dragging or stop waiting for long press/pointer moving a bit
      const cleanupPotentialDrag = () => {
        dlog("Cleaning up drag");
        removePointerUpListener();
        removePointerCancelListener();
        window.removeEventListener("keydown", keyDownListener);
        stopListeningForMovingABit();
        stopListeningForLongTouch();
        currentRefState.dragPointerId = undefined;
        currentRefState.widthOfOneSlotAtDragstart = undefined;
        currentRefState.startedDraggingRelativeToElement = undefined;
      };
      const pointerCancelListener = (e: PointerEventLike) => {
        if (e.pointerId !== pointerId) return;
        setIsDragging(new Set());
        cleanupPotentialDrag();
      };
      const pointerUpListener = (e: PointerEventLike) => {
        if (e.pointerId !== pointerId) return;
        const droppedAt = currentRefState.hoveringOn;
        if (droppedAt) {
          currentRefState.onDrop(isDragging, droppedAt);
          // On mobile we have to deselect the items after a drag since clicking always adds to the selection and doesn't create a new one
          // On desktop too, see https://depictaiworkspace.slack.com/archives/C07KJENQ3NK/p1725552278152769?thread_ts=1725551050.415079&cid=C07KJENQ3NK
          setSelectedItems(new Set());
        }

        setSelectionOnPointerUpOrDragStart?.();
        setIsDragging(new Set());
        cleanupPotentialDrag();
      };
      const keyDownListener = (e: KeyboardEvent) => {
        // Pressing escape cancels dragging
        if (e.key === "Escape") {
          setIsDragging(new Set());
          cleanupPotentialDrag();
        }
      };
      window.addEventListener("keydown", keyDownListener);
      const removePointerUpListener = listenToPointerOrTouchEvent(
        window,
        "pointerup",
        pointerUpListener
      );
      const removePointerCancelListener = listenToPointerOrTouchEvent(
        window,
        "pointercancel",
        pointerCancelListener
      );

      if (isDesktop) {
        // For mouse, only start dragging if it moves a couple px after the pointerdown event
        const whatHappened = await pointerMovedABit;
        if (whatHappened !== "moved-a-bit") {
          callWhenNoDragHappened?.();
          return;
        }
      } else {
        // For touch, start dragging after long-press
        const whatHappened = await longPressHappened;
        if (whatHappened !== "long-pressed") {
          callWhenNoDragHappened?.();
          return;
        }

        dlog("Long press happened");
      }
      // Start drag

      // When starting drag, no longer do a delayed deselection
      if (!selectedItems.has(id)) {
        // Unless the current item isn't selected, in which case we select it
        dlog("Calling", setSelectionOnPointerUpOrDragStart);
        setSelectionOnPointerUpOrDragStart?.();
      }
      setSelectionOnPointerUpOrDragStart = undefined;

      if (!wasTouchEvent) {
        // Release implicit pointer capture so that the DragAndDropZones get pointerenter and pointerleave events, see https://w3c.github.io/pointerevents/#implicit-pointer-capture and https://stackoverflow.com/a/70976017
        (target as HTMLElement).releasePointerCapture(pointerId);
        dlog("Releasing pointer capture", target);
      }

      // Emulate apples' photos app in an edge-case, to avoid thinking about it a lot: when having cmd pressed after a multi selection and clicking on a deselected item, we start dragging only that item
      if ((ctrlKey || metaKey) && !wasSelectedBefore) {
        setIsDragging(new Set([id]));
      } else {
        // Normally start dragging with the selected items
        setIsDragging(selectedItems);
      }
    };
    return listenToPointerOrTouchEvent(div, "pointerdown", pointerDownListener);
  }, [
    allSelectableItemIds,
    currentRefState,
    gridGapPx,
    id,
    isCloneInCardStack,
    isDragging,
    isTall,
    isWide,
    productCardHeight,
    productDuplicates,
    selectedItems,
    setIsDragging,
    setSelectedItems,
    slotsHeight,
    slotsWidth,
  ]);

  const isDark = useIsDarkMode();

  let unpinnedOpacityStyle: CSSProperties | undefined;

  if (!isCloneInCardStack) {
    unpinnedOpacityStyle = {
      ...(shouldShowPinHints
        ? {
            // This looks worse than opacity because it increases contrast, but it's a good enough quick fix for an edge case: when showing the pin hint zone and having a content block already added one shouldn't see the products behind the content block
            filter: isDark
              ? `contrast(${halfOpacity ? 70 : 100}%) brightness(${halfOpacity ? 40 : 100}%)`
              : `contrast(${halfOpacity ? 30 : 100}%) brightness(${halfOpacity ? 150 : 100}%)`,
          }
        : { opacity: halfOpacity ? 0.5 : 1 }),
    };
  }

  return (
    <DNDOpacityProvider setHalfOpacity={setZonesWantHalfOpacity}>
      <div
        ref={draggableWrapperRef}
        data-item-id={isCloneInCardStack ? undefined : id}
        draggable={false}
        className={
          draggableWrapperClass +
          (isContent ? ` content` : "") +
          (selectedItems.has(id) ? ` selected` : "") +
          (isCloneInCardStack ? ` clone` : "") +
          (isWide ? ` wide` : "") +
          (isTall ? ` tall` : "") +
          (className ? ` ${className}` : "")
        }
        style={{
          ...style,
          ["--slots-height" as string]: slotsHeight,
          ["--slots-width" as string]: slotsWidth,
          filter: `contrast(100%) brightness(100%)`,
          ...unpinnedOpacityStyle,
        }}
      >
        {children}
      </div>
    </DNDOpacityProvider>
  );
}

function blockScrollGestureOnMobileIfNeeded(forPointerId: number) {
  let cleanup: VoidFunction | undefined;
  let setLongPressHappened: (
    value:
      | "long-pressed"
      | "cleaned-up"
      | PromiseLike<"long-pressed" | "cleaned-up">
  ) => void;
  const longPressHappened = new Promise<"long-pressed" | "cleaned-up">(
    (resolve) => (setLongPressHappened = resolve)
  );
  let pointerIsIn: Element | undefined;

  const timeout = setTimeout(() => {
    const handler = (e: TouchEvent) => {
      dlog("Got touch events", e);
      for (let i = 0; i < e.changedTouches.length; i++) {
        const { identifier, clientX, clientY } = e.changedTouches[i];
        // Only block scroll for the pointer that started the drag, so one can still scroll with other fingers
        if (identifier === forPointerId) {
          dlog("Preventing default on", e);
          try {
            e.preventDefault();
          } catch (e) {
            dlog("Failed to prevent default", e);
          }
          // Chrome mobile special case
          if (!getIsNonIosMobile()) return;
          // target in the move event is fucked, doesn't say what's under the finger.
          const target = document.elementFromPoint(clientX, clientY);
          if (!target || pointerIsIn === target) return;
          if (pointerIsIn) {
            pointerIsIn.dispatchEvent(
              new PointerEvent("pointerleave", {
                pointerId: forPointerId,
              })
            );
          }
          pointerIsIn = target;
          target.dispatchEvent(
            new PointerEvent("pointerenter", {
              pointerId: forPointerId,
            })
          );
        } else {
          dlog(
            "Not blocking scroll",
            e,
            "because",
            identifier,
            "is not",
            forPointerId
          );
        }
      }
    };
    // iOS safari doesn't respect preventDefault for pointermove and we don't want to do it on desktop anyway, so we use touchmove. Luckily the identifiers are the same across pointer and touch events.
    dlog("Added non-passive touch listener");
    window.addEventListener("touchmove", handler, { passive: false });
    setLongPressHappened("long-pressed");
    cleanup = () => {
      dlog("Removed touch listener");
      window.removeEventListener("touchmove", handler);
      if (getIsNonIosMobile()) {
        pointerIsIn?.dispatchEvent(
          new PointerEvent("pointerleave", {
            pointerId: forPointerId,
          })
        );
      }
    };
  }, 200);

  return {
    longPressHappened,
    stopListeningForLongTouch: () => {
      clearTimeout(timeout);
      cleanup?.();
      setLongPressHappened("cleaned-up");
    },
  };
}

function waitForPointerMovedABit(
  forPointerId: number,
  startPageX: number,
  startPageY: number
) {
  let setMovedABit: (
    value:
      | "moved-a-bit"
      | "cleaned-up"
      | PromiseLike<"moved-a-bit" | "cleaned-up">
  ) => void;
  const pointerMovedABit = new Promise<"moved-a-bit" | "cleaned-up">(
    (resolve) => (setMovedABit = resolve)
  );

  const handler = (e: PointerEventLike) => {
    if (e.pointerId !== forPointerId) return;
    const distance = Math.sqrt(
      // ChatGPT invented this, I didn't try to understand it but it seems to do the right thing
      Math.pow(e.pageX - startPageX, 2) + Math.pow(e.pageY - startPageY, 2)
    );
    if (distance > 6) {
      setMovedABit("moved-a-bit");
      removeListener();
    }
  };
  const removeListener = listenToPointerOrTouchEvent(
    window,
    "pointermove",
    handler
  );

  return {
    pointerMovedABit,
    stopListeningForMovingABit: () => {
      removeListener();
      setMovedABit("cleaned-up");
    },
  };
}

/**
 * Helper function to be used such that when a product is selected, its duplicate is also selected or deselected
 */
function handleSelectProductDuplicate(
  productDuplicates: ProductDuplicate[],
  selectedItem: string,
  selectedItemsSet: Set<string>
) {
  const productIdsToDuplicate = productDuplicates.map(
    (productDuplicate) => productDuplicate.productId
  );
  const productIdOfOriginal =
    getOriginalProductIdForDuplicateProduct(selectedItem);
  const selectedItemHasOrIsADuplicate =
    productIdsToDuplicate.includes(productIdOfOriginal);

  if (selectedItemHasOrIsADuplicate) {
    const isSelecting = selectedItemsSet.has(selectedItem);
    const verb = isSelecting ? "add" : "delete";
    selectedItemsSet[verb](productIdOfOriginal);
    selectedItemsSet[verb](
      getProductIdForDuplicateProduct(productIdOfOriginal)
    );
  }
}
