import React from "react";
import { css, Interpolation, Theme, useTheme } from "@emotion/react";
import useIsMobile from "../../../../helpers/hooks/useIsMobile";

export function StepContainer({
  children,
  extraCss,
  containerCss,
  desktopCards,
}: {
  children: React.ReactNode;
  extraCss?: Interpolation<Theme>;
  containerCss?: Interpolation<Theme>;
  desktopCards: number;
}) {
  const isMobile = useIsMobile();

  return (
    <div css={containerCss}>
      <section
        css={[
          css`
            --gap: 24px;
            display: flex;
            gap: var(--gap);
            align-self: stretch;

            min-height: 0;
            overflow-x: scroll;
            align-items: stretch;
            scroll-snap-type: x mandatory;

            --flex-grow: 1;

            & > * {
              scroll-snap-align: start;
              flex: var(--flex-grow) 0
                ${isMobile
                  ? "75%"
                  : `calc(calc(100% / ${desktopCards}) - calc(calc(var(--gap) * ${desktopCards - 1}) / ${desktopCards}))`};
            }

            &:not(:has(:nth-child(${desktopCards}n))) {
              --flex-grow: 0;
            }

            // Hide scrollbar
            scrollbar-width: none;
            &::-webkit-scrollbar {
              display: none;
            }

            &:empty {
              display: none;
            }
          `,
          extraCss,
        ]}
      >
        {children}
      </section>
    </div>
  );
}

export function StepCard({
  extraCss,
  children,
  onClick,
}: {
  extraCss?: Interpolation<Theme>;
  children: React.ReactNode;
  onClick: VoidFunction;
}) {
  const theme = useTheme();

  return (
    <button
      onClick={onClick}
      className="step-card"
      css={[
        css`
          display: flex;
          justify-content: flex-start;
          white-space: nowrap;
          cursor: pointer;
          border: 1px solid ${theme.colors.stroke["soft-200"]};
          background: ${theme.colors.bg["white-0"]};
          border-radius: 16px;
          padding: 8px;
          align-items: center;

          @media (hover: hover) {
            &:hover {
              background: ${theme.colors.bg["weak-50"]};
            }
          }
        `,
        extraCss,
      ]}
    >
      {children}
    </button>
  );
}
