export const hardcodedExampleProduct = {
  main_product_id: "9683188154642",
  title: "The Collection Snowboard: Hydrogen",
  handle: "the-collection-snowboard-hydrogen",
  image_urls: [
    "https://cdn.shopify.com/s/files/1/0886/5044/9170/files/Main_0a40b01b-5021-48c1-80d1-aa8ab4876d3d.jpg?v=1720617780",
  ],
  images: [
    {
      id: "gid://shopify/MediaImage/40214546546962",
      url: "https://cdn.shopify.com/s/files/1/0886/5044/9170/files/Main_0a40b01b-5021-48c1-80d1-aa8ab4876d3d.jpg?v=1720617780",
    },
  ],
  tags: ["Accessory", "Sport", "Winter"],
  price: 600,
  in_stock: true,
  quantity: 50,
  badges: [{ value: 0, n_days: 30, type: "bestseller" }],
  n_sold_current_period: 0,
  clicks_current_period: 0,
  collection_ids: ["482606154002", "482606186770", "482607464722"],
  skus: [],
  connected_shop_ids: [],
  created_at: "2024-07-10T13:23:00Z",
  published_at: "2024-07-10T13:23:04Z",
  sort_index: 0,
  applied_boost_bury_rule: null,
  hasDuplicate: false,
  hasImageSettings: false,
};
