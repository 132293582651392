// noinspection CssUnresolvedCustomProperty

import { breakpoints } from "../../../theme";
import { css, useTheme } from "@emotion/react";
import React, {
  createContext,
  forwardRef,
  PropsWithChildren,
  useContext,
  useState,
} from "react";
import { useDragAndDropContext } from "./DragAndDrop/DragAndDropProvider";

export const CollectionHeaderWrapper = forwardRef<
  HTMLDivElement,
  PropsWithChildren<Record<never, never>>
>(({ children }, ref) => {
  const theme = useTheme();
  const { shouldShowPinHints } = useDragAndDropContext();
  const { disallowScrollHint } = useDisallowScrollHint();
  const showAnimation = shouldShowPinHints && !disallowScrollHint;

  return (
    <div
      ref={ref}
      css={css`
        position: sticky;
        top: -12px; // When sticking, remove 12px from the 16px padding, leaving 4px
        z-index: calc(var(--dnd-base-z-index, 0) + 1);
        ${showAnimation
          ? `animation: scrollUpHint 1.5s ease-in-out infinite;`
          : ``}

        --box-shadow-1: 0px 4px 4px -4px rgb(from ${theme.colors.primary.base} r
                g b / 36%),
          0px 16px 32px -12px rgb(from ${theme.colors.primary
          .base} r g b / 16%);
        --box-shadow-2: 0px 8px 12px -8px rgb(from ${theme.colors.primary.base}
                r g b / 69%),
          0px 28px 48px -12px rgb(from ${theme.colors.primary.base} r g b / 63%);
        --ear-size: 32px;

        @keyframes scrollUpHint {
          0% {
            box-shadow: var(--box-shadow-1);
          }
          50% {
            box-shadow: var(--box-shadow-2);
          }
          100% {
            box-shadow: var(--box-shadow-1);
          }
        }

        @media (min-width: ${breakpoints.sm}px) {
          &::before,
          &::after {
            content: "";
            position: absolute;
            top: 0;
            height: 100%;
            width: var(--ear-size);
            background: ${theme.colors.bg["white-0"]};
          }

          &::before {
            left: calc(-1 * var(--ear-size));
          }

          &::after {
            right: calc(-1 * var(--ear-size));
          }
        }
      `}
    >
      {children}
    </div>
  );
});

const DisallowScrollHintContext = createContext<
  | undefined
  | {
      disallowScrollHint: boolean;
      setDisallowScrollHint: React.Dispatch<React.SetStateAction<boolean>>;
    }
>(undefined);

export function DisallowScrollHintProvider({
  children,
}: PropsWithChildren<Record<never, never>>) {
  const [disallowScrollHint, setDisallowScrollHint] = useState(false);

  return (
    <DisallowScrollHintContext.Provider
      value={{ disallowScrollHint, setDisallowScrollHint }}
    >
      {children}
    </DisallowScrollHintContext.Provider>
  );
}

export function useDisallowScrollHint() {
  const contextValue = useContext(DisallowScrollHintContext);
  if (!contextValue) {
    throw new Error(
      "Please call useDisallowScrollHint within a DisallowScrollHintProvider"
    );
  }
  return contextValue;
}
