import { PointerEvent as ReactPointerEvent } from "react";
import { dlog } from "@depict-ai/utilishared/latest";

export type PointerEventLike = {
  pointerId: number;
  currentTarget: HTMLElement;
  shiftKey: boolean;
  ctrlKey: boolean;
  metaKey: boolean;
  clientY: number;
  clientX: number;
  target: EventTarget;
  pageY: number;
  pageX: number;
  pointerType: "touch" | "mouse" | "pen";
  button: number;
  wasTouchEvent?: boolean;
};

// Chrome mobile (or probably any non apple webkit mobile) has some bad behavior that breaks our assumptions
// 1. pointer events and touch events don't have the same identifiers, even if the physical touch is the same
// 2. When the page is scrolled by useAutoScroll, chrome seems to get confused that the scrolling might have been caused by a pointer/finger and sends a pointercancel event. This is not the case with touch events.
let isNonIosMobile = false;
const runDetection = (e: PointerEvent) => {
  isNonIosMobile = // Touch device
    e.pointerType === "touch" &&
    // Non-webkit
    typeof (window as any).webkitConvertPointFromNodeToPage !== "function";
};
export const getIsNonIosMobile = () => isNonIosMobile;

const pointerToTouchEvent = {
  pointerdown: "touchstart",
  pointermove: "touchmove",
  pointerup: "touchend",
  pointercancel: "touchcancel",
} as const;

/**
 * Adds the handler as a pointer event listener. But if one is running chrome mobile, uses touch events instead and converts them to something like a PointerEvent.
 * This is because chrome mobile dispatches "pointercancel" events on pointerevents if the finger moves from a card we want to drag more than 100px or so, it doesn't for touch events.
 */
export function listenToPointerOrTouchEvent(
  target: Window | HTMLElement,
  event: "pointermove" | "pointerdown" | "pointerup" | "pointercancel",
  handler: (e: PointerEventLike) => any
) {
  const touchHandler = (originalEvent: TouchEvent) => {
    if (!isNonIosMobile) return;
    dlog("Fallback touch handler added");
    const { changedTouches, currentTarget, shiftKey, ctrlKey, metaKey } =
      originalEvent;

    for (let i = 0; i < changedTouches.length; i++) {
      const touch = changedTouches[i];
      handler({
        pointerId: touch.identifier,
        currentTarget: currentTarget as HTMLDivElement,
        shiftKey,
        ctrlKey,
        metaKey,
        clientY: touch.clientY,
        clientX: touch.clientX,
        target: touch.target,
        pageY: touch.pageY,
        pageX: touch.pageX,
        pointerType: "touch",
        button: 0,
        wasTouchEvent: true,
      });
    }
  };
  const pointerHandler = (e: PointerEvent) => {
    runDetection(e);
    if (isNonIosMobile) return;
    handler(e as unknown as ReactPointerEvent<HTMLElement>);
  };
  const equivalentTouchEvent = pointerToTouchEvent[event];
  (target as Window).addEventListener(event, pointerHandler);
  (target as Window).addEventListener(equivalentTouchEvent, touchHandler, {
    passive: true,
  });

  return () => {
    (target as Window).removeEventListener(event, pointerHandler);
    (target as Window).removeEventListener(equivalentTouchEvent, touchHandler);
  };
}
