import React from "react";
import { LiteCollectionProductDto } from "../../../../api/types";
import ProductCard from "../../../../alignUI/ProductCard/ProductCard";
import { useDragAndDropContext } from "./DragAndDropProvider";
import { DraggableWrapperProvider } from "./DraggableWrapper";

import { dragToSelectAndDeselectClass } from "./useDragToSelect";
import { DNDPinIndicatorZone } from "./DNDPinIndicatorZone";

export const virtualRowClass = "virtual-row";

/**
 * Renders one row of products or content blocks. Takes care of overflowing the row for content blocks and putting blank spaces in the next row for the content blocks to "fill over", as well as setting z-index as necessary for  that layering.
 */
export function VirtualRow({
  rowStart,
  rowIndex,
  numberOfColumns,
  numberOfRows,
  getProductCardProps,
  productCardHeight,
  blockIdToStartRow,
  gapPx,
}: {
  rowStart: number;
  rowIndex: number;
  numberOfColumns: number;
  numberOfRows: number;
  productCardHeight: number;
  blockIdToStartRow: Record<string, number>;
  gapPx: number;
  getProductCardProps: (
    product: LiteCollectionProductDto
  ) => Omit<Parameters<typeof ProductCard>[0], "product">;
}) {
  const {
    allSelectableItemIds,
    shouldShowPinHints,
    productsToRender: productsToRenderNotAffectedDuringDrag,
    repositionedBlocksWithRender:
      repositionedBlocksWithRenderNotAffectedDuringDrag,
    occupiedByBlocksOrProducts: occupiedByBlocksOrProductsNotAffectedDuringDrag,
    gridStateWhileDragging,
  } = useDragAndDropContext();

  const productsToRender =
    gridStateWhileDragging?.productsToRender ||
    productsToRenderNotAffectedDuringDrag;
  const repositionedBlocksWithRender =
    gridStateWhileDragging?.repositionedBlocksWithRender ||
    repositionedBlocksWithRenderNotAffectedDuringDrag;
  const occupiedByBlocksOrProducts =
    gridStateWhileDragging?.occupiedByBlocksOrProducts ||
    occupiedByBlocksOrProductsNotAffectedDuringDrag;

  const blockPositionsById = Object.fromEntries(
    repositionedBlocksWithRender.map((entry) => [
      entry.block.DOMElementId,
      entry,
    ])
  );

  const contentsOfThisRow = occupiedByBlocksOrProducts.slice(
    rowIndex * numberOfColumns,
    rowIndex * numberOfColumns + numberOfColumns
  );

  return (
    <div
      key={rowIndex}
      className={`${virtualRowClass} ${dragToSelectAndDeselectClass}`}
      style={{
        position: "absolute",
        top: `${rowStart}px`,
        ["--z-index" as string]: numberOfRows - rowIndex,
      }}
    >
      {rowIndex === 0 && shouldShowPinHints && <DNDPinIndicatorZone />}
      {contentsOfThisRow.map((blockIdOrProductIndex, indexInRow) => {
        const indexInSlots = rowIndex * numberOfColumns + indexInRow;

        if (typeof blockIdOrProductIndex === "number") {
          // Content is product index, render the product at that index or an empty space
          const product = productsToRender[blockIdOrProductIndex];
          if (product) {
            return (
              <DraggableWrapperProvider
                allSelectableItemIds={allSelectableItemIds}
                isContent={false}
                id={product.main_product_id}
                key={product.main_product_id}
                isCloneInCardStack={false}
                slotsWidth={1}
                slotsHeight={1}
              >
                <ProductCard
                  product={product}
                  {...getProductCardProps(product)}
                />
              </DraggableWrapperProvider>
            );
          }
          return <Empty height={productCardHeight} key={indexInRow} />;
        } else if (typeof blockIdOrProductIndex === "string") {
          // It's a content block!
          const blockData = blockPositionsById[blockIdOrProductIndex];
          const onlyBlocksInThisRow = contentsOfThisRow.every(
            (content) => typeof content === "string"
          );

          if (blockData.finalBlockIndex === indexInSlots) {
            // 1. If the block starts at this index, render it here
            const extraRows = blockData.block.span_rows - 1;
            const marginBottom =
              -1 * (productCardHeight * extraRows + gapPx * extraRows);
            const minHeight =
              productCardHeight * (extraRows + 1) + gapPx * extraRows;

            return (
              <DraggableWrapperProvider
                isContent={true}
                id={blockData.block.DOMElementId}
                allSelectableItemIds={allSelectableItemIds}
                key={blockData.block.DOMElementId}
                isCloneInCardStack={false}
                slotsWidth={blockData.block.span_columns}
                slotsHeight={blockData.block.span_rows}
              >
                {blockData.renderBlock({
                  // When we only have blocks, we can make the whole row the height of x product cards + gaps
                  // When there are products too, overflow the blocks to the next row where we have gaps
                  marginBottom: marginBottom + "px",
                  minHeight: onlyBlocksInThisRow ? minHeight + "px" : undefined,
                })}
              </DraggableWrapperProvider>
            );
          }
          const blockStartsAt = blockIdToStartRow[blockIdOrProductIndex];
          if (blockStartsAt === rowIndex) {
            // 2. If the block starts on the same row, do nothing
            return;
          }
          // 3. If the block starts on a row above, render an empty space
          return <Empty height={productCardHeight} key={indexInRow} />;
        } else {
          // Empty slot in the grid
          return <Empty height={productCardHeight} key={indexInRow} />;
        }
      })}
    </div>
  );
}

function Empty({ height }: { height: number }) {
  return <div className="dnd-empty" style={{ height: height + "px" }}></div>;
}
