import ProductCard from "../../../../alignUI/ProductCard/ProductCard";
import type { LiteCollectionProductDto } from "../../../../api/types";
import { DraggableWrapperProvider } from "./DraggableWrapper";
import { css } from "@emotion/react";
import { MutableRefObject, startTransition, useEffect, useRef } from "react";
import { useDragAndDropContext } from "./DragAndDropProvider";
import { Record } from "@bloomberg/record-tuple-polyfill";
import { hardcodedExampleProduct } from "./hardcodedExampleProduct";

/**
 * All product cards in the DragAndDropGrid are constrained to a certain height. This is to make the virtualization simpler and the overflowing content block implementation possible.
 * However, we still want the product cards to respond to different screen sizes like they would if they weren't constrained to a height but instead just to a width due to the numbers of columns.
 * This renders one single product card (the first product) with a "free height" in a hidden div. Subsequently, its resulting height is measured and the rest of the grid rendered with that height.
 */
export function ProductCardDimensionMeasurer({
  products,
  dndGridRef,
  getProductCardProps,
}: {
  products: LiteCollectionProductDto[];
  dndGridRef: MutableRefObject<HTMLDivElement | null>;
  getProductCardProps: (
    product: LiteCollectionProductDto
  ) => Omit<Parameters<typeof ProductCard>[0], "product">;
}) {
  const gridContainerWidth = useRef<number | null>(null);
  const wrapperDiv = useRef<HTMLDivElement | null>(null);
  const productToMeasure = products[0] || hardcodedExampleProduct;
  const { gridGapPx, numberOfColumns, setProductCardDimensions } =
    useDragAndDropContext();

  useEffect(() => {
    if (!dndGridRef.current) return;
    const resizeObserver = new ResizeObserver((records) => {
      const { width } = records.at(-1)!.contentRect;
      gridContainerWidth.current = width;
      wrapperDiv.current?.style.setProperty("--container-width", `${width}px`);
    });
    resizeObserver.observe(dndGridRef.current);
    return () => resizeObserver.disconnect();
  }, [dndGridRef]);

  useEffect(() => {
    if (!wrapperDiv.current) return;
    const resizeObserver = new ResizeObserver((records) => {
      const { width, height } = records.at(-1)!.contentRect;
      startTransition(() =>
        setProductCardDimensions(Record({ width, height }))
      );
    });
    resizeObserver.observe(wrapperDiv.current);
    return () => resizeObserver.disconnect();
  }, [setProductCardDimensions]);

  return (
    <div
      ref={wrapperDiv}
      css={css`
        position: absolute;
        top: 0;
        left: 0;
        ${gridContainerWidth.current
          ? `--container-width: ${gridContainerWidth.current}px;`
          : ``}
        opacity: 0;
        pointer-events: none;
        width: calc(
          calc(var(--container-width) - ${(numberOfColumns - 1) * gridGapPx}px) /
            ${numberOfColumns}
        );
      `}
    >
      <DraggableWrapperProvider
        isContent={false}
        id={productToMeasure.main_product_id}
        slotsHeight={1}
        slotsWidth={1}
        isCloneInCardStack={true}
      >
        <ProductCard
          product={productToMeasure}
          {...getProductCardProps(productToMeasure)}
          growToImageAspectRatio={true}
        />
      </DraggableWrapperProvider>
    </div>
  );
}
