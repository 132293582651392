import * as React from "react";
import * as ModalPrimitive from "@radix-ui/react-dialog";
import { X } from "@phosphor-icons/react";
import { Interpolation, Theme, css, keyframes, useTheme } from "@emotion/react";
import CompactButton from "../CompactButton/CompactButton";
import styled from "@emotion/styled";
import { useIsDarkMode } from "../../helpers/hooks/app/useIsDarkMode";

const fadeIn = keyframes`
    from {
        opacity: 0;
        visibility: hidden;
    }
    to {
        opacity: 1;
        visibility: visible;
    }
`;

const fadeOut = keyframes`
    from {
        opacity: 1;
        visibility: visible;
    }
    to {
        opacity: 0;
        visibility: hidden;
    }
`;

const contentsEnter = keyframes`
    from {
        opacity: 0;
        transform: translateX(-50%) translateY(-50%) scale(0.96);
    }
    to {
        opacity: 1;
        transform: translateX(-50%) translateY(-50%) scale(1);
    }
`;

const contentsExit = keyframes`
    from {
        opacity: 1;
        transform: translateX(-50%) translateY(-50%) scale(1);
    }
    to {
        opacity: 0;
        transform: translateX(-50%) translateY(-50%) scale(0.96);
    }
`;

const Modal = ModalPrimitive.Root;

const ModalTrigger = ModalPrimitive.Trigger;

const ModalPortal = ModalPrimitive.Portal;

const ModalClose = ModalPrimitive.Close;

const ModalOverlay = React.forwardRef<
  React.ElementRef<typeof ModalPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof ModalPrimitive.Overlay>
>(({ className, ...props }, ref) => {
  const isDark = useIsDarkMode();
  return (
    <ModalPrimitive.Overlay
      css={css`
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: calc(var(--dnd-base-z-index, 0) + 50);
        background: rgba(2, 13, 23, ${isDark ? 0.48 : 0.24});
        backdrop-filter: blur(5px);

        &[data-state="open"] {
          animation: ${fadeIn} 150ms ease-out;
        }

        &[data-state="closed"] {
          animation: ${fadeOut} 150ms ease-out;
        }
      `}
      ref={ref}
      {...props}
    />
  );
});
ModalOverlay.displayName = ModalPrimitive.Overlay.displayName;

const ModalContent = React.forwardRef<
  React.ElementRef<typeof ModalPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof ModalPrimitive.Content> & {
    size?:
      | "xsmall"
      | "small"
      | "smallMedium"
      | "medium"
      | "mediumLarge"
      | "large"
      | number;
    fullHeight?: boolean;
    closeButton?: boolean;
    onClose?: () => void;
    customCss?: Interpolation<Theme>;
  }
>(
  (
    {
      className,
      children,
      onClose,
      fullHeight,
      size = "small",
      closeButton = true,
      customCss,
      ...props
    },
    ref
  ) => {
    const theme = useTheme();

    const sizeToMaxWidth = {
      xsmall: "340px",
      small: "648px",
      smallMedium: "734px",
      medium: "856px",
      mediumLarge: "924px",
      large: "1264px",
    };

    return (
      <ModalPortal>
        <ModalOverlay />
        <ModalPrimitive.Content
          ref={ref}
          css={[
            css`
              position: fixed;
              left: 50%;
              top: 50%;
              z-index: calc(var(--dnd-base-z-index, 0) + 50);
              width: 100%;
              transform: translateX(-50%) translateY(-50%);

              max-height: calc(100% - 64px);
              height: ${fullHeight ? "100%" : "auto"};

              @media (max-width: ${theme.breakpoints.sm}px) {
                height: 100%;
                max-height: 100%;
              }

              display: flex;
              flex-direction: column;
              max-width: ${typeof size === "number"
                ? `${size}px`
                : sizeToMaxWidth[size]};
              border: 1px solid ${theme.colors.stroke["soft-200"]};
              background: ${theme.colors.bg["white-0"]};
              box-shadow: 0 16px 32px -12px rgba(14, 18, 27, 0.1);
              border-radius: 20px;

              &[data-state="open"] {
                animation: ${contentsEnter} 200ms ease-out;
              }

              &[data-state="closed"] {
                animation: ${contentsExit} 200ms ease-out;
              }
            `,
            customCss,
          ]}
          {...props}
        >
          {children}
          {closeButton && (
            <ModalPrimitive.Close
              asChild
              css={css`
                position: absolute;
                right: 20px;
                top: 16px;
              `}
            >
              <CompactButton
                _style={"ghost"}
                size={"large"}
                icon={X}
                onClick={onClose}
              />
            </ModalPrimitive.Close>
          )}
        </ModalPrimitive.Content>
      </ModalPortal>
    );
  }
);
ModalContent.displayName = ModalPrimitive.Content.displayName;

const ModalHeader = styled.div`
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.stroke["soft-200"]};
`;

const ModalBody = ({ children }: { children?: React.ReactNode }) => {
  const theme = useTheme();

  return (
    <div
      css={css`
        flex: 1;
        overflow-y: auto;
        padding: 20px;

        @media (max-width: ${theme.breakpoints.sm}px) {
          padding: 16px 11px 11px 16px;
        }
      `}
    >
      {children}
    </div>
  );
};

const ModalSubBody = ({ children }: { children?: React.ReactNode }) => {
  const theme = useTheme();
  return (
    <div
      css={css`
        padding: 0 20px 20px 20px;
      `}
    >
      <div
        css={css`
          padding-top: 20px;
          border-top: 1px solid ${theme.colors.stroke["soft-200"]};
        `}
      >
        {children}
      </div>
    </div>
  );
};

const ModalContentDivider = styled.div(({ theme }) => [
  theme.typography["subheading-xxs"],
  css`
    display: flex;
    align-items: center;
    gap: 10px;
    color: ${theme.colors.text["soft-400"]};

    &::before,
    &::after {
      content: "";
      flex: 1;
      border-bottom: 1px solid ${theme.colors.stroke["soft-200"]};
    }
  `,
]);

const ModalFooter = ({ ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const theme = useTheme();

  return (
    <div
      css={css`
        border-top: 1px solid ${theme.colors.stroke["soft-200"]};
        padding: 16px 20px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 12px;
      `}
      {...props}
    />
  );
};
ModalFooter.displayName = "DialogFooter";

const ModalTitle = React.forwardRef<
  React.ElementRef<typeof ModalPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof ModalPrimitive.Title>
>(({ className, ...props }, ref) => {
  const theme = useTheme();
  return (
    <ModalPrimitive.Title
      css={[
        theme.typography["label-s"],
        css`
          color: ${theme.colors.text["strong-950"]};
          margin: 0;
        `,
      ]}
      ref={ref}
      {...props}
    />
  );
});
ModalTitle.displayName = ModalPrimitive.Title.displayName;

const ModalDescription = React.forwardRef<
  React.ElementRef<typeof ModalPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof ModalPrimitive.Description>
>(({ className, ...props }, ref) => {
  const theme = useTheme();
  return (
    <ModalPrimitive.Description
      css={[
        theme.typography["paragraph-s"],
        css`
          color: ${theme.colors.text["sub-600"]};
          margin: 0;
        `,
      ]}
      ref={ref}
      {...props}
    />
  );
});
ModalDescription.displayName = ModalPrimitive.Description.displayName;

export {
  Modal,
  ModalPortal,
  ModalOverlay,
  ModalTrigger,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalSubBody,
  ModalFooter,
  ModalTitle,
  ModalClose,
  ModalContentDivider,
  ModalDescription,
};
