import Fuse from "fuse.js";
import { useMemo } from "react";
import { useDebounce } from "usehooks-ts";

function useCollectionSearch<T extends { title: string }>(
  query: string,
  collections: T[]
) {
  const debouncedQuery = useDebounce(query, 300);

  const index = useMemo(() => {
    return new Fuse(collections, {
      keys: ["title", "handle", "collection_id", "description"],
      threshold: 0.3,
    });
  }, [collections]);

  return useMemo(() => {
    if (!debouncedQuery) return collections;
    return index.search(debouncedQuery).map((result) => result.item);
  }, [collections, index, debouncedQuery]);
}

export default useCollectionSearch;
