import { createContext, PropsWithChildren, useContext } from "react";

const OpacityContext = createContext<
  { setHalfOpacity: (newValue: boolean) => void } | undefined
>(undefined);

/**
 * Used so that the DraggableWrapper can know whether to show the element opaque because the zones inside it are disabled due to unpinned products.
 */
export function DNDOpacityProvider({
  children,
  setHalfOpacity,
}: PropsWithChildren<{ setHalfOpacity: (newValue: boolean) => void }>) {
  return (
    <OpacityContext.Provider value={{ setHalfOpacity }}>
      {children}
    </OpacityContext.Provider>
  );
}

export function useDNDSetOpacity() {
  const context = useContext(OpacityContext);
  if (!context) {
    throw new Error(
      "useDNDSetOpacity must be used within a DNDOpacityProvider"
    );
  }
  return context.setHalfOpacity;
}
