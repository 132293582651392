import { MutableRefObject, useEffect } from "react";
import { useDragAndDropContext } from "./DragAndDropProvider";
import { dndGridClass } from "./DragAndDropGrid";

const removeAfterNextDragEnd = new Set<HTMLElement>();
const parentToOnRemoveFns = new WeakMap<
  Element,
  Set<(child: HTMLElement) => Node | "skip">
>();

/**
 * Work around safari bug that breaks drag and drop on iOS, see https://bugs.webkit.org/show_bug.cgi?id=279056
 * The safari bug gets triggered if the element that receives the initial touch is removed from the DOM. Instead, this moves it into the <body> and hides it until the drag is finished.
 *
 * Alternative implementation: https://github.com/depict-org/depict/pull/2145
 * @param draggableWrapperRef Ref to the draggable wrapper element
 */
export function useMobileSafariBugWorkaround(
  draggableWrapperRef: MutableRefObject<HTMLDivElement | null>
) {
  const { isDragging } = useDragAndDropContext();
  const draggingAnything = !!isDragging.size;

  useEffect(() => {
    const div = draggableWrapperRef.current;
    const parent = div?.parentElement;
    if (!div || !(parent instanceof HTMLElement)) return;

    const runOnDraggableRemovedSet = getCallOnRemoveChild(parent);

    // Proxy removeChild on the parent to know when react deletes this node.
    // We have the set setup to avoid proxying the parent's removeChild method multiple times, which leads to "stack size exceeded"-errors if we do it too much, see: https://depictaiworkspace.slack.com/archives/C040TEGA2QJ/p1726563128599149
    const onDraggableRemoved = (child: HTMLElement) => {
      // Only do something when the child this hook was called for was removed
      // Alternatively this proxying could be done on the <VirtualRow>, but someone could accidentally wrap the draggable wrappers, breaking this workaround, so this is more robust.
      if (child === div) {
        // Move div into the body so it's still in the DOM
        document.body.append(div);
        // Make invisible
        div.style.setProperty("display", "none", "important");

        // Mark for removal after the drag ends
        removeAfterNextDragEnd.add(div);

        // Don't call removeChild in this case but pretend the removal was successful
        return div;
      }
      return "skip";
    };
    runOnDraggableRemovedSet.add(onDraggableRemoved);

    // We need to do the same for the grandparent (dnd-grid), because, when selecting a lot of products, the whole <VirtualRow> gets removed
    const grandParent = parent?.closest("." + dndGridClass);
    let cleanupDndGrid: undefined | (() => void);
    if (grandParent) {
      const runOnVirtualRowRemovedSet = getCallOnRemoveChild(grandParent);
      const onVirtualRowRemoved = (child: HTMLElement) => {
        if (child?.contains?.(div)) {
          document.body.append(child);
          child.style.setProperty("display", "none", "important");
          removeAfterNextDragEnd.add(child);
          return child;
        }
        return "skip";
      };
      runOnVirtualRowRemovedSet.add(onVirtualRowRemoved);
      cleanupDndGrid = () =>
        runOnVirtualRowRemovedSet.delete(onVirtualRowRemoved);
    }

    return () => {
      runOnDraggableRemovedSet.delete(onDraggableRemoved);
      cleanupDndGrid?.();
    };
  }, [draggableWrapperRef]);

  useEffect(() => {
    // Only when not dragging
    if (draggingAnything || !removeAfterNextDragEnd.size) return;
    // Remove the stashed elements from <body> again
    for (const element of removeAfterNextDragEnd) {
      element.remove();
    }
    removeAfterNextDragEnd.clear();
  }, [draggingAnything]);
}

function getCallOnRemoveChild(parent: Element) {
  let runOnRemoveChildSet = parentToOnRemoveFns.get(parent);
  if (runOnRemoveChildSet) return runOnRemoveChildSet;

  runOnRemoveChildSet = new Set();
  parentToOnRemoveFns.set(parent, runOnRemoveChildSet);

  // Only proxy removeChild once and then go through our set so we don't accidentally reach max stack size
  parent.removeChild = new Proxy(parent.removeChild, {
    apply(target, thisArg, argumentList) {
      for (const fn of runOnRemoveChildSet!) {
        const returnValue = fn(argumentList[0]);
        if (returnValue === "skip") continue;
        return returnValue;
      }
      return Reflect.apply(target, thisArg, argumentList);
    },
  });

  return runOnRemoveChildSet;
}
