import { useQuery } from "@tanstack/react-query";
import useAuthorizedApi from "src/helpers/hooks/app/useAuthorizedApi";
import useMerchantId from "src/helpers/hooks/app/useMerchantId";
import { toTzAgnosticIsoDate } from "src/lite/helpers/datetime";
import { QueryId, getLiteQueryKey } from "src/lite/queries";

export default function useCollectionProductClicks({
  collectionId,
  fromDate,
  toDate,
  enabled = true,
}: {
  enabled?: boolean;
  collectionId?: string;
  fromDate: Date;
  toDate: Date;
}) {
  const { merchantId } = useMerchantId();
  const { api } = useAuthorizedApi();

  const fromDateString = toTzAgnosticIsoDate(fromDate);
  const toDateString = toTzAgnosticIsoDate(toDate);

  const query = useQuery({
    queryKey: getLiteQueryKey(QueryId.GetCollectionProductClicks, {
      merchantId,
      collectionId: collectionId || "",
      fromDate: fromDateString,
      toDate: toDateString,
    }),
    queryFn: async () => {
      if (!api || !collectionId) {
        return;
      }
      const res = await api.GET("/collections/{collection_id}/product-clicks", {
        params: {
          path: {
            collection_id: collectionId,
          },
          query: {
            merchant_id: merchantId,
            from_date: fromDateString,
            to_date: toDateString,
          },
        },
      });

      return res.data?.products.reduce<{
        [k: string]: {
          clicks: number;
          percentOfMaxClicks: number | null;
          averagePosition: number | null;
        };
      }>((acc, click) => {
        acc[click.main_product_id] = {
          clicks: click.clicks,
          percentOfMaxClicks: click.percent_of_max_clicks,
          averagePosition: click.average_position ?? null,
        };
        return acc;
      }, {});
    },
    enabled: api && enabled && !!collectionId,
  });

  return query;
}
