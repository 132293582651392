import React from "react";
import { createRoot } from "react-dom/client";
import { IntercomProvider } from "react-use-intercom";
import App from "src/App";

import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { queryClient } from "src/queries/client";
import { initMonitoring } from "./monitoring";
import { TooltipProvider } from "./alignUI/Tooltip/Tooltip";
import config from "./config";
import { AnalyticsProvider } from "./helpers/integrations/segment";
import LiteThemeProvider from "./lite/helpers/LiteThemeProvider";
import reportWebVitals from "./reportWebVitals";
import { PostHogProvider } from "posthog-js/react";
import { posthog } from "posthog-js";

initMonitoring();

const segmentKey = config.segment.key;
const intercomAppID = config.intercom.appId;

const container = document.getElementById("root");

if (!location.origin.includes("localhost")) {
  posthog.init(config.postHog.apiKey, {
    api_host: config.postHog.host,
  });
}

const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <LiteThemeProvider>
        <AnalyticsProvider writeKey={segmentKey}>
          <IntercomProvider
            appId={intercomAppID}
            autoBoot={!location.origin.includes("localhost")}
          >
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />
              <TooltipProvider>
                <App />
              </TooltipProvider>
            </QueryClientProvider>
          </IntercomProvider>
        </AnalyticsProvider>
      </LiteThemeProvider>
    </PostHogProvider>
  </React.StrictMode>
);

reportWebVitals((metric) => {
  const body = JSON.stringify({
    ...metric,
    origin: location.origin,
    path: location.pathname,
  });
  const url =
    new URL(config.apiClient.baseUrl, location.origin).origin +
    "/api/lite/vitals";

  // Use `navigator.sendBeacon()` if available, falling back to `fetch()`
  if (navigator.sendBeacon) {
    const blob = new Blob([body], {
      type: "application/json",
    });
    navigator.sendBeacon(url, blob);
  } else {
    fetch(url, {
      body,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      keepalive: true,
    });
  }
});
