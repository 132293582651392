import { JSX, useEffect, useRef } from "react";
import { useDragAndDropContext } from "./DragAndDropProvider";
import { LiteCollectionProductDto } from "../../../../api/types";
import ProductCard from "../../../../alignUI/ProductCard/ProductCard";
import { DraggableWrapperProvider } from "./DraggableWrapper";
import { css } from "@emotion/react";
import { listenToPointerOrTouchEvent, PointerEventLike } from "./events";
import { useSortByGridOrderPrioritiseBlocks } from "./useSortByGridOrder";

export const cardStackIdPrefix = "card-stack-";

/**
 * Renders the stack of cards and tracks it to the dragging pointer during a drag.
 */
export function DNDCardStack({
  getProductCardProps,
}: {
  getProductCardProps: (
    product: LiteCollectionProductDto
  ) => Omit<Parameters<typeof ProductCard>[0], "product">;
}) {
  const stackRef = useRef<HTMLDivElement | null>(null);
  // map of pointer id -> coordinates
  const lastPointerCoordsEvenWhenNotDragging = useRef<
    Map<number, { x: number; y: number }>
  >(new Map());
  const {
    isDragging,
    refState,
    productsToRender,
    dndDisabled,
    repositionedBlocksWithRender,
  } = useDragAndDropContext();
  const { allSortedPrioritised: isDraggingSorted } =
    useSortByGridOrderPrioritiseBlocks(isDragging);
  const areDraggingSomething = isDragging.size;
  const blockPositionsById = Object.fromEntries(
    repositionedBlocksWithRender.map((entry) => [
      entry.block.DOMElementId,
      entry,
    ])
  );
  const createTransformStyles = ({ x, y }: { x: number; y: number }) => {
    const { startedDraggingRelativeToElement } = refState.current;
    const { x: startX, y: startY } = startedDraggingRelativeToElement!;
    return {
      transform: `translate(calc(${x - (startX ?? 0)}px - var(--padding)), calc(${y - (startY ?? 0)}px - var(--padding)))`,
      "--scaled-down-shift-x": `${startX ?? 0}px`,
      "--scaled-down-shift-y": `${startY ?? 0}px`,
    };
  };

  useEffect(() => {
    const handler = (e: PointerEventLike) => {
      // Always collect mouse pointer information so we can put the stack in the right position even on just pointerdown (no move)
      const position = {
        x: e.clientX,
        y: e.clientY,
      };
      lastPointerCoordsEvenWhenNotDragging.current.set(e.pointerId, position);

      const element = stackRef.current;
      if (!element || refState.current.dragPointerId !== e.pointerId) return;
      // Update element style directly, without re-rendering, while dragging
      const styles = createTransformStyles(position);
      for (const key in styles) {
        element.style.setProperty(key, styles[key as keyof typeof styles]);
      }
    };

    const stopListeningForPointerMove = listenToPointerOrTouchEvent(
      window,
      "pointermove",
      handler
    );
    const stopListeningForPointerDown = listenToPointerOrTouchEvent(
      window,
      "pointerdown",
      handler
    );
    return () => {
      stopListeningForPointerMove();
      stopListeningForPointerDown();
    };
  }, [refState]);

  // So our stack doesn't jump around when we start dragging
  const transformStringForDraggingPointer =
    lastPointerCoordsEvenWhenNotDragging.current.get(
      refState.current.dragPointerId!
    );

  const stackElements: JSX.Element[] = [];

  // Avoid doing work if not needed
  if (!areDraggingSomething) return;

  const productsById = Object.fromEntries(
    productsToRender.map((product) => [product.main_product_id, product])
  );

  for (const element of isDraggingSorted.slice(0, 3).reverse()) {
    // Show first 3 elements when sorted by grid order. toReversed because the last one will visually be the top one
    if (element in blockPositionsById) {
      // It's a content block
      const {
        renderBlock,
        block: { DOMElementId, span_columns, span_rows },
      } = blockPositionsById[element];
      const key = cardStackIdPrefix + DOMElementId;
      stackElements.push(
        <DraggableWrapperProvider
          isContent={true}
          id={key}
          key={key}
          isCloneInCardStack={true}
          slotsWidth={span_columns}
          slotsHeight={span_rows}
        >
          {renderBlock({})}
        </DraggableWrapperProvider>
      );
    } else {
      // It's a product
      const product = productsById[element];
      const key = cardStackIdPrefix + product.main_product_id;
      stackElements.push(
        <DraggableWrapperProvider
          slotsHeight={1}
          slotsWidth={1}
          isContent={false}
          id={key}
          key={key}
          isCloneInCardStack={true}
        >
          <ProductCard
            {...getProductCardProps(product)}
            selected={false}
            product={product}
          />
        </DraggableWrapperProvider>
      );
    }
  }

  const selectionCountAsString = isDragging.size + "";

  return (
    <>
      <div
        ref={stackRef}
        className={`dnd-card-stack${dndDisabled ? " dnd-disabled" : ""}`}
        css={css`
          --selection-count: ${JSON.stringify(selectionCountAsString)};
          --selection-count-length: ${selectionCountAsString.length};
        `}
        // Initial transform only, we manually update it while dragging
        style={
          transformStringForDraggingPointer
            ? createTransformStyles(transformStringForDraggingPointer)
            : {}
        }
      >
        <div className="relative-wrapper">{stackElements}</div>
      </div>
    </>
  );
}
