import { css } from "@emotion/react";
import { ProgressBar } from "src/alignUI/ProgressBar/ProgressBar";

export default function OnboardingProgress({
  doneOrCancelledSteps,
  totalSteps,
}: {
  doneOrCancelledSteps: number;
  totalSteps: number;
}) {
  return (
    <div
      css={css`
        display: flex;
        gap: 8px;
        flex-direction: column;
        padding: 0 16px;
      `}
    >
      <div
        css={css`
          display: flex;
          gap: 4px;
          flex-direction: column;
        `}
      >
        <div
          css={[
            (theme) => theme.typography["label-xs"],
            (theme) => css`
              color: ${theme.colors.text["soft-400"]};
            `,
          ]}
        >
          {doneOrCancelledSteps}/{totalSteps}
        </div>
        <div
          css={[
            (theme) => theme.typography["label-l"],
            (theme) => css`
              color: ${theme.colors.text["strong-950"]};
            `,
          ]}
        >
          Get started
        </div>
      </div>

      <ProgressBar
        width={"88px"}
        percentage={(doneOrCancelledSteps / totalSteps) * 100.0}
      />
    </div>
  );
}
